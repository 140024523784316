import React, { useCallback } from "react";

const InfoTooltip = ({ style = {}, className = "", content }) => {
  const smartRef = useCallback((node) => {
    if (node !== null) {
      window.$(node).tooltip();
    }
  }, []);

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <a
      ref={smartRef}
      href="#void"
      className={`material-icons ml-1 ${className}`}
      data-toggle="tooltip"
      data-html="true"
      style={{ fontSize: "21px", ...style }}
      title={content}
      onClick={onClick}
    >
      info
    </a>
  );
};

export default InfoTooltip;
