const initialState = {
  status: null,
  countries: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "COUNTRIES/FETCH":
      return {
        ...state,
        status: "loading",
      };
    case "COUNTRIES/FETCH.SUCCESS":
      return {
        ...state,
        status: "success",
        countries: action.payload.countries,
      };
    case "COUNTRIES/FETCH.ERROR":
      return {
        ...state,
        status: "error",
        countries: [],
      };

    default:
      return state;
  }
}
