/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

const initialState = {
  v1LoginStatus: null,
  loginStatus: null,
  loginError: null,
  loggedUser: null,
  loggedUserStatus: null,
  loggedUserError: null,
  logoutStatus: null,
  currentCompany: null,
  credits: null,
  creditsStatus: null,
  creditsError: null,
};

export default function reducer(state = initialState, action) {
  let loggedUser = null;

  switch (action.type) {
    case "USERS/V1_LOGIN":
      return {
        ...state,
        v1LoginStatus: "loading",
      };
    case "USERS/V1_LOGIN.SUCCESS":
      return {
        ...state,
        v1LoginStatus: "success",
      };
    case "USERS/V1_LOGIN.ERROR":
      return {
        ...state,
        v1LoginStatus: "error",
      };

    case "USER_LOGOUT":
      return {
        ...initialState,
        logoutStatus: "success",
      };

    case "FETCH_USER_DATA":
      return {
        ...state,
        loggedUserStatus: "loading",
        loggedUserError: null,
      };
    case "FETCH_USER_DATA_SUCCESS":
      return {
        ...state,
        loggedUserStatus: "success",
        loggedUserError: null,
        loggedUser: action.payload.user,
      };
    case "FETCH_USER_DATA_ERROR":
      return {
        ...state,
        loggedUserStatus: "error",
        loggedUserError: action.payload,
        loggedUser: null,
      };

    case "FETCH_CREDITS":
      return {
        ...state,
        creditsStatus: "loading",
        creditsError: null,
      };
    case "FETCH_CREDITS_SUCCESS":
      return {
        ...state,
        creditsStatus: "success",
        creditsError: null,
        credits: action.payload.credits,
      };
    case "FETCH_CREDITS_ERROR":
      return {
        ...state,
        creditsStatus: "error",
        creditsError: action.payload,
        credits: null,
      };

    case "USERS/CLEAR_SKIP_STEP5.SUCCESS":
      loggedUser = state.loggedUser;
      loggedUser.skip_step5 = false;
      return {
        ...state,
        loggedUser,
      };

    default:
      return state;
  }
}
