import React, { lazy } from "react";
import ModalsManager from "./ModalsManager";

const NewContactModal = lazy(() => import("./modals/NewContactModal"));
const ManageContactGroupsModal = lazy(() =>
  import("./modals/ManageContactGroupsModal")
);
const ManageContactTagsModal = lazy(() =>
  import("./modals/ManageContactTagsModal")
);
const ManageContactAmCampaignsModal = lazy(() =>
  import("./modals/ManageContactAmCampaignsModal")
);
const NumberLookupModal = lazy(() => import("./modals/NumberLookupModal"));
const NewMessageReceiverModal = lazy(() =>
  import("./modals/NewMessageReceiverModal")
);
const HubMessagesFiltersModal = lazy(() =>
  import("./modals/HubMessagesFiltersModal")
);
const ChooseOrganizationModal = lazy(() =>
  import("./modals/ChooseOrganizationModal")
);
const NewGroupModal = lazy(() => import("./modals/NewGroupModal"));
const ManageGroupAmCampaignsModal = lazy(() =>
  import("./modals/ManageGroupAmCampaignsModal")
);
const ManageGroupExtAppsModal = lazy(() =>
  import("./modals/ManageGroupExtAppsModal")
);
const AccountIssueModalContainer = lazy(() =>
  import("./modals/AccountIssueModalContainer")
);
const AddPeopleToGroupModal = lazy(() =>
  import("./modals/AddPeopleToGroupModal")
);
const DeleteContactModal = lazy(() => import("./modals/DeleteContactModal"));
const DeleteDuplicateContactModal = lazy(() =>
  import("./modals/DeleteDuplicateContactModal")
);
const BlockDuplicateContactModal = lazy(() =>
  import("./modals/BlockDuplicateContactModal")
);
const UnblockDuplicateContactModal = lazy(() =>
  import("./modals/UnblockDuplicateContactModal")
);
const UnsubscribeDuplicateContactModal = lazy(() =>
  import("./modals/UnsubscribeDuplicateContactModal")
);
const SubscribeDuplicateContactModal = lazy(() =>
  import("./modals/SubscribeDuplicateContactModal")
);
const LookupQueuedModal = lazy(() => import("./modals/LookupQueuedModal"));
const LookupResultModal = lazy(() => import("./modals/LookupResultModal"));
const MessageSentModal = lazy(() => import("./modals/MessageSentModal"));
const HostLandlineModal = lazy(() => import("./modals/HostLandlineModal"));
const ShortLinksModal = lazy(() => import("./modals/ShortLinksModal"));
const NewShortLinkModal = lazy(() => import("./modals/NewShortLinkModal"));
const VoiceBroadcastModal = lazy(() => import("./modals/VoiceBroadcastModal"));
const MessageTemplatesModal = lazy(() =>
  import("./modals/MessageTemplatesModal")
);
const MessageTemplateModal = lazy(() =>
  import("./modals/MessageTemplateModal")
);
const NewCallModal = lazy(() => import("./modals/NewCallModal"));
const SignatureModal = lazy(() => import("./modals/SignatureModal"));
const MakeNewModal = lazy(() => import("./modals/MakeNewModal"));
const FetchResourceFailedModal = lazy(() =>
  import("./modals/FetchResourceFailedModal")
);
const LongNamesModal = lazy(() => import("./modals/LongNamesModal"));
const NewVersionAvailableModal = lazy(() =>
  import("./modals/Dashboard/NewVersionAvailableModal")
);

export default class LayoutModals extends React.PureComponent {
  render() {
    const { isCompanyLoaded } = this.props;
    let modals = {
      "choose-organization": ChooseOrganizationModal,
      "message-sent": MessageSentModal,
      "host-landline": HostLandlineModal,
    };
    if (isCompanyLoaded) {
      modals = {
        ...modals,
        "add-person": NewContactModal,
        "manage-contact-groups": ManageContactGroupsModal,
        "manage-contact-tags": ManageContactTagsModal,
        "manage-contact-am-campaigns": ManageContactAmCampaignsModal,
        "number-lookup": NumberLookupModal,
        "new-message-receiver": NewMessageReceiverModal,
        "hub-messages-filters": HubMessagesFiltersModal,
        "new-group": NewGroupModal,
        "manage-group-am-campaigns": ManageGroupAmCampaignsModal,
        "manage-group-ext-apps": ManageGroupExtAppsModal,
        "account-issue": AccountIssueModalContainer,
        "add-people-to-group": AddPeopleToGroupModal,
        "delete-contact": DeleteContactModal,
        "delete-duplicate-contact": DeleteDuplicateContactModal,
        "block-duplicate-contact": BlockDuplicateContactModal,
        "unblock-duplicate-contact": UnblockDuplicateContactModal,
        "unsubscribe-duplicate-contact": UnsubscribeDuplicateContactModal,
        "subscribe-duplicate-contact": SubscribeDuplicateContactModal,
        "lookup-queued": LookupQueuedModal,
        "lookup-result": LookupResultModal,
        "new-voice-broadcast": VoiceBroadcastModal,
        "edit-voice-broadcast": VoiceBroadcastModal,
        "short-links": ShortLinksModal,
        "new-short-link": NewShortLinkModal,
        "message-templates": MessageTemplatesModal,
        "new-message-template": MessageTemplateModal,
        "edit-message-template": MessageTemplateModal,
        "new-call": NewCallModal,
        signature: SignatureModal,
        "make-new": MakeNewModal,
        "fetch-resource-failed": FetchResourceFailedModal,
        "contact-name": LongNamesModal,
        "new-version-available": NewVersionAvailableModal,
      };
    }

    return (
      <>
        <ModalsManager {...this.props} modals={modals} />
      </>
    );
  }
}
