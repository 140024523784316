import React from "react";

import { ReactComponent as CloseSvg } from "../../assets/img/icons/close.svg";

export default class CustomModal extends React.PureComponent {
  render() {
    let buttons = [];
    if (this.props.buttons) {
      for (let i = this.props.buttons.length - 1; i >= 0; i--) {
        let btnClass = "btn";
        if (this.props.buttons[i].type === "confirm") {
          btnClass = "btn btn-primary";
        } else if (this.props.buttons[i].type === "cancel") {
          btnClass = "btn btn-outline-primary";
        } else if (this.props.buttons[i].type === "delete") {
          btnClass = "btn btn-danger";
        }
        buttons.push(
          <button
            key={this.props.buttons[i].type + "-" + i}
            type="button"
            className={`${btnClass} ${this.props.buttons[i].className}`}
            disabled={!!this.props.buttons[i].disabled}
            onClick={this.props.buttons[i].handler}
          >
            {this.props.buttons[i].caption}
          </button>
        );
      }
    }

    return (
      <div
        className={
          "modal fade" +
          (this.props.show ? " show" : "") +
          " " +
          this.props.className
        }
        style={{ display: "block" }}
        id="globalModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="globalModalLabel"
        aria-hidden={this.props.show ? "false" : "true"}
        onClick={this.props.closeOnClick ? this.props.closeModal : null}
      >
        <div
          className={"modal-backdrop fade" + (this.props.show ? " show" : "")}
          onClick={this.props.shakeModal}
        />
        <div
          className={
            "modal-dialog " +
            (this.props.dialogClassName
              ? this.props.dialogClassName
              : "modal-dialog-scrollable modal-dialog-centered")
          }
          role="document"
          style={{ zIndex: 100 }}
        >
          <div className="modal-content">
            {!this.props.noHeader && (
              <div className="modal-header">
                <h5 className="modal-title" id="globalModalLabel">
                  {this.props.title}
                </h5>
                <div className="close-icon" onClick={this.props.closeModal}>
                  <CloseSvg width={14} />
                </div>
              </div>
            )}
            <div className="modal-body">{this.props.children}</div>
            {!this.props.noFooter && (
              <div className={`modal-footer ${this.props.footerClassName}`}>
                {buttons}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
