import React from "react";
import CustomModal from "./CustomModal";

const NewVersionAvailableTextModal = (props) => {
  return (
    <CustomModal
      {...props}
      show={true}
      className={"locked-info-modal with-backdrop"}
      dialogClassName={"modal-dialog-centered"}
      noHeader
      noFooter
      shakeModal={() => {}}
    >
      <div>
        <p className="font-weight-bold" style={{ color: "white" }}>
          This app is no longer functional. Please download the latest version
          from the App Store or Play Store.
        </p>
      </div>
    </CustomModal>
  );
};

export default NewVersionAvailableTextModal;
