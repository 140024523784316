/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

import Cookies from "universal-cookie";

export default class AxiosConfig {
  static _token = "";

  static endpointAddress = process.env.REACT_APP_API_URL;
  static primaryAppUrl = process.env.REACT_APP_PRIMARY_APP_URL;

  static _config = {
    headers: {
      Accept: "application/json, text/javascript, /; q=0.01",
      "Content-Type": "application/json",
      App: "v2",
    },
  };

  static _cookies = new Cookies();

  /**
   *
   * @param token
   */
  static setAuthToken(token) {
    AxiosConfig._token = token;
  }

  /**
   *
   * @returns {string}
   */
  static getAuthToken() {
    return AxiosConfig._token;
  }

  static clearAuthToken() {
    AxiosConfig._token = "";
  }

  /**
   *
   * @returns {object}
   */
  static getConfig() {
    return AxiosConfig._config;
  }

  /**
   *
   * @returns {object}
   */
  static getAuthConfig() {
    return {
      headers: {
        ...AxiosConfig._config.headers,
        Authorization: "Bearer " + AxiosConfig._token,
      },
    };
  }

  /**
   *
   * @returns {string}
   */
  static getEndpointAddress() {
    return AxiosConfig.endpointAddress;
  }

  static getPrimaryAppAddress() {
    return AxiosConfig.primaryAppUrl;
  }

  static getSharedCookieDomain() {
    let url = AxiosConfig.primaryAppUrl
      .replace("http://", "")
      .replace("https://", "");
    if (url.indexOf(":") !== -1) {
      url = url.substr(0, url.indexOf(":"));
    } else if (url.indexOf("/") !== -1) {
      url = url.substr(0, url.indexOf("/"));
    }
    return url;
  }

  static getCookieNameDomain() {
    return (
      "_" +
      AxiosConfig.primaryAppUrl
        .replace(/^http(s|):\/\//, "")
        .replace(/\/$/, "")
        .replace(/\./g, "_")
    );
  }
}
