import axios from "axios";
import AxiosConfig from "../AxiosConfig";

// We stores requestIds to not overwrite response from earlier request, from previous selected company
let fetchContactsRequestIds = [];

export function resetFetchContactsRequests() {
  fetchContactsRequestIds = [];
}

function removeFetchContactRequestId(requestId) {
  const index = fetchContactsRequestIds.indexOf(requestId);
  if (index >= 0) {
    fetchContactsRequestIds.splice(index, 1);
  }
}

export function fetchContacts(companyId, page) {
  return function (dispatch) {
    const requestId = `${new Date().getTime()}-${String(Math.random()).substr(
      2,
      3
    )}`;
    fetchContactsRequestIds.push(requestId);
    dispatch({
      type: "CONTACTS/FETCH",
      page: page,
    });

    const url = `contacts.json?companyId=${companyId}&page=${page}&noGroupDuplicates=1`;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        if (fetchContactsRequestIds.indexOf(requestId) >= 0) {
          removeFetchContactRequestId(requestId);
          dispatch({
            type: "CONTACTS/FETCH.SUCCESS",
            page: page,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        if (fetchContactsRequestIds.indexOf(requestId) >= 0) {
          removeFetchContactRequestId(requestId);
          dispatch({
            type: "CONTACTS/FETCH.ERROR",
            page: page,
            payload: error,
          });
        }
      });
  };
}

export function fetchFilteredContacts(
  companyId,
  query,
  countryId,
  countryCode,
  filter,
  page
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/FETCH_FILTERED",
      query,
      countryId,
      countryCode,
      page,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        query,
        countryId,
        countryCode,
        filter,
        page,
        noGroupDuplicates: 1,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "contacts.json", config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/FETCH_FILTERED.SUCCESS",
          query,
          countryId,
          countryCode,
          page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/FETCH_FILTERED.ERROR",
          query,
          countryId,
          countryCode,
          page,
          payload: error,
        });
      });
  };
}

export function fetchContactData(companyId, contactId) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/FETCH_DATA",
      contactId: contactId,
    });

    const url = "contacts/view/" + contactId + ".json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "CONTACTS/FETCH_DATA.SUCCESS",
          contactId: contactId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/FETCH_DATA.ERROR",
          contactId: contactId,
          payload: error,
        });
      });
  };
}

export function fetchTempContact(companyId, contactId) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/FETCH_TEMP",
      contactId: contactId,
    });

    const url = "contacts/view/" + contactId + ".json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "CONTACTS/FETCH_TEMP.SUCCESS",
          contactId: contactId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/FETCH_TEMP.ERROR",
          contactId: contactId,
          payload: error,
        });
      });
  };
}

export function addContact(companyId, contactData) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/ADD" });

    const data = Object.assign({}, contactData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/ADD.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/ADD.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearAddContact() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/ADD.CLEAR" });
  };
}

export function addQuickContact(companyId, contactData) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/ADD_QUICK" });

    const data = Object.assign({}, contactData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/add-quick.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/ADD_QUICK.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/ADD_QUICK.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function editContactField(companyId, contactId, contactData, requestId) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/EDIT_FIELD",
      contactId: contactId,
      contactData: contactData,
      requestId: requestId,
    });

    const data = Object.assign({}, contactData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          "contacts/edit/" +
          contactId +
          ".json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/EDIT_FIELD.SUCCESS",
          contactId: contactId,
          contactData: contactData,
          requestId: requestId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/EDIT_FIELD.ERROR",
          contactId: contactId,
          contactData: contactData,
          requestId: requestId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function editContactsField(
  companyId,
  contactIds,
  contactData,
  requestId
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/BATCH_EDIT_FIELD",
      contactIds,
      contactData: contactData,
      requestId: requestId,
    });

    const data = Object.assign({}, contactData, {
      company_id: companyId,
      contact_ids: contactIds,
    });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/batch-edit.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/BATCH_EDIT_FIELD.SUCCESS",
          contactIds,
          contactData: contactData,
          requestId: requestId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/BATCH_EDIT_FIELD.ERROR",
          contactIds,
          contactData: contactData,
          requestId: requestId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function deleteContact(companyId, contactId) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/DELETE",
      contactId: contactId,
    });

    const url = "contacts/delete/" + contactId + ".json?companyId=" + companyId;
    return axios
      .delete(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/DELETE.SUCCESS",
          contactId: contactId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/DELETE.ERROR",
          contactId: contactId,
          payload: error,
        });
      });
  };
}

export function deleteContacts(companyId, contactIds) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/BATCH_DELETE",
      contactIds,
    });

    const url = "contacts/batch-delete.json";
    const data = Object.assign(
      {},
      {
        company_id: companyId,
        contact_ids: contactIds,
      }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/BATCH_DELETE.SUCCESS",
          contactIds,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/BATCH_DELETE.ERROR",
          contactIds,
          payload: error,
        });
      });
  };
}

export function checkPhoneNumber(
  companyId,
  countryId,
  phoneNumber,
  exceptContactId
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/CHECK_PHONE_NUMBER",
      countryId: countryId,
      phoneNumber: phoneNumber,
      exceptContactId: exceptContactId,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        countryId: countryId,
        phoneNumber: phoneNumber,
        exceptContactId: exceptContactId,
      },
    });
    return axios
      .get(
        AxiosConfig.getEndpointAddress() + "contacts/check-phone-number.json",
        config
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/CHECK_PHONE_NUMBER.SUCCESS",
          countryId: countryId,
          phoneNumber: phoneNumber,
          exceptContactId: exceptContactId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/CHECK_PHONE_NUMBER.ERROR",
          countryId: countryId,
          phoneNumber: phoneNumber,
          exceptContactId: exceptContactId,
          payload: error,
        });
      });
  };
}

export function fetchContactGroups(
  companyId,
  contactId,
  query = "",
  page = null
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/GROUPS",
      contactId: contactId,
      query: query,
      page: page,
    });

    const url = "contacts/" + contactId + "/groups.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        query: query,
        page: page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/GROUPS.SUCCESS",
          contactId: contactId,
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/GROUPS.ERROR",
          contactId: contactId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearContactGroups() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/GROUPS.CLEAR" });
  };
}

export function patchContactGroups(companyId, contactId, groupIds) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/PATCH_GROUPS",
      contactId: contactId,
      groupIds: groupIds,
    });

    const url = "contacts/" + contactId + "/groups.json";
    const data = Object.assign(
      {},
      {
        companyId: companyId,
        contactId: contactId,
        groupIds: groupIds,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/PATCH_GROUPS.SUCCESS",
          contactId: contactId,
          groupIds: groupIds,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/PATCH_GROUPS.ERROR",
          contactId: contactId,
          groupIds: groupIds,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchContactGroups() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/PATCH_GROUPS.CLEAR" });
  };
}

export function fetchContactTags(
  companyId,
  contactId,
  query = "",
  page = null
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/TAGS",
      contactId: contactId,
      query: query,
      page: page,
    });

    const url = "contacts/" + contactId + "/tags.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        query: query,
        page: page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/TAGS.SUCCESS",
          contactId: contactId,
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/TAGS.ERROR",
          contactId: contactId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearContactTags() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/TAGS.CLEAR" });
  };
}

export function patchContactTags(companyId, contactId, tagIds) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/PATCH_TAGS",
      contactId: contactId,
      tagIds: tagIds,
    });

    const url = "contacts/" + contactId + "/tags.json";
    const data = Object.assign(
      {},
      {
        companyId: companyId,
        contactId: contactId,
        tagIds: tagIds,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/PATCH_TAGS.SUCCESS",
          contactId: contactId,
          tagIds: tagIds,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/PATCH_TAGS.ERROR",
          contactId: contactId,
          tagIds: tagIds,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchContactTags() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/PATCH_TAGS.CLEAR" });
  };
}

export function fetchContactAmCampaigns(
  companyId,
  contactId,
  query = "",
  page = null
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/AM_CAMPAIGNS",
      contactId: contactId,
      query: query,
      page: page,
    });

    const url = "contacts/" + contactId + "/am-campaigns.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        query: query,
        page: page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/AM_CAMPAIGNS.SUCCESS",
          contactId: contactId,
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/AM_CAMPAIGNS.ERROR",
          contactId: contactId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearContactAmCampaigns() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/AM_CAMPAIGNS.CLEAR" });
  };
}

export function patchContactAmCampaigns(companyId, contactId, campaignIds) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/PATCH_AM_CAMPAIGNS",
      contactId: contactId,
      campaignIds: campaignIds,
    });

    const url = "contacts/" + contactId + "/am-campaigns.json";
    const data = Object.assign(
      {},
      {
        companyId: companyId,
        campaignIds: campaignIds,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/PATCH_AM_CAMPAIGNS.SUCCESS",
          contactId: contactId,
          campaignIds: campaignIds,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/PATCH_AM_CAMPAIGNS.ERROR",
          contactId: contactId,
          campaignIds: campaignIds,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchContactAmCampaigns() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/PATCH_AM_CAMPAIGNS.CLEAR" });
  };
}

export function fetchNumberLookupPrice(
  companyId,
  contactIds,
  groupIds,
  recheckAll
) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/LOOKUP_PRICE" });

    const data = Object.assign(
      {},
      { companyId, contactIds, groupIds, recheckAll }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/number-lookup-price.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/LOOKUP_PRICE.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/LOOKUP_PRICE.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearNumberLookupPrice() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/LOOKUP_PRICE.CLEAR" });
  };
}

export function numberLookup(companyId, contactIds, groupIds, recheckAll) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/LOOKUP" });

    const data = Object.assign(
      {},
      { companyId, contactIds, groupIds, recheckAll }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/number-lookup.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/LOOKUP.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/LOOKUP.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearNumberLookup() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/LOOKUP.CLEAR" });
  };
}

export function queueNumberLookup(companyId, contactIds, groupIds, recheckAll) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/QUEUE_LOOKUP" });

    const data = Object.assign(
      {},
      { companyId, contactIds, groupIds, recheckAll }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/queue-number-lookup.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/QUEUE_LOOKUP.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/QUEUE_LOOKUP.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearQueueNumberLookup() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/QUEUE_LOOKUP.CLEAR" });
  };
}

export function setFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/FILTER",
      filter: filter,
    });
  };
}

export function exportContacts(
  company_id,
  contact_id = [],
  group_id = [],
  allQuery = {},
  statusId
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/EXPORT_CONTACTS",
      statusId,
    });
    const data = {
      company_id,
      contact_id,
      group_id,
    };
    if (allQuery.query && allQuery.name) {
      data["all"] = {
        [allQuery.name]: allQuery.query,
        enabled: 1,
      };
    }
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/export.csv`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/EXPORT_CONTACTS.SUCCESS",
          payload: response.data,
          statusId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/EXPORT_CONTACTS.FAILURE",
          payload: error,
          statusId,
        });
      });
  };
}

export function clearExportContacts(id) {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/EXPORT_CONTACTS.CLEAR",
      id,
    });
  };
}

export function longNameModalContent(name) {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/LONG_NAME_MODAL_CONTENT",
      name,
    });
  };
}
