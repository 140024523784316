import React, { useState } from "react";
import { history } from "../store";
import ThreadItem from "./hub/ThreadItem";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { ReactComponent as AlertSvg } from "../assets/img/icons/alert.svg";
import { ReactComponent as AlertFilledSvg } from "../assets/img/icons/alert_filled.svg";
import { tooltipProps } from "../helpers";

const HeaderUndeliveredDropdown = (props) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    if (props.undeliveredsStatus === null) {
      props.load();
    }
    setOpen(!isOpen);
  };

  const showAll = (e) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    history.push("/hub/messages");
    props.showAll();
    setOpen(false);
  };

  if (props.undeliveredsTotal <= 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Dropdown
        tag="li"
        isOpen={isOpen}
        toggle={toggle}
        className="nav-item d-none d-sm-block"
      >
        <DropdownToggle
          tag="a"
          style={{ cursor: "pointer" }}
          className={"nav-link dropdown-toggle toggle-icon"}
        >
          <span {...tooltipProps} title="Undelivered Messages">
            <AlertSvg width={24} />
            <AlertFilledSvg width={24} />
          </span>
          <span
            className="badge badge-pill bg-secondary pull-right"
            style={props.undeliveredsTotal > 99 ? { fontSize: "8px" } : {}}
          >
            {props.undeliveredsTotal > 99 ? "99+" : props.undeliveredsTotal}
          </span>
        </DropdownToggle>
        <DropdownMenu right className={"notifications"}>
          <div className="topnav-dropdown-header">
            <span>
              {props.undeliveredsTotal} Undelivered{" "}
              {props.undeliveredsTotal > 1 ? "Threads" : "Thread"}
            </span>
          </div>
          {props.undeliveredsStatus === "loading" && (
            <p className="text-muted text-center py-3">Loading...</p>
          )}
          {props.undeliveredsStatus === "success" && (
            <ul className="notification-list">
              {props.undelivereds &&
                props.undelivereds.map((thread) => (
                  <ThreadItem key={thread.item_key} {...thread} />
                ))}
            </ul>
          )}
          <div className="topnav-dropdown-footer">
            <a href="#void" onClick={showAll}>
              View All Undelivered
            </a>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default HeaderUndeliveredDropdown;
