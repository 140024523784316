/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

const initialState = {
  pathname: "/",
  previousPathname: null,
  isSidebarOpen: false,
  topBarsHeight: 0,
  isLeftPanelScrollingDown: false,
  isMainPanelScrollingUp: false,
  isLoading: false,

  showLegacyExtendSession: false,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        previousPathname: state.pathname,
        pathname: action.payload.pathname,
      };

    case "MAIN/TOGGLE_SIDEBAR":
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };

    case "MAIN/SET_TOP_BARS_HEIGHT":
      return {
        ...state,
        topBarsHeight: action.height,
      };

    case "MAIN/SET_IS_LEFT_PANEL_SCROLLING_DOWN":
      return {
        ...state,
        isLeftPanelScrollingDown: action.isScrolling,
      };

    case "MAIN/SET_IS_MAIN_PANEL_SCROLLING_UP":
      return {
        ...state,
        isMainPanelScrollingUp: action.isScrolling,
      };

    case "MAIN/SHOW_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "MAIN/EXTEND_LEGACY_SESSION_OPENER": {
      return {
        ...state,
        showLegacyExtendSession: action.show,
      };
    }

    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}
