import React from "react";
import PropTypes from "prop-types";
import CustomModal from "./CustomModal";

const RedirectingToV3Modal = (props) => {
  const { closeModal, show } = props;
  return (
    <CustomModal
      show={show}
      closeModal={closeModal}
      shakeModal={closeModal}
      className={"locked-info-modal"}
      dialogClassName={"modal-dialog-centered"}
      noHeader
      noFooter
    >
      <div onClick={closeModal}>
        <p className="font-weight-bold modal-text">Redirecting to V3</p>
      </div>
    </CustomModal>
  );
};

RedirectingToV3Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

RedirectingToV3Modal.defaultProps = {
  show: false,
};

export default RedirectingToV3Modal;
