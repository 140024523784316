import { resetFetchGroupsRequests } from "../actions/groups";
import { appendAddNewToDataObject } from "../helpers";

const fromEntries = require("fromentries");

const initialState = {
  // Groups data
  data: {},
  dataStatus: {},
  dataMembersStatus: {},

  // List
  groups: null,
  groupsStatus: null,
  groupsPage: null,
  groupsLoadedAll: null,

  // Filtered list
  filteredGroups: null,
  filteredGroupsStatus: null,
  filteredGroupsPage: null,
  filteredGroupsLoadedAll: null,
  filteredGroupsTotal: null,

  // Members
  members: {},
  membersNotMembersIds: [],
  membersStatus: {},
  membersPage: {},
  membersLoadedAll: {},

  // Filtered members
  filteredMembers: null,
  filteredMembersNotMembersIds: [],
  filteredMembersStatus: null,
  filteredMembersPage: null,
  filteredMembersLoadedAll: null,

  // Patch group
  patchQueue: {},
  patchQueueUpdated: 0,

  // Add group
  addId: null,
  addStatus: null,
  addErrors: null,

  // Ext apps data
  extAppsDataStatus: null,
  ccbCampuses: null,
  ccbGroups: null,
  pcoLists: null,
  elvantoGroups: null,
  mcLists: null,
  breezeTags: null,
  breezeEvents: null,

  // Patch members
  patchMembersStatus: null,

  // Check keywords
  checkKeywordsResults: null,
  checkKeywordsStatus: null,
  checkKeywordsUid: null,

  unclassifiedGroupId: null,
  myContactGroupId: null,

  filter: null,

  // Group Automated Messages Campaign
  amCampaigns: null,
  amCampaignsStatus: null,
  amCampaignsPage: null,
  amCampaignsQuery: "",
  amCampaignsLoadedAll: null,
  amCampaignsGroupId: null,

  // Edit group Automated Messages Campaign
  patchAmCampaignsStatus: null,

  // Add Tag
  addTagStatus: null,
  addedTag: null,
};

export default function reducer(state = initialState, action) {
  let data = null;
  let dataStatus = null;
  let groups = null;
  let members = null;
  let membersStatus = null;
  let membersNotMembersIds = null;
  let membersPage = null;
  let membersLoadedAll = null;
  let patchQueue = null;
  let patchQueueUpdated = null;
  let amCampaigns = null;

  switch (action.type) {
    // List
    case "GROUPS/FETCH":
      return {
        ...state,
        groupsStatus: "loading",
      };
    case "GROUPS/FETCH.SUCCESS":
      data = { ...state.data, ...action.payload.groups };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.groups).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };

      groups = action.payload.groups_ids;
      if (state.groups && state.groupsPage + 1 === action.page) {
        let prevGroups = [];
        for (let i = 0; i < state.groups.length; i++) {
          if (groups.indexOf(state.groups[i]) === -1) {
            prevGroups.push(state.groups[i]);
          }
        }
        groups = prevGroups.concat(groups);
      }

      return {
        ...state,
        data: appendAddNewToDataObject(
          { ...state.data },
          action.payload.groups
        ),
        dataStatus: dataStatus,
        groups: groups,
        groupsStatus: "success",
        groupsPage: action.page,
        groupsLoadedAll: action.payload.groups_ids.length === 0,
      };
    case "GROUPS/FETCH.ERROR":
      return {
        ...state,
        groups: null,
        groupsStatus: "error",
        groupsPage: null,
        groupsLoadedAll: null,
      };

    // Filtered list
    case "GROUPS/FETCH_FILTERED":
      return {
        ...state,
        filteredGroupsStatus: "loading",
      };
    case "GROUPS/FETCH_FILTERED.SUCCESS":
      data = { ...state.data, ...action.payload.groups };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.groups).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };
      if (action.page === 1 || !state.filteredGroups) {
        groups = action.payload.groups_ids;
      } else {
        groups = state.filteredGroups.concat(action.payload.groups_ids);
      }
      return {
        ...state,
        data: appendAddNewToDataObject(
          { ...state.data },
          action.payload.groups
        ),
        dataStatus: dataStatus,
        filteredGroups: groups,
        filteredGroupsStatus: "success",
        filteredGroupsPage: action.page,
        filteredGroupsLoadedAll: action.payload.groups_ids.length === 0,
        filteredGroupsTotal: action.payload.total,
      };
    case "GROUPS/FETCH_FILTERED.ERROR":
      return {
        ...state,
        filteredGroups: null,
        filteredGroupsStatus: "error",
        filteredGroupsPage: null,
        filteredGroupsLoadedAll: null,
        filteredGroupsTotal: null,
      };

    // Fetch group data
    case "GROUPS/FETCH_DATA":
      dataStatus = state.dataStatus;
      dataStatus[action.groupId] = "loading";
      return {
        ...state,
        dataStatus: dataStatus,
      };
    case "GROUPS/FETCH_DATA.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.groupId] = data[action.groupId]
        ? { ...data[action.groupId], ...action.payload.group }
        : action.payload.group;
      data[action.groupId]["__full"] = true;
      data[action.groupId]["__switchedCompanyId"] =
        action.payload.switchedCompanyId;
      dataStatus[action.groupId] = "success";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
      };
    case "GROUPS/FETCH_DATA.ERROR":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.groupId] = null;
      dataStatus[action.groupId] = "error";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
      };

    case "GROUPS/FETCH_TEMP.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.groupId] = data[action.groupId]
        ? { ...data[action.groupId], ...action.payload.group }
        : action.payload.group;
      data[action.groupId]["__full"] = true;
      dataStatus[action.groupId] = "success";

      groups = state.groups;
      if (groups.indexOf(action.groupId) === -1) {
        groups.unshift(action.groupId);
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        groups: groups,
      };

    // Members
    case "GROUPS/MEMBERS":
      membersStatus = state.membersStatus;
      membersStatus[action.groupId] = "loading";
      return {
        ...state,
        membersStatus: membersStatus,
      };
    case "GROUPS/MEMBERS.SUCCESS":
      members = [].concat(state.members);
      membersNotMembersIds = [].concat(state.membersNotMembersIds);
      membersStatus = state.membersStatus;
      membersPage = state.membersPage;
      membersLoadedAll = state.membersLoadedAll;

      if (
        action.page === 1 ||
        typeof members[action.groupId] === "undefined" ||
        !members[action.groupId]
      ) {
        members[action.groupId] = action.payload.contacts_ids;
        membersNotMembersIds = action.payload.not_members_contact_ids;
      } else {
        members[action.groupId] = members[action.groupId].concat(
          action.payload.contacts_ids
        );
        membersNotMembersIds = membersNotMembersIds.concat(
          action.payload.not_members_contact_ids
        );
      }
      membersStatus[action.groupId] = "success";
      membersPage[action.groupId] = action.page;
      membersLoadedAll[action.groupId] =
        action.limit === 999999 || action.payload.contacts_ids.length === 0;

      return {
        ...state,
        members,
        membersNotMembersIds,
        membersStatus,
        membersPage,
        membersLoadedAll,
      };
    case "GROUPS/MEMBERS.ERROR":
      members = state.members;
      membersStatus = state.membersStatus;
      membersPage = state.membersPage;
      membersLoadedAll = state.membersLoadedAll;

      members[action.groupId] = null;
      membersStatus[action.groupId] = "error";
      membersPage[action.groupId] = null;
      membersLoadedAll[action.groupId] = false;

      return {
        ...state,
        members: members,
        membersStatus: membersStatus,
        membersPage: membersPage,
        membersLoadedAll: membersLoadedAll,
      };

    // Filtered members
    case "GROUPS/FILTERED_MEMBERS":
      return {
        ...state,
        filteredMembersStatus: "loading",
      };
    case "GROUPS/FILTERED_MEMBERS.SUCCESS":
      if (action.page === 1 || !state.filteredMembers) {
        members = action.payload.contacts_ids;
        membersNotMembersIds = action.payload.not_members_contact_ids;
      } else {
        members = state.filteredMembers.concat(action.payload.contacts_ids);
        membersNotMembersIds = membersNotMembersIds.concat(
          action.payload.not_members_contact_ids
        );
      }
      return {
        ...state,
        filteredMembers: members,
        filteredMembersNotMembersIds: membersNotMembersIds,
        filteredMembersStatus: "success",
        filteredMembersPage: action.page,
        filteredMembersLoadedAll: action.payload.contacts_ids.length === 0,
      };
    case "GROUPS/FILTERED_MEMBERS.ERROR":
      return {
        ...state,
        filteredMembers: null,
        filteredMembersStatus: "error",
        filteredMembersPage: null,
        filteredMembersLoadedAll: null,
      };

    // Patch group
    case "GROUPS/PATCH":
      patchQueue = state.patchQueue ? state.patchQueue : {};
      patchQueue[action.requestId] = {
        groupId: action.groupId,
        patchData: action.patchData,
        status: "loading",
        error: null,
      };
      return {
        ...state,
        patchQueue: patchQueue,
        patchQueueUpdated: state.patchQueueUpdated + 1,
      };
    case "GROUPS/PATCH.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.groupId] = action.payload.group;
      data[action.groupId]["__full"] = true;
      dataStatus[action.groupId] = "success";

      patchQueue = state.patchQueue ? state.patchQueue : {};
      if (typeof patchQueue[action.requestId] !== "undefined") {
        patchQueue[action.requestId].status = "success";
        patchQueue[action.requestId].error = null;
        patchQueueUpdated = state.patchQueueUpdated + 1;
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchQueue: patchQueue,
        patchQueueUpdated: patchQueueUpdated,
      };
    case "GROUPS/PATCH.ERROR":
      patchQueue = state.patchQueue ? state.patchQueue : [];
      if (typeof patchQueue[action.requestId] !== "undefined") {
        patchQueue[action.requestId].status = "error";
        patchQueue[action.requestId].error =
          action.payload && action.payload.message
            ? action.payload.message
            : null;
        patchQueueUpdated = state.patchQueueUpdated + 1;
      }
      return {
        ...state,
        patchQueue: patchQueue,
        patchQueueUpdated: patchQueueUpdated,
      };

    // Add group
    case "GROUPS/ADD":
      return {
        ...state,
        addId: null,
        addStatus: "loading",
        addErrors: null,
      };
    case "GROUPS/ADD.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.payload.group.id] = action.payload.group;
      data[action.payload.group.id]["__full"] = true;
      dataStatus[action.payload.group.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        addId: action.payload.group.id,
        addStatus: "success",
        addErrors: null,
        // reset groups list
        groups: null,
        groupsStatus: null,
        groupsPage: null,
        groupsLoadedAll: null,
      };
    case "GROUPS/ADD.ERROR":
      return {
        ...state,
        addId: null,
        addStatus: "error",
        addErrors:
          action.payload && typeof action.payload.errors !== "undefined"
            ? action.payload.errors
            : null,
      };
    case "GROUPS/ADD.CLEAR":
      return {
        ...state,
        addId: null,
        addStatus: null,
        addErrors: null,
      };

    // Ext apps data
    case "GROUPS/EXT_APPS_DATA":
      return {
        ...state,
        extAppsDataStatus: "loading",
      };
    case "GROUPS/EXT_APPS_DATA.SUCCESS":
      return {
        ...state,
        extAppsDataStatus: "success",
        ccbCampuses: action.payload.ccb_campuses,
        ccbGroups: action.payload.ccb_groups,
        pcoLists: action.payload.pco_lists,
        elvantoGroups: action.payload.elvanto_groups,
        mcLists: action.payload.mc_lists,
        breezeTags: action.payload.breeze_tags,
        breezeEvents: action.payload.breeze_events,
      };
    case "GROUPS/EXT_APPS_DATA.ERROR":
      return {
        ...state,
        extAppsDataStatus: "error",
        ccbCampuses: null,
        ccbGroups: null,
        pcoLists: null,
        elvantoGroups: null,
        mcLists: null,
        breezeTags: null,
        breezeEvents: null,
      };

    case "GROUPS/FETCH_DATA_MEMBERS_STATUSES":
      return {
        ...state,

        dataMembersStatus: { [action.groupId]: "loading" },
      };

    case "GROUPS/FETCH_DATA_MEMBERS_STATUSES.SUCCESS":
      return {
        ...state,

        data: state.data[action.groupId]
          ? {
              ...state.data,
              [action.groupId]: {
                ...state.data[action.groupId],
                ...action.payload.data,
              },
            }
          : {
              ...state.data,
              [action.groupId]: action.payload.data,
            },

        dataMembersStatus: { [action.groupId]: "success" },
      };

    case "GROUPS/FETCH_DATA_MEMBERS_STATUSES.ERROR":
      return {
        ...state,

        dataMembersStatus: { [action.groupId]: "error" },
      };

    // Patch members
    case "GROUPS/PATCH_MEMBERS":
      return {
        ...state,
        patchMembersStatus: "loading",
      };
    case "GROUPS/PATCH_MEMBERS.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.payload.group.id] = action.payload.group;
      data[action.payload.group.id]["__full"] = true;
      dataStatus[action.payload.group.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchMembersStatus: "success",
      };
    case "GROUPS/PATCH_MEMBERS.ERROR":
      return {
        ...state,
        patchMembersStatus: "error",
      };

    // Check keywords
    case "GROUPS/CHECK_KEYWORDS":
      return {
        ...state,
        checkKeywordsStatus: "loading",
        checkKeywordsUid: action.uid,
      };
    case "GROUPS/CHECK_KEYWORDS.SUCCESS":
      return {
        ...state,
        checkKeywordsResults: action.payload.results,
        checkKeywordsStatus: "success",
      };
    case "GROUPS/CHECK_KEYWORDS.ERROR":
      return {
        ...state,
        checkKeywordsResults: null,
        checkKeywordsStatus: "error",
      };
    case "GROUPS/CHECK_KEYWORDS.CLEAR":
      return {
        ...state,
        checkKeywordsResults: null,
        checkKeywordsStatus: null,
        checkKeywordsUid: null,
      };

    case "GROUPS/FILTER":
      return {
        ...state,
        filter: action.filter,
      };

    // Group Automated Messages Campaigns
    case "GROUPS/AM_CAMPAIGNS":
      return {
        ...state,
        amCampaignsStatus: "loading",
        amCampaignsGroupId: action.groupId,
      };
    case "GROUPS/AM_CAMPAIGNS.SUCCESS":
      if (action.page === 1 || !state.amCampaigns) {
        amCampaigns = action.payload.campaigns;
      } else {
        amCampaigns = state.amCampaigns.concat(action.payload.campaigns);
      }
      return {
        ...state,
        amCampaigns: amCampaigns,
        amCampaignsStatus: "success",
        amCampaignsPage: action.page,
        amCampaignsQuery: action.query,
        amCampaignsLoadedAll: action.payload.campaigns.length === 0,
        amCampaignsGroupId: action.groupId,
      };
    case "GROUPS/AM_CAMPAIGNS.ERROR":
      return {
        ...state,
        amCampaigns: null,
        amCampaignsStatus: "error",
        amCampaignsPage: null,
        amCampaignsQuery: "",
        amCampaignsLoadedAll: null,
        amCampaignsGroupId: action.groupId,
      };
    case "GROUPS/AM_CAMPAIGNS.CLEAR":
      return {
        ...state,
        amCampaigns: null,
        amCampaignsStatus: null,
        amCampaignsPage: null,
        amCampaignsQuery: "",
        amCampaignsLoadedAll: null,
        amCampaignsGroupId: null,
      };

    // Edit group Automated Messages Campaigns
    case "GROUPS/PATCH_AM_CAMPAIGNS":
      return {
        ...state,
        patchAmCampaignsStatus: "loading",
      };
    case "GROUPS/PATCH_AM_CAMPAIGNS.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.groupId] = action.payload.group;
      data[action.groupId]["__full"] = true;
      dataStatus[action.groupId] = "success";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchAmCampaignsStatus: "success",
      };
    case "GROUPS/PATCH_AM_CAMPAIGNS.ERROR":
      return {
        ...state,
        patchAmCampaignsStatus: "error",
      };
    case "GROUPS/PATCH_AM_CAMPAIGNS.CLEAR":
      return {
        ...state,
        patchAmCampaignsStatus: null,
      };

    case "CONTACTS/GROUPS.SUCCESS":
      return {
        ...state,
        unclassifiedGroupId: action.payload.unclassifiedGroupId,
        myContactGroupId: action.payload.myContactGroupId,
      };

    case "FETCH_THREADS_SUCCESS":
    case "THREADS/VOICE_THREADS.SUCCESS":
    case "THREADS/SPECIAL.SUCCESS": {
      const newData = appendAddNewToDataObject(
        { ...state.data },
        action.payload.groups
      );

      return {
        ...state,
        data: newData,
        dataStatus: {
          ...state.dataStatus,
          ...fromEntries(
            new Map(
              Object.keys(action.payload.groups).map((item, index) => {
                return [index, "success"];
              })
            )
          ),
        },
      };
    }

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      resetFetchGroupsRequests();
      return initialState;

    // Add Group Tag
    case "GROUP/ADD_TAG":
      return {
        ...state,
        addTagStatus: "loading",
        addedTag: null,
      };

    case "GROUP/ADD_TAG.SUCCESS":
      return {
        ...state,
        addTagStatus: "success",
        addedTag: action.payload,
      };

    case "GROUP/ADD_TAG.ERROR":
      return {
        ...state,
        addTagStatus: "error",
      };

    case "GROUP/ADD_TAG.CLEAR":
      return {
        ...state,
        addTagStatus: null,
        addedTag: null,
      };

    default:
      return state;
  }
}
