const initialState = {
  // Check account
  checkAccount: null,
  checkAccountStatus: null,

  // Voice price
  voicePriceByCountry: {},
  voicePriceByCountryStatus: {},

  // Twilio token
  twilioToken: null,
  twilioVoiceToken: null,
  twilioTokenStatus: null,
};

export default function reducer(state = initialState, action) {
  let voicePriceByCountry = null;
  let voicePriceByCountryStatus = null;
  switch (action.type) {
    // Check account
    case "CALLS/CHECK_ACCOUNT":
      return {
        ...state,
        checkAccount: null,
        checkAccountStatus: "loading",
      };
    case "CALLS/CHECK_ACCOUNT.SUCCESS":
      return {
        ...state,
        checkAccount: action.payload.status,
        checkAccountStatus: "success",
      };
    case "CALLS/CHECK_ACCOUNT.ERROR":
      return {
        ...state,
        checkAccount: null,
        checkAccountStatus: "error",
      };

    // Voice price
    case "CALLS/VOICE_PRICE":
      voicePriceByCountryStatus = state.voicePriceByCountryStatus;
      voicePriceByCountryStatus[action.countryId] = "loading";
      return {
        ...state,
        voicePriceByCountryStatus: voicePriceByCountryStatus,
      };
    case "CALLS/VOICE_PRICE.SUCCESS":
      voicePriceByCountry = state.voicePriceByCountry;
      voicePriceByCountry[action.countryId] = action.payload.credits;
      voicePriceByCountryStatus = state.voicePriceByCountryStatus;
      voicePriceByCountryStatus[action.countryId] = "success";
      return {
        ...state,
        voicePriceByCountry: voicePriceByCountry,
        voicePriceByCountryStatus: voicePriceByCountryStatus,
      };
    case "CALLS/VOICE_PRICE.ERROR":
      voicePriceByCountry = state.voicePriceByCountry;
      voicePriceByCountry[action.countryId] = null;
      voicePriceByCountryStatus = state.voicePriceByCountryStatus;
      voicePriceByCountryStatus[action.countryId] = "error";
      return {
        ...state,
        voicePriceByCountry: voicePriceByCountry,
        voicePriceByCountryStatus: voicePriceByCountryStatus,
      };

    // Twilio token
    case "CALLS/TWILIO_TOKEN":
      return {
        ...state,
        twilioToken: null,
        twilioVoiceToken: null,
        twilioTokenStatus: "loading",
      };
    case "CALLS/TWILIO_TOKEN.SUCCESS":
      return {
        ...state,
        twilioToken: action.payload.token,
        twilioVoiceToken: action.payload.voiceToken,
        twilioTokenStatus: "success",
      };
    case "CALLS/TWILIO_TOKEN.ERROR":
      return {
        ...state,
        twilioToken: null,
        twilioTokenStatus: "error",
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
