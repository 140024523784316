/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/08/2018.
 */
import React from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Redirect } from "react-router";
import { withCookies } from "react-cookie";
import { history } from "./store";
import axios from "axios";
import AxiosConfig from "./AxiosConfig";
import PusherConfig from "./PusherConfig";
import TwilioConfig from "./TwilioConfig";
import { Route, Switch } from "react-router-dom";
import { v1Login, fetchUserData, fetchCredits } from "./actions/users";
import { fetchSettings } from "./actions/settings";
import { fetchCompanies, setCurrentCompany } from "./actions/companies";
import { fetchCountries } from "./actions/countries";
import {
  fetchNumbers,
  setNumbersFilter,
  setSenderNumber,
} from "./actions/numbers";
import { fetchCounts } from "./actions/threads";
import { fetchTwilioToken } from "./actions/calls";

import NotFoundPage from "./pages/NotFoundPage";
import HubPage from "./pages/HubPage";
import ChooseOrganizationPage from "./pages/ChooseOrganizationPage";
import AppLoading from "./components/AppLoading";
import FcmConfig from "./FcmConfig";
import { isCordovaApp, isIOs, getPrimaryUrl, getV3Url } from "./helpers";
import TwilioInitializer from "./components/TwilioInitializer";
import {
  axiosErrorCallback,
  logoutAuth,
  redirectToLoginPage,
  setTokens,
} from "./utils/auth";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./utils/constants";
import LegacyExtendSession from "./components/LegacyExtendSession/LegacyExtendSession";
import NewVersionAvailableTextModal from "./components/modals/NewVersionAvailableTextModal";

const queryString = require("query-string");

function mapStateToProps(store) {
  return {
    location: store.router.location,
    loggedUserStatus: store.users.loggedUserStatus,
    loggedUser: store.users.loggedUser,
    v1LoginStatus: store.users.v1LoginStatus,
    logoutStatus: store.users.logoutStatus,
    settingsStatus: store.settings.settingsStatus,
    companiesStatus: store.companies.companiesStatus,
    companies: store.companies.companies,
    currentCompany: store.companies.currentCompany,
    currentCompanyId: store.companies.currentCompany
      ? store.companies.currentCompany.id
      : null,
    countriesStatus: store.countries.status,
    numbersStatus: store.numbers.numbersStatus,
    numbers: store.numbers.numbers,
    numbersFilterValue: store.numbers.numbersFilterValue,
    creditsStatus: store.users.creditsStatus,
    countsStatus: store.threads.countsStatus,
    twilioToken: store.calls.twilioToken,
    twilioVoiceToken: store.calls.twilioVoiceToken,
    twilioTokenStatus: store.calls.twilioTokenStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    v1Login: () => dispatch(v1Login()),
    fetchUserData: () => dispatch(fetchUserData()),
    fetchSettings: () => dispatch(fetchSettings()),
    fetchCompanies: () => dispatch(fetchCompanies()),
    fetchCountries: () => dispatch(fetchCountries()),
    setCurrentCompany: (id) => dispatch(setCurrentCompany(id)),
    fetchCredits: (companyId) => dispatch(fetchCredits(companyId)),
    fetchNumbers: (companyId) => dispatch(fetchNumbers(companyId)),
    setNumbersFilter: (companyId, numbers, all, save) =>
      dispatch(setNumbersFilter(companyId, numbers, all, save)),
    setSenderNumber: (number) => dispatch(setSenderNumber(number)),
    handlePusherEvent: (dispatchRess) => {
      for (let i = 0; i < dispatchRess.length; i++) {
        dispatch(dispatchRess[i]);
      }
    },
    dispatchProxy: (dispatchRes) => dispatch(dispatchRes),
    fetchCounts: (companyId, numbersFilter, fields) =>
      dispatch(fetchCounts(companyId, numbersFilter, fields)),
    fetchTwilioToken: (companyId) => dispatch(fetchTwilioToken(companyId)),
  };
}

class RouterClass extends React.Component {
  constructor(props) {
    super(props);

    // !!! IMPORTANT !!!
    // In ./store.js we held some code that fixes some corner case using replacing the history of the URL
    // if something works wrong when with reading/replacing the parts or URL or something related to URL, please look there  first
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const accessToken = params.get("accessToken");
    const refreshToken = params.get("refreshToken");

    if (accessToken && refreshToken) {
      setTokens(accessToken, refreshToken);
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    AxiosConfig.setAuthToken(localStorage.getItem(ACCESS_TOKEN));

    this.initAxiosAuth();

    this.receiveMessage = this.receiveMessage.bind(this);

    this.state = {
      appReady: false,
      companyPage: false,
      otherLoaded: false,
      logout: false,
    };
  }

  initAxiosAuth() {
    axios.interceptors.response.use((response) => response, axiosErrorCallback);
  }

  componentDidMount() {
    if (!this.state.appReady) {
      this.loadApp();
    }

    if (isCordovaApp() && isIOs()) {
      window.addEventListener("message", this.receiveMessage, false);
    }

    if (!isCordovaApp()) {
      const accessToken = localStorage.getItem(ACCESS_TOKEN);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      if (accessToken && refreshToken) {
        window.location.href = getV3Url(
          `dashboard?accessToken=${accessToken}&refreshToken=${refreshToken}`
        );
      } else {
        window.location.href = getV3Url("dashboard");
      }
    }
  }

  componentWillUnmount() {
    if (isCordovaApp() && isIOs()) {
      window.removeEventListener("message", this.receiveMessage, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.logoutStatus !== prevProps.logoutStatus &&
      this.props.logoutStatus === "success"
    ) {
      FcmConfig.sendParentMessage("user-logout");
      window.location.href = getPrimaryUrl("users/logout");
      this.setState({ appReady: false, logout: true });
      return;
    }

    if (this.state.logout) {
      return;
    }

    if (!this.state.appReady) {
      this.loadApp(prevProps);
      return;
    }

    if (
      (this.props.currentCompany && !prevProps.currentCompany) ||
      (this.props.currentCompany &&
        prevProps.currentCompany &&
        this.props.currentCompany.id !== prevProps.currentCompany.id)
    ) {
      PusherConfig.init(
        this.props.currentCompany.id,
        this.props.loggedUser.id,
        this.props.handlePusherEvent
      );
      this.setState({ appReady: false });
      this.props.fetchCredits(this.props.currentCompany.id);
      this.props.fetchNumbers(this.props.currentCompany.id);
      this.props.fetchTwilioToken(this.props.currentCompany.id);
    }

    if (
      this.props.numbersStatus !== prevProps.numbersStatus &&
      this.props.numbersStatus === "success" &&
      this.props.currentCompany
    ) {
      this.loadNumbersFilter();
      this.loadSenderNumber();
    }
    if (
      prevProps.numbersFilterValue === null &&
      this.props.numbersFilterValue !== null
    ) {
      this.props.fetchCounts(
        this.props.currentCompany.id,
        this.props.numbersFilterValue,
        ["unread"]
      );
    }

    if (prevState.appReady !== this.state.appReady) {
      this.props.fetchCounts(
        this.props.currentCompany.id,
        this.props.numbersFilterValue,
        [
          "undeliveredUnread",
          "undeliveredAll",
          "undeliveredRead",
          "undelivered",
        ]
      );
    }
  }

  sendMessage(event) {
    window.parent.postMessage(event, "*");
  }

  receiveMessage(event) {
    switch (event.data.event) {
      case "call-initiated":
        TwilioConfig.cordovaInit(
          this.props.currentCompany,
          this.props.loggedUser,
          this.props.dispatchProxy
        );
        break;
      case "call-connected":
        TwilioConfig.cordovaCallConnected();
        break;
      case "call-disconnected":
        TwilioConfig.cordovaCallDisconnected();
        break;
      default:
        console.log("No such event", event.data.event);
        break;
    }
  }

  loadApp(prevProps) {
    // User not logged - go to login page
    if (!AxiosConfig.getAuthToken()) {
      logoutAuth();
      redirectToLoginPage();
      return;
    }

    // User logged - fetch user data
    if (
      AxiosConfig.getAuthToken() &&
      !this.props.loggedUser &&
      this.props.loggedUserStatus !== "loading" &&
      this.props.loggedUserStatus !== "error"
    ) {
      this.props.fetchUserData();
    }
    if (!this.props.loggedUser) {
      return;
    }

    // Fetch settings and companies
    if (
      this.props.settingsStatus === null ||
      this.props.companiesStatus === null
    ) {
      if (this.props.settingsStatus === null) {
        this.props.fetchSettings();
      }
      if (this.props.companiesStatus === null) {
        this.props.fetchCompanies();
      }
      return;
    }

    // Fetch countries
    if (this.props.countriesStatus === null) {
      this.props.fetchCountries();
    }

    // Select company if cookie exists or go to choose page
    if (
      !this.props.currentCompany &&
      this.props.companiesStatus === "success"
    ) {
      const companyCookieName =
        "company_id_" + AxiosConfig.getCookieNameDomain();
      const companyCookie = parseInt(this.props.cookies.get(companyCookieName));
      if (companyCookie) {
        for (let i = 0; i < this.props.companies.length; i++) {
          if (this.props.companies[i].id === companyCookie) {
            this.props.setCurrentCompany(companyCookie);
            return;
          }
        }
      }

      // If only one company available - choose it
      if (this.props.companies.length === 1) {
        this.props.setCurrentCompany(this.props.companies[0].id);
        return;
      }

      // Set to show Choose Organization page
      if (!this.state.companyPage) {
        this.setState({ companyPage: true }, () => {
          history.push(
            `${this.props.location.pathname}#modal-choose-organization`
          );
        });
      }
      return;
    } else if (!this.props.currentCompany) {
      return;
    }

    // Load other data
    if (
      this.props.currentCompany &&
      this.props.settingsStatus === "success" &&
      !this.state.otherLoaded
    ) {
      this.setState({ otherLoaded: true }, () => {
        FcmConfig.init(
          this.props.loggedUser.id,
          localStorage.getItem(REFRESH_TOKEN)
        );
        FcmConfig.sendParentMessage("user");
        PusherConfig.init(
          this.props.currentCompany.id,
          this.props.loggedUser.id,
          this.props.handlePusherEvent
        );
        this.props.fetchCredits(this.props.currentCompany.id);
        this.props.fetchNumbers(this.props.currentCompany.id);
        this.props.fetchTwilioToken(this.props.currentCompany.id);
      });
    }

    // Init Twilio Client
    if (
      this.props.currentCompany &&
      this.props.twilioToken &&
      !TwilioConfig.isInitialized() &&
      // Cordova app has a different flow for now. Will be changed in the future.
      // Microphone instance is required so it's initializaed in call modal
      !isCordovaApp()
    ) {
      TwilioConfig.init(
        this.props.twilioToken,
        this.props.currentCompany,
        this.props.loggedUser,
        this.props.dispatchProxy
      );
    }

    // Load number filter from current company data
    if (
      this.props.numbersStatus === "success" &&
      this.props.numbersFilterValue === null
    ) {
      this.loadNumbersFilter();
      this.loadSenderNumber();
    }

    // Load counts when number filters are set
    if (
      this.props.numbersFilterValue !== null &&
      this.props.countsStatus === null
    ) {
      this.props.fetchCounts(
        this.props.currentCompany.id,
        this.props.numbersFilterValue,
        ["unread"]
      );
    }

    // After load other data
    if (
      !this.state.appReady &&
      this.props.creditsStatus === "success" &&
      this.props.numbersStatus === "success" &&
      this.props.numbersFilterValue !== null &&
      this.props.countsStatus === "success"
    ) {
      this.setState({ appReady: true, companyPage: false });
    }
  }

  loadNumbersFilter() {
    let numbersFilter = this.props.currentCompany.filter_number_settings;
    numbersFilter = numbersFilter ? numbersFilter.split(",") : ["all"];
    let numbers = [];
    if (numbersFilter[0] === "all") {
      for (let i = 0; i < this.props.numbers.length; i++) {
        numbers.push(this.props.numbers[i].number);
      }
    } else {
      for (let i = 0; i < this.props.numbers.length; i++) {
        if (numbersFilter.indexOf(this.props.numbers[i].number) !== -1) {
          numbers.push(this.props.numbers[i].number);
        }
      }
    }
    const all = this.props.numbers.length === numbers.length;
    this.props.setNumbersFilter(
      this.props.currentCompany.id,
      numbers,
      all,
      false
    );
    PusherConfig.setNumbersFilter(numbers);
  }

  loadSenderNumber() {
    let senderNumber = null;
    const cookieSenderNumber = this.props.cookies.get("sender_number");
    for (let i = 0; i < this.props.numbers.length; i++) {
      if (this.props.numbers[i].number === cookieSenderNumber) {
        senderNumber = cookieSenderNumber;
        break;
      }
      if (i === 0 || this.props.numbers[i].is_default) {
        senderNumber = this.props.numbers[i].number;
      }
    }
    this.props.setSenderNumber(senderNumber);
  }

  checkRedirect() {
    const search = queryString.parse(this.props.location.search);
    const urls = [
      "",
      "/",
      "/login",
      "/logout",
      "/companies/switch",
      "/hub",
      "/hub/",
    ];
    if (search._return && urls.indexOf(search._return) === -1) {
      return { pathname: search._return };
    } else if (urls.indexOf(this.props.location.pathname) !== -1) {
      if (this.props.location.hash) {
        return { pathname: "/hub/messages", hash: this.props.location.hash };
      } else {
        return { pathname: "/hub/messages" };
      }
    } else if (
      !this.props.location.hash &&
      this.props.loggedUser &&
      this.props.loggedUser.skip_step5
    ) {
      return { hash: "modal-host-landline" };
    }
  }

  render() {
    let redirect = this.checkRedirect();
    if (redirect) {
      return (
        <ConnectedRouter history={history}>
          <Redirect to={redirect} />
        </ConnectedRouter>
      );
    }

    if (this.state.companyPage) {
      return (
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="*" component={ChooseOrganizationPage} />
          </Switch>
        </ConnectedRouter>
      );
    }

    if (!this.state.appReady || !this.props.loggedUser) {
      return <AppLoading />;
    }

    return (
      <>
        {/* Cordova app has a different flow for now. Will be changed in the future. */}
        {!isCordovaApp() && <TwilioInitializer />}
        <ConnectedRouter history={history}>
          <Switch>
            <Route
              path="/hub/:tab(messages|calls|people)/:threadType(contact|group)/:interlocutorId/:subcontactId"
              component={HubPage}
            />
            <Route
              path="/hub/:tab(messages|calls|people)/:threadType(contact|group)/:interlocutorId"
              component={HubPage}
            />
            <Route
              path="/hub/:tab(groups)/:interlocutorId"
              component={HubPage}
            />
            <Route
              path="/hub/:tab(messages|calls|groups|people)"
              component={HubPage}
            />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </ConnectedRouter>
        <LegacyExtendSession />
        {isCordovaApp() && <NewVersionAvailableTextModal />}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(RouterClass));
