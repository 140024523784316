export function ready(ready) {
  return function (dispatch) {
    dispatch({
      type: "TWILIO/READY",
      ready,
    });
  };
}

export function unsupportedBrowser() {
  return function (dispatch) {
    dispatch({ type: "TWILIO/UNSUPPORTED_BROWSER" });
  };
}

export function call(from, contactId, minutePrice, status) {
  return function (dispatch) {
    dispatch({
      type: "TWILIO/CALL",
      from,
      contactId,
      minutePrice,
      status,
    });
  };
}

export function callStatus(status) {
  return function (dispatch) {
    dispatch({
      type: "TWILIO/CALL_STATUS",
      status,
    });
  };
}

export function callTtv(from, text, language, voice, status) {
  return function (dispatch) {
    dispatch({
      type: "TWILIO/CALL_TTV",
      from,
      text,
      language,
      voice,
      status,
    });
  };
}

export function callTtvStatus(status) {
  return function (dispatch) {
    dispatch({
      type: "TWILIO/CALL_TTV_STATUS",
      status,
    });
  };
}
