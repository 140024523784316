import React from "react";
import { connect } from "react-redux";
import { history } from "../../store";
import { ModalsContext } from "../../ModalsContext";
import { UncontrolledTooltip } from "reactstrap";

import { fetchContactData } from "../../actions/contacts";
import { checkAccount, fetchVoicePrice } from "../../actions/calls";

import { ReactComponent as CloseSvg } from "../../assets/img/icons/close.svg";
import { ReactComponent as MinimizeSvg } from "../../assets/img/icons/minimize_icon.svg";

import Avatar from "../Avatar";
import UnsupportedBrowserModal from "./UnsupportedBrowserModal";
import {
  contactsName,
  formatDuration,
  formatPhoneNumber,
  tooltipPropsTop,
  isCordovaApp,
  isIOs,
} from "../../helpers";
import TwilioConfig from "../../TwilioConfig";
import TooltipWhenOverflow from "../hub/HelperComponents/TooltipWhenOverflow";

function mapStateToProps(store, ownProps) {
  const contactId = parseInt(ownProps.param);
  let contactData = null;
  let contactStatus = null;

  if (contactId) {
    let contact =
      typeof store.contacts.data[contactId] === "undefined"
        ? null
        : store.contacts.data[contactId];
    if (contact) {
      contactData = [];
      for (let i = 0; i < contact.contacts_ids.length; i++) {
        if (
          typeof store.contacts.data[contact.contacts_ids[i]] !== "undefined"
        ) {
          contactData.push(store.contacts.data[contact.contacts_ids[i]]);
        }
      }
    }
    contactStatus =
      typeof store.contacts.dataStatus[contactId] === "undefined"
        ? null
        : store.contacts.dataStatus[contactId];
  }

  let voicePrice = null;
  let voicePriceStatus = null;
  if (contactData && contactData.length) {
    const countryId = contactData[0].country_id;
    voicePrice =
      typeof store.calls.voicePriceByCountry[countryId] !== "undefined"
        ? store.calls.voicePriceByCountry[countryId]
        : null;
    voicePriceStatus =
      typeof store.calls.voicePriceByCountryStatus[countryId] !== "undefined"
        ? store.calls.voicePriceByCountryStatus[countryId]
        : null;
  }

  let callStatus = null;
  let callFrom = null;
  let callTime = null;
  let callStartedAt = null;
  let callMinutePrice = null;
  if (store.twilio.callContactId === contactId) {
    callStatus = store.twilio.callStatus;
    callFrom = store.twilio.callFrom;
    callTime = store.twilio.callTime;
    callStartedAt = store.twilio.callStartedAt;
    callMinutePrice = store.twilio.callMinutePrice;
  }

  return {
    currentCompany: store.companies.currentCompany,
    companyId: store.companies.currentCompany.id,
    unsupportedBrowser: store.twilio.unsupportedBrowser,
    contactId: contactId,
    contactData: contactData,
    contactStatus: contactStatus,
    numbers: store.numbers.numbers,
    checkAccountResult: store.calls.checkAccount,
    checkAccountStatus: store.calls.checkAccountStatus,
    voicePrice: voicePrice,
    voicePriceStatus: voicePriceStatus,
    twilioReady: store.twilio.ready,
    callStatus: callStatus,
    callFrom: callFrom,
    callTime: callTime,
    callStartedAt: callStartedAt,
    callMinutePrice: callMinutePrice,
    twilioToken: store.calls.twilioToken,
    twilioVoiceToken: store.calls.twilioVoiceToken,
    loggedUser: store.users.loggedUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContactData: (companyId, contactId) =>
      dispatch(fetchContactData(companyId, contactId)),
    checkAccount: (companyId, contactId) =>
      dispatch(checkAccount(companyId, contactId)),
    fetchVoicePrice: (companyId, countryId) =>
      dispatch(fetchVoicePrice(companyId, countryId)),
    dispatchProxy: (dispatchRes) => dispatch(dispatchRes),
  };
}

class CallContactModal extends React.Component {
  static contextType = ModalsContext;

  constructor(props) {
    super(props);
    this.timeInterval = null;
    this.refreshContactTimeout = null;
    this.fromRef = React.createRef();
    this.updateView = this.updateView.bind(this);
    this.updateData = this.updateData.bind(this);
    this.close = this.close.bind(this);
    this.call = this.call.bind(this);
    this.decline = this.decline.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.receiveMessage = this.receiveMessage.bind(this);
    this.initAgain = this.initAgain.bind(this);
    this.state = {
      micAllowed: null,
      isShown: false,
      isHide: false,
      time: 0,
      spent: 0,
      micRejectionMessage: "",
    };
  }

  componentDidMount() {
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
    }
    const currentOpen = this.state.isShown && !this.state.isHide;
    const prevOpen = prevState.isShown && !prevState.isHide;
    if (currentOpen !== prevOpen && currentOpen) {
      this.props.checkAccount(this.props.companyId, this.props.contactId);
    }
    if (currentOpen && this.props.contactStatus === null) {
      this.props.fetchContactData(this.props.companyId, this.props.contactId);
    }
    if (
      currentOpen &&
      this.props.contactStatus === "success" &&
      this.props.voicePriceStatus === null
    ) {
      this.props.fetchVoicePrice(
        this.props.companyId,
        this.props.contactData[0].country_id
      );
    }
    if (
      this.props.callStatus === "connected" &&
      prevProps.callStatus !== "connected"
    ) {
      this.timeInterval = setInterval(this.updateData, 1000);
    }

    // Refresh contact data after failed call (to check number is unformatted)
    if (
      this.props.callStatus !== "connected" &&
      prevProps.callStatus === "connected" &&
      this.state.time < 5
    ) {
      clearTimeout(this.refreshContactTimeout);
      this.refreshContactTimeout = setTimeout(() => {
        this.props.fetchContactData(this.props.companyId, this.props.contactId);
      }, 2000);
    }

    if (
      this.state.isShown &&
      this.state.isShown !== prevState.isShown &&
      isCordovaApp()
    ) {
      window.addEventListener("message", this.receiveMessage, false);
      if (isIOs() && !this.props.twilioReady && this.props.twilioVoiceToken) {
        const initEvent = {
          event: "call-init",
          token: this.props.twilioVoiceToken,
        };
        this.sendMessage(initEvent);
      } else {
        // For Android
        this.sendMessage({ event: "mic-auth" });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    if (isCordovaApp()) {
      window.removeEventListener("message", this.receiveMessage, false);
    }
  }

  sendMessage(event) {
    window.parent.postMessage(event, "*");
  }

  receiveMessage(event) {
    switch (event.data.event) {
      case "mic-auth-rejected":
        if (event.data.data) {
          this.setState({
            micRejectionMessage: event.data.data,
            micAllowed: false,
          });
        } else {
          this.setState({
            micAllowed: false,
          });
        }
        break;
      case "mic-auth-accepted": {
        this.setState({
          micAllowed: true,
          micRejectionMessage: "",
        });
        if (
          this.props.currentCompany &&
          this.props.twilioToken &&
          !TwilioConfig.isInitialized() &&
          !isIOs()
        ) {
          TwilioConfig.init(
            this.props.twilioToken,
            this.props.currentCompany,
            this.props.loggedUser,
            this.props.dispatchProxy
          );
        }
        break;
      }
      default:
        console.log("Call contact Modal, No such event", event.data.event);
        break;
    }
  }

  updateView() {
    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  updateData() {
    const time = Math.ceil(
      (new Date().getTime() - this.props.callStartedAt) / 1000
    );
    const spent = Math.ceil(time / 60) * this.props.callMinutePrice;
    this.setState({
      time: time,
      spent: spent,
    });
  }

  close(e) {
    e.preventDefault();
    history.goBack();
  }

  call(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    if (this.props.voicePriceStatus === "success") {
      TwilioConfig.callContact(
        this.fromRef.current.value,
        this.props.contactId,
        this.props.contactData[0].phone_number,
        this.props.voicePrice
      );
    }
  }

  decline(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    TwilioConfig.disconnect();
  }

  initAgain() {
    if (isCordovaApp() && !isIOs()) {
      this.sendMessage({ event: "mic-auth" });
    }
  }

  render() {
    const { unsupportedBrowser, contactData } = this.props;
    const { micRejectionMessage } = this.state;

    if (unsupportedBrowser) {
      if (this.state.isShown && !this.state.isHide) {
        return (
          <UnsupportedBrowserModal
            mode="microphone"
            show
            closeModal={this.close}
          />
        );
      }
      return null;
    }

    const unformattedNumber =
      contactData &&
      contactData.length > 0 &&
      contactData[0].number_lookup &&
      !contactData[0].number_lookup.type;

    let content = <p className={"text-center text-muted"}>Loading...</p>;
    if (this.state.micAllowed === false) {
      content = (
        <React.Fragment>
          <p className="text-danger">
            {micRejectionMessage ||
              "Please allow us to use your microphone to make a call."}
          </p>
          {!isIOs() && !micRejectionMessage && (
            <button className="btn btn-link" onClick={this.initAgain}>
              Ask Again
            </button>
          )}
        </React.Fragment>
      );
    } else if (
      this.props.contactData &&
      this.props.contactData.length &&
      this.props.checkAccountStatus === "success"
    ) {
      if (this.props.checkAccountResult) {
        const name = contactsName(this.props.contactData, false, 4);
        const phonecode = this.props.contactData[0].country
          ? "" + this.props.contactData[0].country.phonecode
          : "";
        const number = formatPhoneNumber(
          "" + phonecode + this.props.contactData[0].phone_number
        );
        content = (
          <React.Fragment>
            {this.props.callStatus !== "connected" && (
              <h5 className="modal-title" id="MakeCallToUserLabel">
                {this.props.callStatus === "connecting"
                  ? "Calling"
                  : "Make a call to"}
              </h5>
            )}

            {this.props.contactData && this.props.contactData.length === 1 && (
              <Avatar
                isGroup={false}
                firstName={this.props.contactData[0].first_name}
                lastName={this.props.contactData[0].last_name}
                email={this.props.contactData[0].email}
                bgColor={this.props.contactData[0].color}
                size={75}
              />
            )}
            {this.props.contactData && this.props.contactData.length > 1 && (
              <Avatar isMultipleContacts size={75} />
            )}
            <div className="contact-info" style={{ padding: 0 }}>
              <span className="name">
                <TooltipWhenOverflow maxWidth={300} text={name}>
                  {name}
                </TooltipWhenOverflow>
              </span>
              <span className="number">{number}</span>
            </div>

            {!unformattedNumber && (
              <>
                {this.props.callStatus === "connected" && (
                  <div className="time">
                    <span>{formatDuration(this.state.time)}</span>
                  </div>
                )}
                {this.props.callStatus !== "connected" && (
                  <div className="number-select">
                    {this.props.callStatus === "connecting" && (
                      <span>
                        From: {formatPhoneNumber(this.props.callFrom)}
                      </span>
                    )}
                    {this.props.callStatus !== "connecting" && (
                      <React.Fragment>
                        <span>From:</span>
                        <select ref={this.fromRef}>
                          {this.props.numbers.map((number) => (
                            <option key={number.number} value={number.number}>
                              {`${number.number_formatted} (${
                                number.is_default ? "Me, " : ""
                              }${number.nickname})`}
                            </option>
                          ))}
                        </select>
                      </React.Fragment>
                    )}
                  </div>
                )}
                <div className="credit-info">
                  {(this.props.voicePriceStatus !== "success" ||
                    !this.props.twilioReady) && (
                    <React.Fragment>
                      <span className="mr-3">Initializing...</span>
                    </React.Fragment>
                  )}
                  {this.props.voicePriceStatus === "success" &&
                    this.props.twilioReady && (
                      <React.Fragment>
                        {this.props.callStatus === "connected" && (
                          <span
                            className="mr-3 d-block"
                            style={{ fontWeight: 400 }}
                          >
                            You spent {this.state.spent} credits
                          </span>
                        )}
                        {this.props.callStatus !== "connected" && (
                          <span className="mr-3">Ready</span>
                        )}
                        <span>{this.props.voicePrice} credits/min</span>
                      </React.Fragment>
                    )}
                </div>
                {(this.props.callStatus === "connecting" ||
                  this.props.callStatus === "connected") && (
                  <a href="#void" className="decline" onClick={this.decline}>
                    <i className="fa fa-phone"></i>
                  </a>
                )}
                {this.props.callStatus !== "connecting" &&
                  this.props.callStatus !== "connected" && (
                    <a
                      href="#void"
                      className="dial"
                      style={
                        this.props.voicePriceStatus === "success"
                          ? {}
                          : { opacity: 0.5 }
                      }
                      onClick={this.call}
                    >
                      <i className="fa fa-phone"></i>
                    </a>
                  )}
              </>
            )}
            {unformattedNumber && (
              <div className="mt-5 text-danger">
                <span id="CallContactModalUnformattedMsg">
                  <i
                    className="fa fa-warning mr-1"
                    style={{
                      fontSize: "16px",
                      verticalAlign: "middle",
                      marginTop: "-4px",
                    }}
                  ></i>
                  Unformatted number
                </span>
                <UncontrolledTooltip
                  {...tooltipPropsTop}
                  container="#CallContactModal"
                  target="#CallContactModalUnformattedMsg"
                  boundariesElement="scrollParent"
                >
                  Number lookup completed.
                  <br />
                  Format error.
                </UncontrolledTooltip>
              </div>
            )}
          </React.Fragment>
        );
      } else {
        content = (
          <p className={"text-center text-danger"}>
            Your account was marked for review, please contact support for any
            questions.
          </p>
        );
      }
    }
    return (
      <div
        className={
          "modal fade making-call" +
          (this.state.isShown && !this.state.isHide ? " show" : "")
        }
        style={
          this.props.show || this.state.isShown ? { display: "block" } : {}
        }
        id="CallContactModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="CallContactModalLabel"
        aria-hidden={this.props.show || this.state.isShown ? "false" : "true"}
      >
        <div className="modal-backdrop fade show" onClick={this.shakeModal} />
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
          style={{ zIndex: 100 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              {(this.props.callStatus === "connecting" ||
                this.props.callStatus === "connected") && (
                <div
                  className="close-icon"
                  data-dismiss="modal"
                  data-tooltip="tooltip"
                  data-placement="top"
                  title="Minimize"
                  onClick={this.close}
                >
                  <MinimizeSvg width={18} />
                </div>
              )}
              {this.props.callStatus !== "connecting" &&
                this.props.callStatus !== "connected" && (
                  <div className="close-icon" onClick={this.close}>
                    <CloseSvg width={14} />
                  </div>
                )}
            </div>
            <div className="modal-body">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallContactModal);
