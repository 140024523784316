export function addNotification(
  body,
  title = null,
  autoHide = true,
  timeout = 5000
) {
  return function (dispatch) {
    dispatch({
      type: "NOTIFICATIONS/ADD",
      body,
      title,
      autoHide,
      timeout,
    });
  };
}

export function removeNotification(body, title = null) {
  return function (dispatch) {
    dispatch({
      type: "NOTIFICATIONS/REMOVE",
      body,
      title,
    });
  };
}
