import React from "react";
import ErrorPage from "../pages/ErrorPage";
import logger from "../logger";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    logger.push({
      error: error.message,
      stack: error.stack,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      url: window.location.href,
      windowSize: `${
        window.innerWidth
          ? window.innerWidth
          : document.body.offsetWidth
          ? document.body.offsetWidth
          : 0
      }x${
        window.innerHeight
          ? window.innerHeight
          : document.body.offsetHeight
          ? document.body.offsetHeight
          : 0
      }`,
      info,
      appName: process.env.REACT_APP_LOGGLY_APP_NAME,
      severity: "error",
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
