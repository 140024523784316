import React from "react";
import { connect } from "react-redux";
import { cookies } from "../store";
import jquery from "jquery";
import "bootstrap";
import {
  closeAccountIssueBar,
  clearIsAutoSwitched,
} from "../actions/companies";
import { toggleSidebar, setTopBarsHeight, showLoading } from "../actions/main";
import Intercom from "react-intercom";

import { ModalsContext } from "../ModalsContext";
import CallMinimizeBar from "./CallMinimizeBar";
import AccountIssueBar from "./AccountIssueBar";
import Header from "./Header";
import Sidebar from "./Sidebar";
import BlockContactModal from "./modals/BlockContactModal";
import UnblockContactModal from "./modals/UnblockContactModal";
import UnsubscribeContactModal from "./modals/UnsubscribeContactModal";
import SubscribeContactModal from "./modals/SubscribeContactModal";
import ClearAllUnreadModal from "./modals/ClearAllUnreadModal";
import ClearAllUndeliveredModal from "./modals/ClearAllUndeliveredModal";
import FilterNumbersModal from "./modals/FilterNumbersModal";
import NewCallDialpadModal from "./modals/NewCallDialpadModal";
import CallContactModal from "./modals/CallContactModal";
import LayoutModals from "./LayoutModals";
import AccountIssueOverlay from "./AccountIssueOverlay";
import Notifications from "./Notifications";
import Loading from "./Loading";
import { getAccountIssueInfo, isCordovaApp } from "../helpers";

import "../assets/components_react/LayoutDefault.scss";
import "../assets/css/iziModal.min.css";
import "../assets/css/audioplayer.css";
import "../assets/css/popbox.css";
import "font-awesome/scss/font-awesome.scss";

window.jQuery = window.$ = jquery;
window.Popper = require("popper.js").default;

function mapStateToProps(store) {
  let modal = null;
  let modalParam = null;
  if (
    store.router.location.hash &&
    store.router.location.hash.substr(0, 7) === "#modal-"
  ) {
    let tmp = store.router.location.hash.substr(7).split("/");
    modal = tmp[0];
    modalParam = tmp.length > 1 ? tmp.slice(1).join("/") : null;
  }
  return {
    isLoading: store.main.isLoading,
    modal,
    modalParam,
    callStatus: store.twilio.callStatus,
    loggedUser: store.users.loggedUser,
    credits: store.users.credits,
    intercomAppId: store.settings.settings
      ? store.settings.settings.intercomAppId
      : null,
    isAdmin:
      store.companies.currentCompany &&
      (store.companies.currentCompany.is_main_owner ||
        store.companies.currentCompany.is_admin_plus ||
        store.companies.currentCompany.is_admin_user),
    companyId: store.companies.currentCompany
      ? store.companies.currentCompany.id
      : null,
    company: store.companies.currentCompany,
    isCurrentCompanyAutoSwitched: store.companies.isCurrentAutoSwitched,
    accountIssueBarCloseState: store.companies.accountIssueBarCloseState,
    isSidebarOpen: store.main.isSidebarOpen,
    isLeftPanelScrollingDown: store.main.isLeftPanelScrollingDown,
    isMainPanelScrollingUp: store.main.isMainPanelScrollingUp,
    topBarsHeight: store.main.topBarsHeight,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeAccountIssueBar: (companyId, issueKey) =>
      dispatch(closeAccountIssueBar(companyId, issueKey)),
    toggleSidebar: () => dispatch(toggleSidebar()),
    setTopBarsHeight: (height) => dispatch(setTopBarsHeight(height)),
    clearIsAutoSwitched: () => dispatch(clearIsAutoSwitched()),
    showLoading: () => dispatch(showLoading()),
  };
}

class LayoutDefault extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isModalOpen = this.isModalOpen.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.updateHeight = this.updateHeight.bind(this);

    this.state = {
      openModals: [],
    };
  }

  componentDidMount() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  componentDidUpdate(prevProps) {
    const {
      topBarsHeight,
      isLeftPanelScrollingDown,
      isMainPanelScrollingUp,
    } = this.props;
    this.updateHeight();

    if (topBarsHeight !== prevProps.topBarsHeight) {
      document.documentElement.style.setProperty(
        "--top-bars-height",
        `${topBarsHeight}px`
      );
    }

    if (isLeftPanelScrollingDown !== prevProps.isLeftPanelScrollingDown) {
      if (isLeftPanelScrollingDown) {
        document.body.classList.add("left-panel-scrolling-down");
      } else {
        document.body.classList.remove("left-panel-scrolling-down");
      }
    }

    if (isMainPanelScrollingUp !== prevProps.isMainPanelScrollingUp) {
      if (isMainPanelScrollingUp) {
        document.body.classList.add("main-panel-scrolling-up");
      } else {
        document.body.classList.remove("main-panel-scrolling-up");
      }
    }
  }

  updateHeight() {
    const { topBarsHeight, setTopBarsHeight } = this.props;
    const { clientHeight } = this.topBars;
    if (topBarsHeight !== clientHeight) {
      setTopBarsHeight(clientHeight);
    }
  }

  openModal(modal) {
    let openModals = this.state.openModals;
    openModals.push(modal);
    this.setState({ openModals: openModals });
  }

  closeModal(modal) {
    let openModals = this.state.openModals;
    if (openModals.indexOf(modal) !== -1) {
      openModals.splice(openModals.indexOf(modal), 1);
      this.setState({ openModals: openModals });
    }
  }

  isModalOpen(modal) {
    return this.state.openModals.indexOf(modal) === -1 ? false : true;
  }

  closeAllModals(modal) {
    this.setState({ openModals: [] });
  }

  render() {
    const {
      isLoading,
      isSidebarOpen,
      company,
      isMobile,
      topBarsHeight,
      toggleSidebar,
      clearIsAutoSwitched,
      showLoading,
    } = this.props;

    const openModal = this.openModal;
    const closeModal = this.closeModal;
    const isModalOpen = this.isModalOpen;
    const closeAllModals = this.closeAllModals;
    const isAdminCookie = cookies.get("is_admin") === "1";

    // Alert about credits and account suspended
    const accountIssueInfo = !this.props.company
      ? null
      : getAccountIssueInfo(
          this.props.company.is_billing_issue,
          this.props.company.is_suspended,
          this.props.company.number_is_deleted,
          this.props.company.package,
          this.props.credits,
          this.props.company.billing_issue_days,
          this.props.isAdmin,
          null
        );
    if (this.props.company && !accountIssueInfo) {
      this.props.closeAccountIssueBar(this.props.company.id, null);
    }

    // Account about invalid email
    const emailIssueInfo = !this.props.loggedUser
      ? null
      : getAccountIssueInfo(
          null,
          null,
          null,
          null,
          null,
          null,
          this.props.isAdmin,
          this.props.loggedUser.is_invalid_email
        );

    const intercomEnabled =
      this.props.loggedUser &&
      this.props.intercomAppId &&
      !isAdminCookie &&
      company &&
      !company.is_demo_account;
    let intercomCustomSelector = null;
    if (
      (accountIssueInfo &&
        ["hard", "inactive"].indexOf(accountIssueInfo.issueKey) !== -1) ||
      isCordovaApp()
    ) {
      intercomCustomSelector = ".btn-intercom-launcher";
    }

    const headerHeight =
      !isMobile || (accountIssueInfo && accountIssueInfo.isOverlay) ? 60 : 0;

    let sidebarTop = topBarsHeight;
    if (!isMobile || (accountIssueInfo && accountIssueInfo.isOverlay)) {
      sidebarTop += 60;
    } else {
      sidebarTop += isMobile ? 45 : 60;
    }

    return (
      <ModalsContext.Provider
        value={{ openModal, closeModal, isModalOpen, closeAllModals }}
      >
        <div className="top-bars" ref={(topBars) => (this.topBars = topBars)}>
          {(this.props.callStatus === "connected" ||
            this.props.callStatus === "connecting") && <CallMinimizeBar />}
          {this.props.company && accountIssueInfo && (
            <AccountIssueBar
              {...accountIssueInfo}
              companyId={this.props.company ? this.props.company.id : 0}
              closeState={this.props.accountIssueBarCloseState}
              onClose={this.props.closeAccountIssueBar}
              showLoading={showLoading}
            />
          )}
          {this.props.loggedUser && emailIssueInfo && (
            <AccountIssueBar
              {...emailIssueInfo}
              companyId={this.props.company ? this.props.company.id : 0}
              showLoading={showLoading}
            />
          )}
        </div>
        <div className="main-tab-content" style={{ marginTop: topBarsHeight }}>
          {(!isMobile || (accountIssueInfo && accountIssueInfo.isOverlay)) && (
            <Header
              style={{ top: topBarsHeight }}
              openSidebar={toggleSidebar}
              showLoading={showLoading}
            />
          )}
          {this.props.company && (
            <Sidebar
              style={{ top: `${sidebarTop}px` }}
              isOpen={isSidebarOpen}
              isMobile={isMobile}
              closeSidebar={toggleSidebar}
              expandSidebar={() =>
                window.innerWidth >= 992
                  ? document.body.classList.add("drawer-active")
                  : null
              }
              collapseSidebar={() =>
                window.innerWidth >= 992
                  ? document.body.classList.remove("drawer-active")
                  : null
              }
              showLoading={showLoading}
            />
          )}
          <div
            className={"page-wrapper " + this.props.className}
            style={{ minHeight: `calc(100vh - ${topBarsHeight}px)` }}
          >
            {this.props.children}
          </div>
          {this.props.company && accountIssueInfo && (
            <AccountIssueOverlay
              {...accountIssueInfo}
              isBillingIssue={this.props.company.is_billing_issue}
              isSuspended={this.props.company.is_suspended}
              numberIsDeleted={this.props.company.number_is_deleted}
              package={this.props.company.package}
              credits={this.props.credits > 0 ? 1 : 0}
              topBarsHeight={topBarsHeight + headerHeight}
              showLoading={showLoading}
            />
          )}
        </div>

        <Notifications />

        {/* This way is deprecated. Please use LayoutModals, as below. */}
        {this.props.company && (
          <React.Fragment>
            <BlockContactModal
              show={this.props.modal === "block-contact"}
              param={this.props.modalParam}
            />
            <UnblockContactModal
              show={this.props.modal === "unblock-contact"}
              param={this.props.modalParam}
            />
            <UnsubscribeContactModal
              show={this.props.modal === "unsubscribe-contact"}
              param={this.props.modalParam}
            />
            <SubscribeContactModal
              show={this.props.modal === "subscribe-contact"}
              param={this.props.modalParam}
            />
            <ClearAllUnreadModal
              show={this.props.modal === "clear-all-unread"}
              param={this.props.modalParam}
            />
            <ClearAllUndeliveredModal
              show={this.props.modal === "clear-all-undelivered"}
              param={this.props.modalParam}
            />
            <FilterNumbersModal
              show={this.props.modal === "filter-numbers"}
              param={this.props.modalParam}
            />
            <NewCallDialpadModal
              show={this.props.modal === "new-call-dialpad"}
              param={this.props.modalParam}
            />
            <CallContactModal
              show={this.props.modal === "call-contact"}
              param={this.props.modalParam}
            />
          </React.Fragment>
        )}

        {/* All new modals should be done via below new modal manager*/}
        <LayoutModals
          modal={this.props.modal}
          param={this.props.modalParam}
          isUserLoaded={this.props.loggedUser && this.props.loggedUser.id > 0}
          isCompanyLoaded={this.props.company && this.props.company.id > 0}
          company={this.props.company}
          isCurrentCompanyAutoSwitched={this.props.isCurrentCompanyAutoSwitched}
          onCloseCurrentCompanyAutoSwitched={clearIsAutoSwitched}
          showLoading={showLoading}
        />

        {intercomEnabled && (
          <Intercom
            appID={this.props.intercomAppId}
            user_id={this.props.loggedUser.id}
            user_hash={this.props.loggedUser.intercomUserHash}
            email={this.props.loggedUser.email}
            name={
              this.props.loggedUser.first_name +
              " " +
              this.props.loggedUser.last_name
            }
            custom_launcher_selector={intercomCustomSelector}
            hide_default_launcher={true}
          />
        )}

        {isLoading && <Loading />}
      </ModalsContext.Provider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutDefault);
