import React from "react";
import { connect } from "react-redux";
import { cookies, history } from "../store";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

import {
  formatPhoneNumber,
  getFormattedAccountCredits,
  isCordovaApp,
  tooltipProps,
  getV3Url,
} from "../helpers";
import { setFilter } from "../actions/threads";
import { logout } from "../actions/users";

// import {ReactComponent as DoubleCreditIcon} from '../assets/img/icons/double_credits.svg';
import { ReactComponent as AddIcon } from "../assets/img/icons/add.svg";
import { ReactComponent as MessageSingleIcon } from "../assets/img/icons/message_single.svg";
import { ReactComponent as MessageSingleFilledIcon } from "../assets/img/icons/message-single-filled.svg";
import {
  ReactComponent as AlertIconSvg,
  ReactComponent as AlertIcon,
} from "../assets/img/icons/alert.svg";
import { ReactComponent as AlertFilledIcon } from "../assets/img/icons/alert_filled.svg";
import { ReactComponent as HubIcon } from "../assets/img/icons/hub-icon.svg";
import { ReactComponent as QuestionIcon } from "../assets/img/icons/question.svg";
import { ReactComponent as LogoutIcon } from "../assets/img/icons/logout.svg";

//import timelineIcon from '../assets/img/icons/timeline_icon.svg';
//import timelineWhiteIcon from '../assets/img/icons/timeline_icon_white.svg';
import contactIcon from "../assets/img/icons/contact_icon.svg";
import contactWhiteIcon from "../assets/img/icons/contact_icon_white.svg";
import groupsIcon from "../assets/img/icons/groups_icon.svg";
import groupsWhiteIcon from "../assets/img/icons/groups_icon_white.svg";
import voiceIcon from "../assets/img/icons/voice_icon.svg";
import voiceWhiteIcon from "../assets/img/icons/voice_icon_white.svg";
import campaignsIcon from "../assets/img/icons/campaigns_icon.svg";
import campaignsWhiteIcon from "../assets/img/icons/campaigns_icon_white.svg";
import analyticsIcon from "../assets/img/icons/analytics_icon.svg";
import analyticsWhiteIcon from "../assets/img/icons/analytics_icon_white.svg";
import moreIcon from "../assets/img/icons/more_icon.svg";
import moreWhiteIcon from "../assets/img/icons/more_icon_white.svg";
import backIconWhite from "../assets/img/icons/back_icon.svg";
import backIconBlack from "../assets/img/icons/back_icon_black.svg";
import logIconWhite from "../assets/img/icons/log1.png";
import logIconBlack from "../assets/img/icons/log.png";
import reportsIconWhite from "../assets/img/icons/flip1.png";
import reportsIconBlack from "../assets/img/icons/flip.png";
import integrationsIconWhite from "../assets/img/icons/syn1.png";
import integrationsIconBlack from "../assets/img/icons/syn.png";
import templatesIconWhite from "../assets/img/icons/arc1.png";
import templatesIconBlack from "../assets/img/icons/arc.png";
import webwidgetsIconWhite from "../assets/img/icons/flip1.png";
import webwidgetsIconBlack from "../assets/img/icons/flip.png";
import communityIconWhite from "../assets/img/icons/fb-black.png";
import communityIconBlack from "../assets/img/icons/fb.png";
import helpIconWhite from "../assets/img/icons/help-black.png";
import helpIconBlack from "../assets/img/icons/help.png";
import NewVersionLink from "./hub/NewVersionLink";

function mapStateToProps(store) {
  return {
    loggedUser: store.users.loggedUser,
    currentCompany: store.companies.currentCompany,
    numbers: store.numbers.numbers,
    numbersFilter: store.numbers.numbersFilter,
    credits: store.users.credits,
    unreads: store.threads.unreads,
    unreadsTotal: store.threads.unreadsTotal,
    unreadsStatus: store.threads.unreadsStatus,
    undelivereds: store.threads.undelivereds,
    undeliveredsTotal: store.threads.undeliveredsTotal,
    undeliveredsStatus: store.threads.undeliveredsStatus,
    intercomAppId: store.settings.settings
      ? store.settings.settings.intercomAppId
      : null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showUnread: () => {
      dispatch({ type: "THREADS/UNIGNORE_UNREAD" });
      dispatch(setFilter("unread"));
    },
    showUndelivered: () => {
      dispatch({ type: "THREADS/IGNORE_UNREAD" });
      dispatch(setFilter("undelivered"));
    },
    logout: () => dispatch(logout()),
  };
}

class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.showUnread = this.showUnread.bind(this);
    this.showUndelivered = this.showUndelivered.bind(this);
    this.showChooseOrganization = this.showChooseOrganization.bind(this);
    this.showFilterNumber = this.showFilterNumber.bind(this);
    this.switchGroup = this.switchGroup.bind(this);

    this.state = {
      group: "main",
    };
  }

  showUnread(e) {
    e && e.preventDefault();
    e && e.stopPropagation();

    const { showUnread, closeSidebar } = this.props;
    showUnread();
    closeSidebar();
    history.push("/hub/messages");
  }

  showUndelivered(e) {
    e && e.preventDefault();
    e && e.stopPropagation();

    const { showUndelivered, closeSidebar } = this.props;
    showUndelivered();
    closeSidebar();
    history.push("/hub/messages");
  }

  showChooseOrganization(e) {
    e && e.preventDefault();
    e && e.stopPropagation();

    const { closeSidebar } = this.props;
    closeSidebar();
    history.push("#modal-choose-organization");
  }

  showFilterNumber(e) {
    e && e.preventDefault();
    e && e.stopPropagation();

    const { closeSidebar } = this.props;
    closeSidebar();
    history.push("#modal-filter-numbers");
  }

  switchGroup(e) {
    e && e.preventDefault();
    e && e.stopPropagation();

    const { group } = e.currentTarget.dataset;
    this.setState({ group });
  }

  renderGroup() {
    const { group } = this.state;
    const { loggedUser, currentCompany } = this.props;
    const isV2Default = loggedUser && loggedUser.default_app_version === "v2";
    const isCordova = isCordovaApp();

    let active = "hub";
    if (window.location.pathname.substr(0, 10) === "/hub/calls") {
      active = "calls";
    }

    if (group === "main") {
      return (
        <React.Fragment>
          <li>
            <Link
              className={active === "hub" ? "active" : ""}
              to="/hub/messages"
            >
              <HubIcon width="25.601" height="21.185" />
              <span style={{ marginLeft: "5px" }}>
                {isV2Default ? "Hub/Inbox" : "Hub"}
              </span>
            </Link>
          </li>
          {!isCordova && currentCompany.contact_management !== 1 && (
            <li>
              <NewVersionLink link={getV3Url("hub/people")}>
                <img
                  className="dark-icon"
                  src={contactIcon}
                  alt="Contacts 1.0"
                />
                <img
                  className="white-icon"
                  src={contactWhiteIcon}
                  alt="Contacts 1.0"
                />
                <span>Contacts 1.0</span>
              </NewVersionLink>
            </li>
          )}
          {!isCordova && (
            <li>
              <NewVersionLink link={getV3Url("hub/groups")}>
                <img className="dark-icon" src={groupsIcon} alt="Groups 1.0" />
                <img
                  className="white-icon"
                  src={groupsWhiteIcon}
                  alt="Groups 1.0"
                />
                <span>Groups 1.0</span>
              </NewVersionLink>
            </li>
          )}
          {!isCordova && isV2Default && (
            <li>
              <Link
                className={active === "calls" ? "active" : ""}
                to="/hub/calls#modal-new-call"
              >
                <img className="dark-icon" src={voiceIcon} alt="Voice" />
                <img className="white-icon" src={voiceWhiteIcon} alt="Voice" />
                <span>Voice</span>
              </Link>
            </li>
          )}
          {!isCordova && currentCompany.contact_management !== 1 && (
            <li>
              <NewVersionLink link={getV3Url("hub/menus/filter/campaigns")}>
                <img
                  className="dark-icon"
                  src={campaignsIcon}
                  alt="Campaigns 1.0"
                />
                <img
                  className="white-icon"
                  src={campaignsWhiteIcon}
                  alt="Campaigns 1.0"
                />
                <span>Campaigns 1.0</span>
              </NewVersionLink>
            </li>
          )}
          {!isCordova && currentCompany.is_admin_user && (
            <li>
              <a href="#void" data-group="analytics" onClick={this.switchGroup}>
                <img
                  className="dark-icon"
                  src={analyticsIcon}
                  alt="Analytics 1.0"
                />
                <img
                  className="white-icon"
                  src={analyticsWhiteIcon}
                  alt="Analytics 1.0"
                />
                <span>Analytics 1.0</span>
              </a>
            </li>
          )}
          {!isCordova && (
            <li>
              <a href="#void" data-group="more" onClick={this.switchGroup}>
                <img className="dark-icon" src={moreIcon} alt="More" />
                <img className="white-icon" src={moreWhiteIcon} alt="More" />
                <span>More</span>
              </a>
            </li>
          )}
        </React.Fragment>
      );
    } else if (group === "analytics") {
      return !currentCompany.is_admin_user ? null : (
        <React.Fragment>
          <li>
            <a href="#void" data-group="main" onClick={this.switchGroup}>
              <img className="dark-icon" src={backIconBlack} alt="Back" />
              <img className="white-icon" src={backIconWhite} alt="Back" />
              <span>Back</span>
            </a>
          </li>
          <li>
            <NewVersionLink
              link={getV3Url(
                "hub/menus/filter/analytics/logs/submenu/legacy-logs"
              )}
            >
              <img className="dark-icon" src={logIconBlack} alt="Logs 1.0" />
              <img className="white-icon" src={logIconWhite} alt="Logs 1.0" />
              <span>Logs 1.0</span>
            </NewVersionLink>
          </li>
          <li>
            <NewVersionLink
              link={getV3Url("hub/menus/filter/analytics/reports")}
            >
              <img
                className="dark-icon"
                src={reportsIconBlack}
                alt="Reports 1.0"
              />
              <img
                className="white-icon"
                src={reportsIconWhite}
                alt="Reports 1.0"
              />
              <span>Reports 1.0</span>
            </NewVersionLink>
          </li>
        </React.Fragment>
      );
    } else if (group === "more") {
      return (
        <React.Fragment>
          <li>
            <a href="#void" data-group="main" onClick={this.switchGroup}>
              <img className="dark-icon" src={backIconBlack} alt="Back" />
              <img className="white-icon" src={backIconWhite} alt="Back" />
              <span>Back</span>
            </a>
          </li>
          {currentCompany.is_admin_user && (
            <React.Fragment>
              <li>
                <NewVersionLink
                  link={getV3Url(
                    "hub/menus/filter/settings/admin-settings/submenu/admin-integrations/active-apps"
                  )}
                >
                  <img
                    className="dark-icon"
                    src={integrationsIconBlack}
                    alt="Integrations 1.0"
                  />
                  <img
                    className="white-icon"
                    src={integrationsIconWhite}
                    alt="Integrations 1.0"
                  />
                  <span>Integrations 1.0</span>
                </NewVersionLink>
              </li>
              <li>
                <NewVersionLink link={getV3Url("hub/menus/filter/templates")}>
                  <img
                    className="dark-icon"
                    src={templatesIconBlack}
                    alt="Templates 1.0"
                  />
                  <img
                    className="white-icon"
                    src={templatesIconWhite}
                    alt="Templates 1.0"
                  />
                  <span>Templates 1.0</span>
                </NewVersionLink>
              </li>
            </React.Fragment>
          )}
          <li>
            <NewVersionLink link={getV3Url("hub/menus/filter/web-widgets")}>
              <img
                className="dark-icon"
                src={webwidgetsIconBlack}
                alt="Web Widgets 1.0"
              />
              <img
                className="white-icon"
                src={webwidgetsIconWhite}
                alt="Web Widgets 1.0"
              />
              <span style={{ whiteSpace: "nowrap" }}>Web Widgets 1.0</span>
            </NewVersionLink>
          </li>
          <li>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://help.pastorsline.com/" target="_blank">
              <img className="dark-icon" src={helpIconBlack} alt="Help Notes" />
              <img
                className="white-icon"
                src={helpIconWhite}
                alt="Help Notes"
              />
              <span>Help Notes</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/groups/pastorsline/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="dark-icon"
                src={communityIconBlack}
                alt="Community"
              />
              <img
                className="white-icon"
                src={communityIconWhite}
                alt="Community"
              />
              <span>Community</span>
            </a>
          </li>
        </React.Fragment>
      );
    }
  }

  render() {
    const {
      numbers,
      loggedUser,
      currentCompany,
      isOpen,
      isMobile,
      unreadsTotal,
      undeliveredsTotal,
      intercomAppId,
      credits,
      expandSidebar,
      collapseSidebar,
      logout,
    } = this.props;

    const sidebarHeight = isMobile
      ? "100vh - 45px - var(--top-bars-height)"
      : "100vh - 60px";
    const isSharedNumber =
      loggedUser &&
      loggedUser.is_shares_number > 0 &&
      currentCompany &&
      currentCompany.is_main_owner > 0;

    let assignedNumber = null;
    if (numbers) {
      for (let i = 0; i < numbers.length; i++) {
        if (numbers[i].is_default) {
          assignedNumber = numbers[i].number;
          break;
        }
      }
    }

    const isAdminCookie = cookies.get("is_admin") === "1";
    const isCordova = isCordovaApp();
    const intercomEnabled =
      loggedUser &&
      intercomAppId &&
      !isAdminCookie &&
      currentCompany &&
      !currentCompany.is_demo_account &&
      isCordova;
    let creditsSimple = "Loading credits...";
    if (credits !== null) {
      ({ creditsSimple } = getFormattedAccountCredits(credits));
    }

    return (
      <React.Fragment>
        <div
          style={this.props.style}
          className={"sidebar" + (isOpen ? " opened" : "")}
          id="sidebar"
          onMouseEnter={expandSidebar}
          onMouseLeave={collapseSidebar}
        >
          <Scrollbars
            autoHeight
            autoHeightMax={sidebarHeight}
            autoHide
            hideTracksWhenNotNeeded
          >
            <div className="sidebar-inner">
              <div className="sidebar-menu">
                <div className="mobile-top d-lg-none">
                  <ul>
                    {isCordova && loggedUser && (
                      <li className="nav-item">
                        <span
                          className="nav-link text-white"
                          style={{ paddingLeft: "20px", fontSize: "larger" }}
                        >
                          {(
                            loggedUser.first_name +
                            " " +
                            loggedUser.last_name
                          ).trim()}
                        </span>
                        <span className="nav-link text-white credits">
                          {creditsSimple}
                        </span>
                      </li>
                    )}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#modal-choose-organization"
                        onClick={this.showChooseOrganization}
                      >
                        {currentCompany
                          ? currentCompany.company_name
                          : "Choose organization"}
                        <i className="fa fa-caret-right"></i>
                      </a>
                    </li>
                    {numbers && (
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#modal-filter-numbers"
                          onClick={this.showFilterNumber}
                        >
                          {assignedNumber && (
                            <React.Fragment>
                              {isSharedNumber && (
                                <React.Fragment>
                                  <AlertIconSvg
                                    {...tooltipProps}
                                    title="You can test most of the features with this shared test number. Unfortunately, you will not be able to receive texts or calls."
                                    className="icon-white mr-1"
                                  />
                                  <span style={{ marginLeft: "11px" }}>
                                    {formatPhoneNumber(assignedNumber)}
                                  </span>
                                </React.Fragment>
                              )}
                              {!isSharedNumber &&
                                formatPhoneNumber(assignedNumber)}
                              <i className="fa fa-caret-down"></i>
                            </React.Fragment>
                          )}
                        </a>
                      </li>
                    )}
                    {/*<li className="nav-item">
                      <a className="nav-link double-credit" href="#void">
                        <DoubleCreditIcon width="24" height="24" />
                        <span style={{marginLeft: '20px'}}>Double credits</span>
                      </a>
                    </li>*/}
                    <li className="nav-item">
                      <a className="nav-link" href="#modal-make-new">
                        <AddIcon width="21.9" height="21.9" />
                        <span style={{ marginLeft: "20px" }}>Make New</span>
                      </a>
                    </li>
                    {unreadsTotal > 0 && (
                      <li className="nav-item">
                        <a
                          href="#void"
                          className="nav-link"
                          onClick={this.showUnread}
                        >
                          <span
                            className="btn icon toggle-icon badge-wrapper"
                            data-toggle="button"
                          >
                            <MessageSingleIcon width="22" />
                            <MessageSingleFilledIcon
                              width="20.911"
                              height="19.167"
                            />
                            <span
                              className="badge badge-pill primary-badge pull-right"
                              style={
                                unreadsTotal > 99 ? { fontSize: "8px" } : {}
                              }
                            >
                              {unreadsTotal > 99 ? "99+" : unreadsTotal}
                            </span>
                          </span>
                          <span>New Messages</span>
                        </a>
                      </li>
                    )}
                    {undeliveredsTotal > 0 && (
                      <li className="nav-item">
                        <a
                          href="#void"
                          className="nav-link"
                          onClick={this.showUndelivered}
                        >
                          <span
                            className="btn icon toggle-icon badge-wrapper"
                            data-toggle="button"
                          >
                            <AlertIcon width="24" />
                            <AlertFilledIcon width="22" height="20.167" />
                            <span
                              className="badge badge-pill primary-badge pull-right"
                              style={
                                undeliveredsTotal > 99
                                  ? { fontSize: "8px" }
                                  : {}
                              }
                            >
                              {undeliveredsTotal > 99
                                ? "99+"
                                : undeliveredsTotal}
                            </span>
                          </span>
                          <span>Undelivered</span>
                        </a>
                      </li>
                    )}
                    {intercomEnabled && (
                      <li className="nav-item">
                        <a
                          href="#void"
                          className="nav-link btn-intercom-launcher"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <span
                            style={{ display: "inline-block", width: "42px" }}
                          >
                            <QuestionIcon
                              width="21.9"
                              height="21.9"
                              className="icon-white"
                            />
                          </span>
                          <span>Ask a question</span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <ul className="nav nav-tabs">
                  {this.renderGroup()}
                  {/*<li>
                    <a href="#void" data-target="#timeline-page" data-toggle="tab" data-close="drawer">
                      <img className="dark-icon" src={timelineIcon} alt="Timeline" />
                      <img className="white-icon" src={timelineWhiteIcon} alt="Timeline" />
                      <span>Timeline</span>
                    </a>
                  </li>*/}
                </ul>
                {isCordova && loggedUser && (
                  <ul>
                    <li>
                      <a
                        href="#void"
                        onClick={(e) => {
                          e.stopPropagation();
                          logout();
                        }}
                      >
                        <LogoutIcon width="21.9" height="21.9" />
                        <span style={{ marginLeft: "20px" }}>Logout</span>
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </Scrollbars>
        </div>
        <div
          className={"sidebar-overlay" + (isOpen ? " opened" : "")}
          onClick={this.props.closeSidebar}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
