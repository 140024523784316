import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import FcmConfig from "../FcmConfig";
import { getPrimaryUrl } from "../helpers";
import { cookies, store } from "../store";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./constants";

// Error messages
const MAINTENANCE_BREAK = "Maintenance break";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const logoutAuth = () => {
  AxiosConfig.clearAuthToken();

  window.localStorage.clear();

  const cookiesNames = Object.keys(cookies.getAll());

  for (let i = 0; i < cookiesNames.length; i++) {
    cookies.remove(cookiesNames[i], { path: "/" });
  }
};

export const redirectToLoginPage = () => {
  window.location.href = getPrimaryUrl(
    `login?requesturl=/v2${window.location.pathname}`
  );
};

export const setTokens = (accessToken, refreshToken) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

const refreshAxios = axios.create({});

const getNewToken = async (originalRequest) => {
  try {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    const { data } = await refreshAxios.post(
      `${AxiosConfig.getEndpointAddress()}users/refresh-tokens.json`,
      {
        refresh_token: refreshToken,
      }
    );

    // extend legacy session after getting a new refresh token
    store.dispatch({
      type: "MAIN/EXTEND_LEGACY_SESSION_OPENER",
      show: true,
    });

    // resend the refresh token to Cordova app
    FcmConfig.setToken(data.refresh_token);
    FcmConfig.sendParentMessage("user");

    setTokens(data.access_token, data.refresh_token);
    originalRequest.headers["Authorization"] = `Bearer ${data.access_token}`;
    AxiosConfig.setAuthToken(data.access_token);

    processQueue(null, data.access_token);

    return axios(originalRequest);
  } catch (error) {
    logoutAuth();
    redirectToLoginPage();
    processQueue(error, null);

    return Promise.reject(error);
  } finally {
    isRefreshing = false;
  }
};

const storeOldRequestInQueue = (originalRequest) => {
  return new Promise((resolve, reject) => {
    failedQueue.push({ resolve, reject });
  })
    .then((token) => {
      originalRequest.headers["Authorization"] = "Bearer " + token;
      return axios(originalRequest);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const axiosErrorCallback = async (error) => {
  // Handle 401 unauthorized errors
  // Handle 503 service unavailable errors
  if (error.response && [401, 503].includes(error.response.status)) {
    if (error.response?.data?.message === MAINTENANCE_BREAK) {
      return logoutAuth();
    } else {
      const originalRequest = error.config;

      if (isRefreshing) {
        return storeOldRequestInQueue(originalRequest);
      }

      isRefreshing = true;

      return getNewToken(originalRequest);
    }
  }

  return Promise.reject(error);
};
