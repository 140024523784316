import React from "react";
import { connect } from "react-redux";
import { history } from "../../store";

import { isCordovaApp } from "../../helpers";
import { addQuickContact, fetchContactData } from "../../actions/contacts";
import ThreadItem from "./ThreadItem";

function mapStateToProps(store) {
  const userData = store.contacts.data[store.contacts.addQuickId];
  const fetchStatus = store.contacts.dataStatus[store.contacts.addQuickId];

  return {
    companyId: store.companies.currentCompany.id,
    addQuickContactStatus: store.contacts.addQuickStatus,
    addQuickContactId: store.contacts.addQuickId,
    userData,
    fetchStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addQuickContact: (companyId, contactData) =>
      dispatch(addQuickContact(companyId, contactData)),
    fetchContactData: (companyId, contactId) =>
      dispatch(fetchContactData(companyId, contactId)),
  };
}

class ThreadItemNewContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleNewContact = this.handleNewContact.bind(this);
    this.state = {
      afterAction: "message",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.addQuickContactStatus !== this.props.addQuickContactStatus &&
      this.props.addQuickContactStatus === "success"
    ) {
      if (this.props.callback) {
        if (this.props.purpose === "group-members-modal") {
          this.props.fetchContactData(
            this.props.companyId,
            this.props.addQuickContactId
          );
        } else {
          this.props.callback(this.props.addQuickContactId);
        }
      } else if (this.state.afterAction === "call" && !isCordovaApp()) {
        history.push("#modal-call-contact/" + this.props.addQuickContactId);
      } else {
        history.push(
          "/hub/messages/contact/" + this.props.addQuickContactId + "#new"
        );
      }
    }

    if (
      prevProps.fetchStatus !== this.props.fetchStatus &&
      this.props.fetchStatus === "success" &&
      this.props.purpose !== "new-call-modal" // this is because on the NewMessageReceiveModal will already do the fetch call inside the function and it's also done above. Trello link: https://trello.com/c/IA15MxM0/1267-add-new-message-new-person-in-hub-issue
    ) {
      if (this.props.callback) {
        this.props.callback(this.props.userData.id, this.props.userData);
      }
    }
  }

  handleNewContact(e, contact, afterAction) {
    e && e.preventDefault();
    e && e.stopPropagation();

    this.setState({ afterAction: afterAction }, () => {
      this.props.addQuickContact(this.props.companyId, {
        phone_number: contact.phone_number,
        country_id: contact.country ? contact.country.id : 0,
      });
    });
  }

  render() {
    const newContact = {
      phone_number: this.props.phoneNumber,
      color: "#FF5D34",
      country: {
        id: this.props.countryId,
        phonecode: this.props.countryCode,
      },
    };
    return (
      <ThreadItem
        item_key="contact-new"
        newContact={newContact}
        purpose={this.props.purpose}
        handleNewContact={this.handleNewContact}
        className={this.props.className}
        style={this.props.style}
        withoutLog={this.props.withoutLog}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreadItemNewContact);
