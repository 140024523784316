import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderAccountDropdown from "./HeaderAccountDropdown";
import HeaderUnreadDropdown from "./HeaderUnreadDropdown";
import HeaderUndeliveredDropdown from "./HeaderUndeliveredDropdown";
import {
  formatPhoneNumber,
  isCordovaApp,
  tooltipProps,
  getPrimaryUrl,
} from "../helpers";

import { logout } from "../actions/users";
import { fetchUnread, fetchUndelivered, setFilter } from "../actions/threads";

import InfoTooltip from "./elements/InfoTooltip";

import { ReactComponent as LogoSvg } from "../assets/img/icons/v2-logo.svg";
import { ReactComponent as AddSvg } from "../assets/img/icons/add.svg";
import { ReactComponent as AddFilledSvg } from "../assets/img/icons/add-filled.svg";
import { ReactComponent as MessageSingleFilledSvg } from "../assets/img/icons/message-single-filled.svg";
import { ReactComponent as ContactFilledIconSvg } from "../assets/img/icons/contact-filled-icon.svg";
import { ReactComponent as GroupFilledIconSvg } from "../assets/img/icons/group-filled-icon.svg";
import { ReactComponent as ImportIconSvg } from "../assets/img/icons/import-icon.svg";
import { ReactComponent as BroadcastIconSvg } from "../assets/img/icons/broadcast-icon.svg";
import { ReactComponent as CallIconFilledSvg } from "../assets/img/icons/call-icon-filled.svg";
import { ReactComponent as CampaignIconSvg } from "../assets/img/icons/campaign-icon.svg";
import { ReactComponent as AutoReplyIconSvg } from "../assets/img/icons/auto-reply-icon.svg";
import { ReactComponent as AlertIconSvg } from "../assets/img/icons/alert.svg";
import CustomModal from "./modals/CustomModal";

function mapStateToProps(store) {
  return {
    loggedUser: store.users.loggedUser,
    currentCompany: store.companies.currentCompany,
    numbers: store.numbers.numbers,
    numbersFilter: store.numbers.numbersFilter,
    credits: store.users.credits,
    unreads: store.threads.unreads,
    unreadsTotal: store.threads.unreadsTotal,
    unreadsStatus: store.threads.unreadsStatus,
    undelivereds: store.threads.undelivereds,
    undeliveredsTotal: store.threads.undeliveredsTotal,
    undeliveredsStatus: store.threads.undeliveredsStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    fetchUnread: (companyId, numbersFilter, limit) =>
      dispatch(fetchUnread(companyId, numbersFilter, limit)),
    fetchUndelivered: (companyId, numbersFilter, limit) =>
      dispatch(fetchUndelivered(companyId, numbersFilter, limit)),
    showUnread: () => {
      dispatch({ type: "THREADS/UNIGNORE_UNREAD" });
      dispatch(setFilter("unread"));
    },
    showUndelivered: () => {
      dispatch({ type: "THREADS/IGNORE_UNREAD" });
      dispatch(setFilter("undelivered"));
    },
  };
}

const Header = (props) => {
  const [isBillingNoAccessModal, setBillingNoAccessModal] = useState(false);
  const { loggedUser, currentCompany, showLoading } = props;

  let assignedNumber = null;
  let assignedNumberNickname = "";
  if (props.numbers) {
    for (let i = 0; i < props.numbers.length; i++) {
      if (props.numbers[i].is_default) {
        assignedNumber = props.numbers[i].number;
        assignedNumberNickname = props.numbers[i].nickname;
        break;
      }
    }
  }

  const isSharedNumber =
    props.loggedUser &&
    props.loggedUser.is_shares_number > 0 &&
    props.currentCompany &&
    props.currentCompany.is_main_owner > 0;
  const isAdminUser = currentCompany && currentCompany.is_admin_user;
  const isCordova = isCordovaApp();
  const numberFilterUrl =
    assignedNumber || !currentCompany || !isAdminUser
      ? "#modal-filter-numbers"
      : getPrimaryUrl(`users/listing?userCompanyId=${currentCompany.id}`);

  const openBillingModal = () => {
    setBillingNoAccessModal(true);
  };
  const closeBillingModal = () => {
    setBillingNoAccessModal(false);
  };

  useEffect(() => {
    window.$('.header [data-toggle="tooltip"]').tooltip();
  });

  return (
    <div className="header" style={props.style}>
      <div className="header-left">
        <Link to="/hub/messages" className="logo">
          <LogoSvg width="40" height="40" alt="" className="icon-white" />
        </Link>
      </div>
      <a
        id="mobile_btn"
        className="mobile_btn pull-left"
        href="#sidebar"
        onClick={(e) => {
          e.preventDefault();
          props.openSidebar();
        }}
      >
        <i className="fa fa-bars" aria-hidden="true"></i>
      </a>
      <ul className="nav user-menu left-menu pull-left">
        <li className="nav-item">
          <a className="nav-link" href="#modal-choose-organization">
            {props.currentCompany
              ? props.currentCompany.company_name
              : "Choose organization"}
            <i className="fa fa-caret-right"></i>
          </a>
        </li>

        {props.numbers && (
          <li className="nav-item">
            <a className="nav-link" href={numberFilterUrl}>
              {assignedNumber && (
                <React.Fragment>
                  {isSharedNumber && (
                    <React.Fragment>
                      <AlertIconSvg
                        {...tooltipProps}
                        title="You can test most of the features with this shared test number. Unfortunately, you will not be able to receive texts or calls."
                        className="icon-white mr-1"
                      />
                      <span style={{ marginLeft: "11px" }}>
                        {formatPhoneNumber(assignedNumber)}
                      </span>
                    </React.Fragment>
                  )}
                  {!isSharedNumber && formatPhoneNumber(assignedNumber)}
                  {assignedNumberNickname && (
                    <InfoTooltip
                      style={{ fontSize: "17px", color: "white" }}
                      content={`Me, ${assignedNumberNickname}`}
                    />
                  )}
                  <i className="fa fa-caret-down"></i>
                </React.Fragment>
              )}
              {!assignedNumber && (
                <span className="nav-link">
                  {isAdminUser ? "Assign a Number" : "Request a Number"}
                </span>
              )}
            </a>
          </li>
        )}

        {/*<li className="nav-item d-none d-sm-block double-credits">
          <a href="#"><?php include('icons/DoubleCredit.php') ?></a>
        </li>*/}
      </ul>
      <ul className="nav user-menu pull-right">
        {loggedUser && loggedUser.new_gifts > 0 && (
          <li
            className="nav-item d-none d-sm-block"
            style={{ marginRight: "15px" }}
          >
            <a
              href={getPrimaryUrl("inbox/dashboard#gifts")}
              className="nav-link gift-btn"
              onClick={showLoading}
            >
              <span {...tooltipProps} title={"New Gifts"}>
                <i className="material-icons">card_giftcard</i>
              </span>
              <span
                className="badge badge-pill primary-badge pull-right"
                style={{ marginLeft: "-7px" }}
              >
                {loggedUser.new_gifts}
              </span>
            </a>
          </li>
        )}
        {props.currentCompany && (
          <li className="nav-item dropdown d-none d-sm-block">
            <a
              href="#void"
              className="nav-link dropdown-toggle toggle-icon"
              data-toggle="dropdown"
            >
              <span {...tooltipProps} title={"New"}>
                <AddSvg width={21.9} />
                <AddFilledSvg width={21.9} />
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a href="#modal-new-message-receiver" className="dropdown-item">
                <span className="menu-icon">
                  <MessageSingleFilledSvg width={20.911} />
                </span>
                Text
              </a>
              <a href="#modal-add-person" className="dropdown-item">
                <span className="menu-icon">
                  <ContactFilledIconSvg width={12.026} />
                </span>
                Contact
              </a>
              {isCordova && (
                <a href="#modal-new-group" className="dropdown-item">
                  <span className="menu-icon">
                    <GroupFilledIconSvg width={13.865} />
                  </span>
                  Group
                </a>
              )}
              {!isCordova && (
                <a
                  href={getPrimaryUrl(
                    `users/select_company/${props.currentCompany.id}/?requesturl=/groups/add_group/`
                  )}
                  className="dropdown-item"
                  onClick={showLoading}
                >
                  <span className="menu-icon">
                    <GroupFilledIconSvg width={13.865} />
                  </span>
                  Group
                </a>
              )}
              <a
                href={getPrimaryUrl("contacts/inbox_import_step_1")}
                className="dropdown-item"
                onClick={showLoading}
              >
                <span className="menu-icon">
                  <ImportIconSvg width={14.301} />
                </span>
                Import
              </a>
              <a href="#modal-new-call" className="dropdown-item">
                <span className="menu-icon">
                  <BroadcastIconSvg width={13.538} />
                </span>
                Broadcast
              </a>
              {!isCordovaApp() && (
                <a href="#modal-new-call" className="dropdown-item">
                  <span className="menu-icon">
                    <CallIconFilledSvg width={10.041} />
                  </span>
                  Call
                </a>
              )}
              <a
                href={getPrimaryUrl("campaigns")}
                className="dropdown-item"
                onClick={showLoading}
              >
                <span className="menu-icon">
                  <CampaignIconSvg width={12.073} />
                </span>
                Campaign
              </a>
              <a
                href={getPrimaryUrl("groups/?addNew=1")}
                className="dropdown-item"
                onClick={showLoading}
              >
                <span className="menu-icon">
                  <AutoReplyIconSvg width={14.517} />
                </span>
                Keyword/Autoreply
              </a>
            </div>
          </li>
        )}
        <HeaderUnreadDropdown
          unreads={props.unreads ? props.unreads.slice(0, 2) : props.unreads}
          unreadsTotal={props.unreadsTotal}
          unreadsStatus={props.unreadsStatus}
          load={() =>
            props.fetchUnread(props.currentCompany.id, props.numbersFilter)
          }
          showUnread={() => props.showUnread()}
        />
        <HeaderUndeliveredDropdown
          undelivereds={props.undelivereds}
          undeliveredsTotal={props.undeliveredsTotal}
          undeliveredsStatus={props.undeliveredsStatus}
          load={() =>
            props.fetchUndelivered(props.currentCompany.id, props.numbersFilter)
          }
          showAll={() => props.showUndelivered()}
        />
        {!isCordova && (
          <HeaderAccountDropdown
            loggedUser={props.loggedUser}
            currentCompany={props.currentCompany}
            credits={props.credits}
            logout={props.logout}
            openBillingModal={openBillingModal}
            showLoading={showLoading}
          />
        )}
      </ul>

      {isBillingNoAccessModal && (
        <CustomModal
          show={true}
          title={"No Access"}
          buttons={[
            {
              type: "cancel",
              caption: "OK",
              handler: closeBillingModal,
            },
          ]}
        >
          <p
            className={"mb-3 text-left text-dark"}
            style={{ fontSize: "initial" }}
          >
            You are not authorized to manage billing. Please contact the owner
            of this account.
          </p>
          <p
            className={"mb-3 text-left text-dark"}
            style={{ fontSize: "initial" }}
          >
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href={
                "https://help.pastorsline.com/en/articles/3440498-how-to-give-billing-privileges-to-an-admin"
              }
            >
              LEARN MORE
            </a>
          </p>
        </CustomModal>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
