import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as DuplicateNumberAvatar } from "../assets/img/icons/duplicate-number-avatar.svg";
import { ReactComponent as AmSvg } from "../assets/img/icons/automated-message.svg";
import { ReactComponent as AmUserSvg } from "../assets/img/icons/automated_user.svg";
import { ReactComponent as ContestSvg } from "../assets/img/icons/contest.svg";
import { ReactComponent as DcSvg } from "../assets/img/icons/data-capture.svg";
import { ReactComponent as PollSvg } from "../assets/img/icons/poll.svg";
import { ReactComponent as BirthdaySvg } from "../assets/img/icons/birthday-sms.svg";
import { ReactComponent as NewMsgSvg } from "../assets/img/icons/new_message_icon.svg";
import { ReactComponent as AllPeopleSvg } from "../assets/img/icons/all-people.svg";

const md5 = require("md5");

export default class Avatar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gravatarChecked: false,
      useGravatar: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.email !== this.props.email) {
      this.setState({ gravatarChecked: false, useGravatar: false });
    }
  }

  render() {
    let letters = "";
    if (!this.props.isMultipleContacts) {
      if (this.props.firstName && this.props.lastName) {
        letters =
          this.props.firstName.substr(0, 1) + this.props.lastName.substr(0, 1);
      } else if (this.props.firstName) {
        letters = this.props.firstName.substr(0, 2);
      } else if (this.props.lastName) {
        letters = this.props.lastName.substr(0, 2);
      }
      letters = letters.toUpperCase();
    }

    // For use as logged user avatar in header
    if (this.props.headerMode) {
      return (
        <span className="user-img">
          {(!this.state.gravatarChecked || this.state.useGravatar) &&
            this.props.email && (
              <img
                alt=""
                className={"rounded-circle mr-2"}
                width={38}
                height={38}
                style={{ display: this.useGravatar ? "" : "none" }}
                src={
                  "https://www.gravatar.com/avatar/" +
                  md5(this.props.email.toLowerCase().trim()) +
                  "?d=404"
                }
                onLoad={(e) => {
                  e.target.onload = null;
                  this.setState({ useGravatar: true });
                }}
              />
            )}
          {!this.state.useGravatar && (
            <span className="text-avatar mr-2">{letters}</span>
          )}
        </span>
      );
    }

    // For use as contact/group avatar in threads list
    let className =
      "avatar" +
      (this.props.big ? " big-avatar" : "") +
      (this.props.bgLight ? " light" : "");
    let styles = {};
    if (this.props.isMultipleContacts) {
      className += " bg-white";
    } else if (this.props.campaignType) {
      className += " bg-white";
    } else if (this.props.isNote) {
      className += " bg-blue";
    } else if (letters === "" && !this.props.isNotification) {
      className += " bg-white";
    }
    if (this.props.bgColor) {
      if (this.props.bgLight) {
        styles = { backgroundColor: this.props.bgColor + "80" };
      } else {
        styles = { backgroundColor: this.props.bgColor };
      }
    } else if (this.props.isNotification) {
      styles = { backgroundColor: "#9253FF" };
    }
    const size = this.props.size ? this.props.size : 36;

    const content = (
      <React.Fragment>
        {!this.props.isMultipleContacts &&
          !this.props.campaignType &&
          !this.props.isNote &&
          !this.props.isNotification &&
          (!this.state.gravatarChecked || this.state.useGravatar) &&
          this.props.email && (
            <img
              alt=""
              style={{
                display: this.state.useGravatar ? "" : "none",
                width: "100%",
                objectFit: "contain",
              }}
              src={
                "https://www.gravatar.com/avatar/" +
                md5(this.props.email.toLowerCase().trim()) +
                "?d=404"
              }
              onLoad={(e) => {
                e.target.onload = null;
                this.setState({ useGravatar: true });
              }}
            />
          )}
        {!this.props.isMultipleContacts &&
          !this.props.campaignType &&
          !this.props.isNote &&
          !this.props.isNotification &&
          !this.state.useGravatar &&
          !letters &&
          !!this.props.isGroup && (
            <React.Fragment>
              {!!this.props.isAdhocGroup && (
                <NewMsgSvg
                  width={size}
                  height={size}
                  className={this.props.iconBlack ? "icon-black" : "icon-grey2"}
                />
              )}
              {!!this.props.isAllPeopleGroup && (
                <AllPeopleSvg
                  width={size}
                  height={size}
                  className={this.props.iconBlack ? "icon-black" : ""}
                />
              )}
              {!this.props.isAdhocGroup && !this.props.isAllPeopleGroup && (
                <svg
                  width={size}
                  height={size}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                  className={this.props.bgColor ? null : "icon-grey3"}
                >
                  <path
                    fill={this.props.bgColor ? this.props.bgColor : "#95A5A6"}
                    d="M18,0A18.1,18.1,0,0,0,0,18,18.1,18.1,0,0,0,18,36,18.1,18.1,0,0,0,36,18,18.1,18.1,0,0,0,18,0ZM6.261,27.391h0v2.035A18.25,18.25,0,0,1,2.5,23.478c1.252-.47,2.191-.939,1.565-2.191C1.252,16.122,3.287,13.3,6.261,13.3A3.251,3.251,0,0,1,9.7,16.9C9.7,23.009,6.261,19.722,6.261,27.391Zm21.13,4.07a16.315,16.315,0,0,1-18.783,0V26.452c0-2.035.157-3.13,2.5-3.6,2.661-.626,5.165-1.1,3.913-3.443-3.6-6.887-.939-10.8,2.974-10.8s6.574,3.757,2.974,10.643C19.722,21.6,22.226,22.07,24.887,22.7c2.348.47,2.5,1.722,2.5,3.6Zm2.348-2.035V27.391h0c0-7.67-3.443-4.383-3.443-10.487a3.328,3.328,0,0,1,3.443-3.6c2.974,0,5.009,2.817,2.191,7.983-.626,1.252.157,1.878,1.565,2.191A15.472,15.472,0,0,1,29.739,29.426Z"
                  ></path>
                </svg>
              )}
            </React.Fragment>
          )}
        {!this.props.isMultipleContacts &&
          !this.props.campaignType &&
          !this.props.isNote &&
          !this.props.isNotification &&
          !this.state.useGravatar &&
          !letters &&
          !this.props.isGroup && (
            <svg
              width={size}
              height={size}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 46 46"
            >
              <path
                fill={this.props.bgColor}
                d="M23,0A23,23,0,1,0,46,23,23.068,23.068,0,0,0,23,0Zm0,42.167A18.631,18.631,0,0,1,8.242,35.075c.575-1.15,1.533-1.917,3.642-2.3,4.217-.958,8.625-1.917,6.517-5.558C12.267,15.908,16.483,9.583,23,9.583c6.325,0,10.733,6.133,4.792,17.442-1.917,3.833,2.108,4.6,6.517,5.558a4.857,4.857,0,0,1,3.642,2.3A18.889,18.889,0,0,1,23,42.167Z"
              />
            </svg>
          )}
        {!this.props.isMultipleContacts &&
          !this.props.campaignType &&
          !this.props.isNote &&
          !this.props.isNotification &&
          !this.state.useGravatar &&
          letters && <span className="text-avatar">{letters}</span>}
        {!!this.props.isMultipleContacts && (
          <DuplicateNumberAvatar
            width={size}
            height={size}
            className={this.props.iconBlack ? "icon-black" : "icon-madc"}
          />
        )}
        {this.props.campaignType === "am" && (
          <AmSvg width={size} height={size} className={"icon-blue"} />
        )}
        {this.props.campaignType === "contests" && (
          <ContestSvg width={size} height={size} className={"icon-blue"} />
        )}
        {this.props.campaignType === "poll" && (
          <PollSvg width={size} height={size} className={"icon-blue"} />
        )}
        {this.props.campaignType === "dc" && (
          <DcSvg width={size} height={size} className={"icon-blue"} />
        )}
        {this.props.campaignType === "birthday" && (
          <BirthdaySvg width={size} height={size} className={"icon-blue"} />
        )}
        {!this.props.campaignType && this.props.isNote === 1 && (
          <AmUserSvg
            width={size * 0.7}
            height={size * 0.7}
            className={"icon-white"}
          />
        )}
        {!!this.props.isNotification && (
          <i className="material-icons">notifications</i>
        )}
      </React.Fragment>
    );

    const url = this.props.url ? this.props.url : "#void";
    const doNothing = this.props.url
      ? null
      : (e) => {
          e.preventDefault();
          e.stopPropagation();
        };

    const AvatarTag = this.props.tag ? this.props.tag : "div";

    if (AvatarTag === "a" && url !== "#void") {
      if (url.substr(0, 1) === "#") {
        return (
          <a href={url} className={className} style={styles}>
            {content}
          </a>
        );
      } else {
        return (
          <Link to={url} className={className} style={styles}>
            {content}
          </Link>
        );
      }
    } else {
      return (
        <AvatarTag
          className={className}
          style={styles}
          href={url}
          onClick={doNothing}
        >
          {content}
        </AvatarTag>
      );
    }
  }
}
