import React from "react";
import { connect } from "react-redux";
import { history } from "../../store";
import { ModalsContext } from "../../ModalsContext";
import { formatPhoneNumber } from "../../helpers";
import ThreadItem from "../hub/ThreadItem";
import ThreadItemNewContact from "../hub/ThreadItemNewContact";

import { searchReceivers, clearSearchReceivers } from "../../actions/threads";
import { addQuickContact } from "../../actions/contacts";

import { ReactComponent as CloseSvg } from "../../assets/img/icons/close.svg";
import { ReactComponent as SearchSvg } from "../../assets/img/icons/search-icon.svg";
import { ReactComponent as ClearSvg } from "../../assets/img/icons/clear_icon.svg";

function mapStateToProps(store) {
  return {
    companyId: store.companies.currentCompany.id,
    receivers: store.threads.receivers,
    status: store.threads.receiversStatus,
    phoneCountryCode: store.threads.receiversPhoneCountryCode,
    query: store.threads.receiversQuery,
    addQuickContactStatus: store.contacts.addQuickStatus,
    addQuickContactId: store.contacts.addQuickId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchReceivers: (
      companyId,
      query,
      phoneCountryId,
      onlyContacts,
      noLogs,
      noGroupDuplicates
    ) =>
      dispatch(
        searchReceivers(
          companyId,
          query,
          phoneCountryId,
          onlyContacts,
          noLogs,
          noGroupDuplicates
        )
      ),
    clearSearchReceivers: () => dispatch(clearSearchReceivers()),
    addQuickContact: (companyId, contactData) =>
      dispatch(addQuickContact(companyId, contactData)),
  };
}

class NewCallDialpadModal extends React.Component {
  static contextType = ModalsContext;

  constructor(props) {
    super(props);

    this.updateView = this.updateView.bind(this);
    this.close = this.close.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.search = this.search.bind(this);
    this.queryChanged = this.queryChanged.bind(this);
    this.clickDigit = this.clickDigit.bind(this);
    this.handleDigit = this.handleDigit.bind(this);
    this.documentKeyDown = this.documentKeyDown.bind(this);
    this.call = this.call.bind(this);
    this.state = {
      isShown: false,
      isHide: false,
      query: "",
      countryCode: "",
      queryIsNumber: false,
      redirect: null,
      number: "",
    };
  }

  componentDidMount() {
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
    }
    const currentOpen = this.state.isShown && !this.state.isHide;
    const prevOpen = prevState.isShown && !prevState.isHide;
    if (currentOpen && !prevOpen) {
      document.addEventListener("keydown", this.documentKeyDown);
      setTimeout(this.focusInput, 100);
    } else if (!currentOpen && prevOpen) {
      document.removeEventListener("keydown", this.documentKeyDown);
    }

    if (currentOpen) {
      if (
        prevProps.addQuickContactStatus !== this.props.addQuickContactStatus &&
        this.props.addQuickContactStatus === "success"
      ) {
        history.push("#modal-call-contact/" + this.props.addQuickContactId);
      }
    }

    // on close
    if (!currentOpen && prevOpen) {
      this.props.clearSearchReceivers();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.documentKeyDown);
  }

  updateView() {
    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  close(e) {
    e.preventDefault();
    history.goBack();
  }

  handleQueryChange() {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(this.queryChanged, 500);
  }

  queryChanged() {
    if (this.state.number !== "") {
      this.search();
    }
  }

  search() {
    this.props.searchReceivers(
      this.props.companyId,
      this.state.number,
      null,
      0,
      0,
      1
    );
  }

  clickDigit(e) {
    e.stopPropagation();
    e.preventDefault();
    const digit = e.currentTarget.dataset.digit;
    this.handleDigit(digit);
  }

  documentKeyDown(e) {
    var char = typeof e.key !== "undefined" ? e.key : null;
    if (!char) {
      var charCode = typeof e.which === "number" ? e.which : e.keyCode;
      if (charCode === 8) {
        char = "Backspace";
      } else {
        char = String.fromCharCode(charCode);
      }
    }
    if (char === "Backspace") {
      this.handleDigit("__backspace");
    } else if (char.match(/[0-9*#]/)) {
      this.handleDigit(char);
    }
  }

  handleDigit(digit) {
    let { number } = this.state;
    if (digit === "__backspace") {
      number = number.substr(0, Math.max(number.length - 1, 0));
    } else if (digit !== "0" || number.length > 0) {
      number += digit;
    }
    this.setState({ number: number }, this.handleQueryChange);
  }

  call(e) {
    e.stopPropagation();
    e.preventDefault();
    if (
      this.props.receivers &&
      this.props.receivers.length &&
      this.props.receivers[0].contacts_ids
    ) {
      history.push(
        "#modal-call-contact/" + this.props.receivers[0].contacts_ids[0]
      );
    } else {
      this.props.addQuickContact(this.props.companyId, {
        phone_number: this.state.number,
        country_id: 226,
      });
    }
  }

  render() {
    const newContact =
      this.state.number &&
      this.state.number !== "" &&
      (!this.props.receivers || this.props.receivers.length === 0);
    return (
      <div
        className={
          "modal fade new-call-with-number" +
          (this.state.isShown && !this.state.isHide ? " show" : "")
        }
        style={
          this.props.show || this.state.isShown ? { display: "block" } : {}
        }
        id="NewCallDialpad"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="NewCallDialpadLabel"
        aria-hidden={this.props.show || this.state.isShown ? "false" : "true"}
      >
        <div className="modal-backdrop fade show" onClick={this.close} />
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
          style={{ zIndex: 100 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="NewCallDialpadLabel">
                New Call
              </h5>
              <div className="close-icon" onClick={this.close}>
                <CloseSvg width={14} />
              </div>
            </div>
            <div className="modal-body">
              <div style={{ display: "flex", alignItems: "center" }}>
                {!newContact &&
                  this.state.number &&
                  this.props.receivers &&
                  this.props.receivers.length > 0 && (
                    <ThreadItem
                      key={this.props.receivers[0].item_key}
                      {...this.props.receivers[0]}
                      className={"show-contact"}
                      style={{
                        flexGrow: 1,
                        maxWidth: "92%",
                        whiteSpace: "nowrap",
                      }}
                      purpose={"new-call-modal"}
                      withoutLog
                    />
                  )}
                {newContact && (
                  <ThreadItemNewContact
                    phoneNumber={this.state.number}
                    countryId={null}
                    countryCode={null}
                    className={"show-contact"}
                    style={{ flexGrow: 1 }}
                    purpose={"new-call-modal"}
                    withoutLog
                  />
                )}
                {!newContact &&
                  !(
                    this.state.number &&
                    this.props.receivers &&
                    this.props.receivers.length > 0
                  ) && (
                    <p style={{ flexGrow: 1, margin: 0 }}>
                      Use dial pad to make a call.
                    </p>
                  )}
                <span
                  className="search"
                  style={{ marginLeft: "10px" }}
                  onClick={() => history.replace("#modal-new-call")}
                >
                  <SearchSvg width={18} />
                </span>
              </div>
              <div className="entered-number">
                <span>{formatPhoneNumber(this.state.number, "", true)}</span>
              </div>
              <div className="row dial-pad justify-content-center">
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={1}
                  >
                    <span className="number">1</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={2}
                  >
                    <span className="number">2</span>
                    <span className="alphabet">abc</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={3}
                  >
                    <span className="number">3</span>
                    <span className="alphabet">def</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={4}
                  >
                    <span className="number">4</span>
                    <span className="alphabet">ghi</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={5}
                  >
                    <span className="number">5</span>
                    <span className="alphabet">jkl</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={6}
                  >
                    <span className="number">6</span>
                    <span className="alphabet">mno</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={7}
                  >
                    <span className="number">7</span>
                    <span className="alphabet">pqrs</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={8}
                  >
                    <span className="number">8</span>
                    <span className="alphabet">tuv</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={9}
                  >
                    <span className="number">9</span>
                    <span className="alphabet">wxyz</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={"*"}
                  >
                    <span
                      style={{ fontSize: "42px", marginTop: "13px" }}
                      className="number"
                    >
                      *
                    </span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={"0"}
                  >
                    <span className="number">0</span>
                    <span className="alphabet">+</span>
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="#void"
                    className="single"
                    onClick={this.clickDigit}
                    data-digit={"#"}
                  >
                    <span className="number">#</span>
                  </a>
                </div>
                <div className="col-4"></div>
                <div className="col-4">
                  <a href="#void" className="dial" onClick={this.call}>
                    <i className="fa fa-phone"></i>
                  </a>
                </div>
                <div className="col-4">
                  {this.state.number && this.state.number.length && (
                    <a
                      href="#void"
                      className="clear"
                      onClick={this.clickDigit}
                      data-digit={"__backspace"}
                    >
                      <ClearSvg width={32.396} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCallDialpadModal);
