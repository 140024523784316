/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import main from "./main";
import users from "./users";
import settings from "./settings";
import companies from "./companies";
import countries from "./countries";
import numbers from "./numbers";
import threads from "./threads";
import messages from "./messages";
import contacts from "./contacts";
import calls from "./calls";
import twilio from "./twilio";
import groups from "./groups";
import messageTemplates from "./messageTemplates";
import audioUploads from "./audioUploads";
import audioRecordings from "./audioRecordings";
import broadcasts from "./broadcasts";
import shortLinks from "./shortLinks";
import notifications from "./notifications";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    main,
    users,
    settings,
    companies,
    countries,
    numbers,
    threads,
    messages,
    contacts,
    calls,
    twilio,
    groups,
    messageTemplates,
    audioUploads,
    audioRecordings,
    broadcasts,
    shortLinks,
    notifications,
  });
