/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { getPrimaryUrl } from "../helpers";
import { logoutAuth } from "../utils/auth";

export function v1Login() {
  return function (dispatch) {
    dispatch({ type: "USERS/V1_LOGIN" });

    const url = getPrimaryUrl("users/v2Login");
    return axios
      .get(url, AxiosConfig.getConfig())
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.jwt_token);
        dispatch({
          type: "USERS/V1_LOGIN.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USERS/V1_LOGIN.ERROR",
          payload: error,
        });
      });
  };
}

export function logout() {
  return function (dispatch) {
    logoutAuth();
    return dispatch({ type: "USER_LOGOUT" });
  };
}

export function fetchUserData() {
  return function (dispatch) {
    dispatch({ type: "FETCH_USER_DATA" });

    const url = "users/view.json";
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_USER_DATA_SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_USER_DATA_ERROR",
          payload: error,
        });
      });
  };
}

export function fetchCredits(companyId) {
  return function (dispatch) {
    dispatch({ type: "FETCH_CREDITS" });

    const url = "users/credits.json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_CREDITS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_CREDITS_ERROR",
          payload: error,
        });
      });
  };
}

export function clearSkipStep5() {
  return function (dispatch) {
    dispatch({ type: "USERS/CLEAR_SKIP_STEP5" });

    const url = "users/clear-skip-step5.json";
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        {},
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "USERS/CLEAR_SKIP_STEP5.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USERS/CLEAR_SKIP_STEP5.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}
