import React from "react";
import { getPrimaryUrl } from "../helpers";

class AccountIssueOverlay extends React.PureComponent {
  render() {
    const {
      isBillingIssue,
      isSuspended,
      billingPackage,
      numberIsDeleted,
      topBarsHeight,
      showLoading,
    } = this.props;
    if (isBillingIssue === 3 && isSuspended === 2 && billingPackage <= 35) {
      return (
        <div className="alert-overlay" style={{ top: `${topBarsHeight}px` }}>
          <div className="message">
            Your trial has ended. Within 48 hours, you will lose access to your
            phone number(s) and messaging credits. Your number(s) will be
            released soon.
            <br />
            Use the invite code <strong>GRACE</strong> when choosing a plan and
            pay only $1 for 14 more days. This offer expires in 48 hrs.
          </div>
          <div className="buttons">
            <a
              href={getPrimaryUrl("users/billing")}
              className="btn"
              onClick={showLoading}
            >
              Pick a plan
            </a>
            &nbsp;
            <a href="#void" className="btn outline btn-intercom-launcher">
              Support
            </a>
          </div>
        </div>
      );
    } else if (isBillingIssue === 3 && isSuspended === 2) {
      return (
        <div className="alert-overlay" style={{ top: `${topBarsHeight}px` }}>
          <div className="message">
            Your account is now downgraded and we are releasing your number(s)
            and messaging credits in 48 hours. Renew now. Coupon{" "}
            <strong>ACT</strong> (500 bonus credits).
          </div>
          <div className="buttons">
            <a
              href={getPrimaryUrl("users/payBilling")}
              className="btn"
              onClick={showLoading}
            >
              Pay invoice
            </a>
            &nbsp;
            <a href="#void" className="btn outline btn-intercom-launcher">
              Support
            </a>
          </div>
        </div>
      );
    } else if (
      isBillingIssue === 4 &&
      isSuspended === 2 &&
      numberIsDeleted &&
      billingPackage <= 35
    ) {
      return (
        <div className="alert-overlay" style={{ top: `${topBarsHeight}px` }}>
          <div className="message">
            Your account is now closed. Your number(s) and messaging credits
            were deleted.
            <br />
            Use the invite code <strong>GRACE</strong> when choosing a plan and
            pay only $1 for 14 more days.
          </div>
          <div className="buttons">
            <a
              href={getPrimaryUrl("users/billing")}
              className="btn"
              onClick={showLoading}
            >
              Pick a plan
            </a>
            &nbsp;
            <a href="#void" className="btn outline btn-intercom-launcher">
              Support
            </a>
          </div>
        </div>
      );
    } else if (isBillingIssue === 4 && isSuspended === 2 && numberIsDeleted) {
      return (
        <div className="alert-overlay" style={{ top: `${topBarsHeight}px` }}>
          <div className="message">
            After 3 auto-charge attempts your account is now closed, your
            number(s) released and your messaging credits deleted. To continue
            using this account, pick a new plan.
          </div>
          <div className="buttons">
            <a
              href={getPrimaryUrl("users/billing")}
              className="btn"
              onClick={showLoading}
            >
              Pick a plan
            </a>
            &nbsp;
            <a href="#void" className="btn outline btn-intercom-launcher">
              Support
            </a>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default AccountIssueOverlay;
