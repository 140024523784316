import { cookies } from "../store";

const initialState = {
  currentCompany: null,
  currentCompanyStatus: null,
  isCurrentAutoSwitched: null,
  companies: [],
  companiesStatus: null,
  companiesError: null,
  accountIssueBarCloseState: null,
  users: null,
  usersStatus: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "FETCH_COMPANIES":
      return {
        ...state,
        companiesStatus: "loading",
        companiesError: null,
      };
    case "FETCH_COMPANIES_SUCCESS":
      return {
        ...state,
        companiesStatus: "success",
        companiesError: null,
        companies: action.payload.companies,
      };
    case "FETCH_COMPANIES_ERROR":
      return {
        ...state,
        companiesStatus: "error",
        companiesError: action.payload,
        companies: [],
      };

    case "SET_CURRENT_COMPANY":
      if (state.companies) {
        for (let i = 0; i < state.companies.length; i++) {
          if (state.companies[i].id === action.payload.companyId) {
            return {
              ...state,
              currentCompany: state.companies[i],
              currentCompanyStatus: "success",
              isCurrentAutoSwitched: action.payload.isAutoSwitched,
              accountIssueBarCloseState: cookies.get(
                "account_issue_closed__" + state.companies[i].id
              ),
              users: null,
              usersStatus: null,
            };
          }
        }
      }
      return {
        ...state,
        currentCompany: null,
        currentCompanyStatus: "error",
        isCurrentAutoSwitched: null,
        accountIssueBarCloseState: null,
        users: null,
        usersStatus: null,
      };

    case "COMPANIES/CLEAR_AUTO_SWITCHED_FLAG":
      return {
        ...state,
        isCurrentAutoSwitched: false,
      };

    case "COMPANIES/CLOSE_ACCOUNT_ISSUE_BAR":
      return {
        ...state,
        accountIssueBarCloseState: state.currentCompany
          ? cookies.get("account_issue_closed__" + state.currentCompany.id)
          : null,
      };

    case "COMPANIES/FETCH_USERS":
      return {
        ...state,
        usersStatus: "loading",
      };
    case "COMPANIES/FETCH_USERS.SUCCESS":
      return {
        ...state,
        users: action.payload.userCompanies,
        usersStatus: "success",
      };
    case "COMPANIES/FETCH_USERS.ERROR":
      return {
        ...state,
        users: [],
        usersStatus: "error",
      };

    default:
      return state;
  }
}
