import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import Cookies from "universal-cookie";

export function fetchNumbers(companyId) {
  return function (dispatch) {
    dispatch({ type: "NUMBERS/NUMBERS" });

    const url = "numbers.json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "NUMBERS/NUMBERS.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "NUMBERS/NUMBERS.ERROR",
          payload: error,
        });
      });
  };
}

export function setNumbersFilter(companyId, numbers, all, save = true) {
  return function (dispatch) {
    let value = all ? "all" : "no-all";
    if (!all) {
      value += "," + numbers.join(",");
    }
    dispatch({
      type: "NUMBERS/FILTER",
      numbers,
      value,
      all,
    });
    if (save) {
      const data = {
        company_id: companyId,
        numbers,
        all: all ? 1 : 0,
      };
      return axios.post(
        AxiosConfig.getEndpointAddress() + "numbers/saveFilter.json",
        data,
        AxiosConfig.getAuthConfig()
      );
    }
  };
}

export function setSenderNumber(number) {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SENDER",
      number: number,
    });
    const cookies = new Cookies();
    let expires = new Date();
    expires.setDate(expires.getDate() + 365);
    cookies.set("sender_number", number, { path: "/", expires: expires });
  };
}

export function setUseSecondaryNumber(state) {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SET_USE_SECONDARY_NUMBER",
      useSecondaryNumber: state,
    });
  };
}

export function setShortCodeSignature(userId, userNumber, signaturePreview) {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SHORT_CODE_SIGNATURE",
      userId,
      userNumber,
      signaturePreview,
    });
  };
}
