import React, { useState } from "react";
import { history } from "../store";
import ThreadItem from "./hub/ThreadItem";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { ReactComponent as MessageSingleSvg } from "../assets/img/icons/message_single.svg";
import { ReactComponent as MessageSingleFilledSvg } from "../assets/img/icons/message-single-filled.svg";
import { tooltipProps } from "../helpers";

const HeaderUnreadDropdown = (props) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    if (props.unreadsStatus === null) {
      props.load();
    }
    setOpen(!isOpen);
  };

  const showUnread = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    history.push("/hub/messages");
    props.showUnread();
    setOpen(false);
  };

  if (props.unreadsTotal <= 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Dropdown
        tag="li"
        isOpen={isOpen}
        toggle={toggle}
        className="nav-item d-none d-sm-block"
      >
        <DropdownToggle
          tag="a"
          style={{ cursor: "pointer" }}
          className={"nav-link dropdown-toggle toggle-icon"}
        >
          <span {...tooltipProps} title="New Messages">
            <MessageSingleSvg width={22} />
            <MessageSingleFilledSvg width={22} />
          </span>
          <span
            className="badge badge-pill primary-badge pull-right"
            style={props.unreadsTotal > 99 ? { fontSize: "8px" } : {}}
          >
            {props.unreadsTotal > 99 ? "99+" : props.unreadsTotal}
          </span>
        </DropdownToggle>
        <DropdownMenu right className={"notifications"}>
          <div className="topnav-dropdown-header">
            <span>
              {props.unreadsTotal} Unread{" "}
              {props.unreadsTotal > 1 ? "Threads" : "Thread"}
            </span>
          </div>
          {props.unreadsStatus === "loading" && (
            <p className="text-muted text-center py-3">Loading...</p>
          )}
          {props.unreadsStatus === "success" && (
            <ul className="notification-list">
              {props.unreads &&
                props.unreads.map((thread) => (
                  <ThreadItem key={thread.item_key} {...thread} />
                ))}
            </ul>
          )}
          <div className="topnav-dropdown-footer">
            <a href="#void" onClick={showUnread}>
              View All Unread
            </a>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default HeaderUnreadDropdown;
