import React from "react";
import {
  getFormattedAccountCredits,
  isCordovaApp,
  getPrimaryUrl,
  getV3Url,
} from "../helpers";
import Avatar from "./Avatar";
import NewVersionLink from "./hub/NewVersionLink";

const HeaderAccountDropdown = (props) => {
  const { credits, loggedUser, currentCompany, showLoading } = props;
  if (!loggedUser) {
    return null;
  }

  let creditsSimple = "Loading...";
  let creditsFull = "Loading...";
  if (credits !== null) {
    ({ creditsSimple, creditsFull } = getFormattedAccountCredits(credits));
  }

  const isCordova = isCordovaApp();

  const hasBillingPermissions =
    currentCompany &&
    (currentCompany.is_main_owner ||
      currentCompany.is_admin_plus ||
      currentCompany.is_manage_cc);

  return (
    <li className="nav-item dropdown has-arrow user-switch">
      <a
        href="#void"
        className="dropdown-toggle nav-link user-link"
        data-toggle="dropdown"
      >
        <Avatar
          headerMode
          firstName={loggedUser.first_name}
          lastName={loggedUser.last_name}
          email={loggedUser.email}
        />
        <span>
          {(loggedUser.first_name + " " + loggedUser.last_name).trim()}
        </span>
      </a>
      <div className="dropdown-menu">
        {currentCompany && (
          <a className="dropdown-item" href="#void">
            {currentCompany.company_name}
            <svg
              className="ml-2"
              xmlns="http://www.w3.org/2000/svg"
              width="9.559"
              height="12.714"
              viewBox="0 0 9.559 12.714"
            >
              <path
                id="Path_2106"
                data-name="Path 2106"
                d="M4.779,0A6,6,0,0,1,0,1.593v6.1c0,2.451,1.685,3.064,4.779,5.024,3.064-1.93,4.779-2.573,4.779-5.024v-6.1A5.935,5.935,0,0,1,4.779,0ZM8,7.69c0,1.134-.429,1.5-2.819,2.941-.123.092-.276.153-.429.245V1.93A6.4,6.4,0,0,0,8,3.064V7.69Z"
                fill="#8f8f8f"
              ></path>
            </svg>
          </a>
        )}
        <NewVersionLink
          doNotShowModal={true}
          className={"dropdown-item"}
          link={getV3Url("")}
        >
          <span className="credit-short">{creditsSimple}</span>
          <span className="credit-full">{creditsFull}</span>
        </NewVersionLink>
        {loggedUser.default_app_version === "legacy" && (
          <>
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item text-primary"
              href={getPrimaryUrl("inbox")}
              onClick={showLoading}
            >
              Inbox 1.0
            </a>
          </>
        )}
        <div className="dropdown-divider"></div>
        {!isCordova && (
          <>
            {currentCompany && currentCompany.role === 2 && (
              <React.Fragment>
                {(hasBillingPermissions ||
                  loggedUser.default_app_version !== "v2") && (
                  <NewVersionLink
                    className={"dropdown-item"}
                    link={getV3Url(
                      "hub/menus/filter/settings/admin-settings/submenu/admin-billing/billing"
                    )}
                    doNotShowModal={true}
                  >
                    Billing
                  </NewVersionLink>
                )}
                {!(
                  hasBillingPermissions ||
                  loggedUser.default_app_version !== "v2"
                ) && (
                  <a
                    className="dropdown-item"
                    href="#void"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      props.openBillingModal();
                    }}
                  >
                    Billing
                  </a>
                )}
                <NewVersionLink
                  className={"dropdown-item"}
                  link={getV3Url(
                    "hub/menus/filter/settings/admin-settings/submenu/admin-integrations/active-apps"
                  )}
                  doNotShowModal={true}
                >
                  Integrations
                </NewVersionLink>
              </React.Fragment>
            )}
            <NewVersionLink
              className={"dropdown-item"}
              link={getV3Url(
                "hub/menus/filter/settings/user-settings/submenu/user-profile"
              )}
              doNotShowModal={true}
            >
              My Profile
            </NewVersionLink>
            {currentCompany &&
              (currentCompany.is_main_owner ||
                currentCompany.is_admin_plus) && (
                <NewVersionLink
                  className={"dropdown-item"}
                  link={getV3Url(
                    "hub/menus/filter/settings/admin-settings/submenu/admin-signatures"
                  )}
                  doNotShowModal={true}
                >
                  Organization
                </NewVersionLink>
              )}
            {currentCompany && currentCompany.role === 2 && (
              <NewVersionLink
                link={getV3Url(
                  "hub/menus/filter/settings/admin-settings/submenu/admin-users/active-users"
                )}
                className={"dropdown-item"}
                doNotShowModal={true}
              >
                Users
              </NewVersionLink>
            )}
            <NewVersionLink
              link={getV3Url(
                "hub/menus/filter/settings/user-settings/submenu/user-notifications/all"
              )}
              className={"dropdown-item"}
              doNotShowModal={true}
            >
              Notifications
            </NewVersionLink>
            <NewVersionLink
              link={getV3Url(
                "hub/menus/filter/settings/user-settings/submenu/user-number"
              )}
              className={"dropdown-item"}
            >
              Numbers
            </NewVersionLink>
            {currentCompany &&
              currentCompany.is_allow_short_code === 1 &&
              hasBillingPermissions && (
                <NewVersionLink
                  link={getV3Url("hub/menus/filter/shortcode-keywords")}
                  className={"dropdown-item"}
                  doNotShowModal={true}
                >
                  Shortcode Keywords
                </NewVersionLink>
              )}
            <NewVersionLink
              link={getV3Url(
                "hub/menus/filter/settings/admin-settings/submenu/admin-security"
              )}
              className={"dropdown-item"}
              doNotShowModal={true}
            >
              Security
            </NewVersionLink>
            <div className="dropdown-divider"></div>
          </>
        )}
        <span
          className="dropdown-item"
          style={{ cursor: "pointer" }}
          onClick={props.logout}
        >
          Logout
        </span>
      </div>
    </li>
  );
};

export default HeaderAccountDropdown;
