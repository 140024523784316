import Pusher from "pusher-js";
import { fetchIncomingThread, fetchCounts } from "./actions/threads";
import { fetchCredits } from "./actions/users";

export default class PusherConfig {
  fetchCreditsTimeout = null;

  static _socket = null;
  static _channel = null;

  static app_id = process.env.REACT_APP_PUSHER_APP_ID;
  static key = process.env.REACT_APP_PUSHER_KEY;
  static cluster = process.env.REACT_APP_PUSHER_CLUSTER;

  static dispatch = null;
  static userId = null;
  static companyId = null;
  static numbersFilter = null;
  static ignoreOutgoingLogs = [];

  static init(companyId, userId, eventsCallback) {
    if (PusherConfig._socket) {
      PusherConfig._socket.disconnect();
    }

    PusherConfig.companyId = companyId;
    PusherConfig.userId = userId;

    PusherConfig._socket = new Pusher(PusherConfig.key, {
      cluster: PusherConfig.cluster,
    });
    PusherConfig._socket.connection.bind("error", function (err) {
      if (err.error.data.code === 4004) {
        console.log("Pusher connection failed - over limit");
      } else {
        console.log("Pusher connection failed");
      }
    });

    PusherConfig._channel = PusherConfig._socket.subscribe("channel-" + userId);
    PusherConfig._bindEvents(eventsCallback);
  }

  static setNumbersFilter(numberFilter) {
    PusherConfig.numbersFilter = numberFilter;
  }

  static _bindEvents(eventsCallback) {
    PusherConfig._channel.bind("new-message", (data) => {
      setTimeout(
        () =>
          eventsCallback([
            fetchIncomingThread(
              PusherConfig.companyId,
              PusherConfig.numbersFilter,
              data.id
            ),
            fetchCounts(PusherConfig.companyId, PusherConfig.numbersFilter),
          ]),
        5000
      );
    });

    PusherConfig._channel.bind("new-outgoing-message", (data) => {
      // Small delay as Pusher event is faster than our API response
      setTimeout(() => {
        const ignoreIndex = PusherConfig.ignoreOutgoingLogs.indexOf(data.id);
        if (ignoreIndex !== -1) {
          PusherConfig.ignoreOutgoingLogs.splice(ignoreIndex, 1);
        } else {
          eventsCallback([
            fetchIncomingThread(
              PusherConfig.companyId,
              PusherConfig.numbersFilter,
              data.id
            ),
            fetchCounts(PusherConfig.companyId, PusherConfig.numbersFilter),
          ]);
        }
      }, 500);
    });

    PusherConfig._channel.bind("reload-credit", () => {
      // Small delay as Pusher event is faster than our API response

      clearTimeout(this.fetchCreditsTimeout);

      this.fetchCreditsTimeout = setTimeout(
        () => eventsCallback([fetchCredits(PusherConfig.companyId)]),
        750
      );
    });
  }

  static addIgnoreLogId(logId) {
    PusherConfig.ignoreOutgoingLogs.push(logId);
  }
}
