import React, { Suspense, lazy } from "react";
import withResponsive from "../withResponsive";
import LayoutDefault from "../components/LayoutDefault";

const HubContent = lazy(() => import("../components/hub/HubContent"));

const HubPage = (props) => {
  return (
    <LayoutDefault className="inbox" isMobile={props.isMobile}>
      <Suspense fallback={<div>Loading...</div>}>
        <HubContent {...props} />
      </Suspense>
    </LayoutDefault>
  );
};

export default withResponsive(HubPage);
