export default class FcmConfig {
  static userId = null;
  static token = null;
  static primaryAppUrl = process.env.REACT_APP_PRIMARY_APP_URL.replace(
    /(http(|s):\/\/|\/$)/g,
    ""
  );

  static init(userId, token) {
    FcmConfig.userId = userId;
    FcmConfig.token = token; // this is the refresh token
  }

  // this is the refresh token
  static setToken(token) {
    FcmConfig.token = token;
  }

  static sendParentMessage(event) {
    if (window.parent && FcmConfig.userId) {
      const message =
        event === "user-logout"
          ? null
          : {
              event,
              token: FcmConfig.token,
              value: FcmConfig.userId,
              topic: `${FcmConfig.primaryAppUrl}-${FcmConfig.userId}`,
            };
      window.parent.postMessage(message, "*");
    }
  }
}
