import axios from "axios";
import AxiosConfig from "../AxiosConfig";

// We stores requestIds to not overwrite response from earlier request, from previous selected company
let fetchThreadsRequestIds = [];

export function resetFetchThreadsRequests() {
  fetchThreadsRequestIds = [];
}

function removeFetchThreadRequestId(requestId) {
  const index = fetchThreadsRequestIds.indexOf(requestId);
  if (index >= 0) {
    fetchThreadsRequestIds.splice(index, 1);
  }
}

export function fetchThreads(companyId, numbersFilter, page) {
  return function (dispatch) {
    const requestId = `${new Date().getTime()}-${String(Math.random()).substr(
      2,
      3
    )}`;
    fetchThreadsRequestIds.push(requestId);
    dispatch({ type: "FETCH_THREADS" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        page: page,
        numbersFilter: numbersFilter,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        if (fetchThreadsRequestIds.indexOf(requestId) >= 0) {
          removeFetchThreadRequestId(requestId);
          dispatch({
            type: "FETCH_THREADS_SUCCESS",
            payload: response.data,
            companyId: companyId,
            page: page,
          });
        }
      })
      .catch(function (error) {
        if (fetchThreadsRequestIds.indexOf(requestId) >= 0) {
          removeFetchThreadRequestId(requestId);
          dispatch({
            type: "FETCH_THREADS_ERROR",
            payload: error,
          });
        }
      });
  };
}

export function setThreadsAsOutdated() {
  return function (dispatch) {
    dispatch({ type: "THREADS/FETCH.OUTDATED" });
  };
}

export function fetchFilteredThreads(
  companyId,
  query,
  phoneCountryId,
  phoneCountryCode,
  numbersFilter,
  page
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/FILTERED",
      companyId,
      phoneCountryId,
      phoneCountryCode,
      query,
      page,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        phoneCountryId,
        phoneCountryCode,
        query,
        numbersFilter,
        page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/FILTERED.SUCCESS",
          payload: response.data,
          companyId,
          phoneCountryId,
          phoneCountryCode,
          query,
          page,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/FILTERED.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchSpecialThreads(companyId, numbersFilter, filter, page) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SPECIAL", page });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        page: page,
        numbersFilter: numbersFilter,
        filter: filter,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/SPECIAL.SUCCESS",
          payload: response.data,
          companyId: companyId,
          page: page,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/SPECIAL.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchIncomingThread(companyId, numbersFilter, logId) {
  return function (dispatch) {
    dispatch({ type: "FETCH_INCOMING_THREAD" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        logId: logId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "FETCH_INCOMING_THREAD_SUCCESS",
          payload: response.data,
          companyId: companyId,
          logId: logId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_INCOMING_THREAD_ERROR",
          payload: error,
        });
      });
  };
}

export function fetchThread(companyId, numbersFilter, contactId, groupId) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SINGLE" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        contactId: contactId,
        groupId: groupId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS/SINGLE.SUCCESS",
          payload: response.data,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/SINGLE.ERROR",
          payload: error,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      });
  };
}

export function fetchTempThread(companyId, numbersFilter, contactId, groupId) {
  return function (dispatch) {
    dispatch({ type: "THREADS/TEMP" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        contactId: contactId,
        groupId: groupId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS/TEMP.SUCCESS",
          payload: response.data,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/TEMP.ERROR",
          payload: error,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      });
  };
}

export function fetchVoiceThreads(companyId, numbersFilter, page) {
  return function (dispatch) {
    const requestId = `${new Date().getTime()}-${String(Math.random()).substr(
      2,
      3
    )}`;
    fetchThreadsRequestIds.push(requestId);
    dispatch({ type: "THREADS/VOICE_THREADS" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        page: page,
        numbersFilter: numbersFilter,
        type: "all_voice",
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        if (fetchThreadsRequestIds.indexOf(requestId) >= 0) {
          removeFetchThreadRequestId(requestId);
          dispatch({
            type: "THREADS/VOICE_THREADS.SUCCESS",
            payload: response.data,
            page: page,
          });
        }
      })
      .catch(function (error) {
        if (fetchThreadsRequestIds.indexOf(requestId) >= 0) {
          removeFetchThreadRequestId(requestId);
          dispatch({
            type: "THREADS/VOICE_THREADS.ERROR",
            payload: error,
          });
        }
      });
  };
}

export function setVoiceThreadsAsOutdated() {
  return function (dispatch) {
    dispatch({ type: "THREADS/VOICE_THREADS.OUTDATED" });
  };
}

export function fetchTempVoiceThread(
  companyId,
  numbersFilter,
  contactId,
  groupId
) {
  return function (dispatch) {
    dispatch({ type: "THREADS/TEMP_VOICE" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        contactId: contactId,
        groupId: groupId,
        type: "all_voice",
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS/TEMP_VOICE.SUCCESS",
          payload: response.data,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/TEMP_VOICE.ERROR",
          payload: error,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      });
  };
}

export function fetchVoiceThread(companyId, numbersFilter, contactId, groupId) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SINGLE_VOICE" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        contactId: contactId,
        groupId: groupId,
        type: "all_voice",
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS/SINGLE_VOICE.SUCCESS",
          payload: response.data,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/SINGLE_VOICE.ERROR",
          payload: error,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      });
  };
}

export function fetchFilteredVoiceThreads(
  companyId,
  query,
  phoneCountryId,
  phoneCountryCode,
  numbersFilter,
  filter,
  page
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/VOICE_FILTERED",
      companyId,
      phoneCountryId,
      phoneCountryCode,
      query,
      page,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        phoneCountryId,
        phoneCountryCode,
        query,
        numbersFilter,
        type: "all_voice",
        filter,
        page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/VOICE_FILTERED.SUCCESS",
          payload: response.data,
          companyId,
          phoneCountryId,
          phoneCountryCode,
          query,
          page,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/VOICE_FILTERED.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchThreadItems(
  companyId,
  threadType,
  interlocutorId,
  fromExcId,
  toExcId,
  numbersFilter,
  threadItemKey
) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_THREAD_ITEMS",
      companyId,
      threadType,
      interlocutorId,
      fromExcId,
      toExcId,
      threadItemKey,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        numbersFilter,
        threadType,
        interlocutorId,
        fromExcId,
        toExcId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/items.json", config)
      .then((response) => {
        dispatch({
          type: "FETCH_THREAD_ITEMS_SUCCESS",
          payload: response.data,
          companyId,
          threadType,
          interlocutorId,
          fromExcId,
          toExcId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_THREAD_ITEMS_ERROR",
          payload: error,
          companyId,
          threadType,
          interlocutorId,
          fromExcId,
          toExcId,
        });
      });
  };
}

export function fetchVoiceThreadItems(
  companyId,
  threadType,
  interlocutorId,
  fromExcId,
  toExcId,
  numbersFilter,
  threadItemKey
) {
  return function (dispatch) {
    dispatch({
      type: "THREAD/VOICE_ITEMS",
      companyId: companyId,
      threadType: threadType,
      interlocutorId: interlocutorId,
      fromExcId,
      toExcId,
      threadItemKey: threadItemKey,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        threadType: threadType,
        interlocutorId: interlocutorId,
        fromExcId,
        toExcId,
        type: "all_voice",
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/items.json", config)
      .then((response) => {
        dispatch({
          type: "THREAD/VOICE_ITEMS.SUCCESS",
          payload: response.data,
          companyId: companyId,
          threadType: threadType,
          interlocutorId: interlocutorId,
          fromExcId,
          toExcId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREAD/VOICE_ITEMS.ERROR",
          payload: error,
          companyId: companyId,
          threadType: threadType,
          interlocutorId: interlocutorId,
          fromExcId,
          toExcId,
        });
      });
  };
}

export function resetNewItemsCount() {
  return function (dispatch) {
    dispatch({
      type: "THREADS_RESET_NEW_ITEMS_COUNT",
    });
  };
}

export function searchReceivers(
  companyId,
  query,
  phoneCountryId,
  onlyContacts = 0,
  noLogs = 0,
  noGroupDuplicates = 0
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS_SEARCH_RECEIVERS",
      companyId,
      query,
      phoneCountryId,
    });

    const url = "threads/search-receivers.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        phoneCountryId,
        query,
        onlyContacts,
        noLogs,
        noGroupDuplicates,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS_SEARCH_RECEIVERS_SUCCESS",
          payload: response.data,
          companyId,
          query,
          phoneCountryId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS_SEARCH_RECEIVERS_ERROR",
          payload: error,
          companyId,
          query,
          phoneCountryId,
        });
      });
  };
}

export function clearSearchReceivers() {
  return function (dispatch) {
    dispatch({ type: "THREADS/SEARCH_RECEIVERS.CLEAR" });
  };
}

export function fetchUnread(companyId, numbersFilter, limit, page) {
  limit = typeof limit === "undefined" ? 5 : limit;
  return function (dispatch) {
    dispatch({ type: "THREADS/UNREAD" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        numbersFilter,
        limit,
        page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/unread.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/UNREAD.SUCCESS",
          payload: response.data,
          companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/UNREAD.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchUndelivered(companyId, numbersFilter, limit) {
  limit = typeof limit === "undefined" ? 5 : limit;
  return function (dispatch) {
    dispatch({ type: "THREADS/UNDELIVERED" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        limit: limit,
      },
    });
    return axios
      .get(
        AxiosConfig.getEndpointAddress() + "threads/undelivered.json",
        config
      )
      .then((response) => {
        dispatch({
          type: "THREADS/UNDELIVERED.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/UNDELIVERED.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchCounts(companyId, numbersFilterValue, fields = []) {
  return function (dispatch) {
    dispatch({ type: "THREADS/COUNTS" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilterValue: numbersFilterValue,
        fields,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/counts.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/COUNTS.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/COUNTS.ERROR",
          payload: error,
        });
      });
  };
}

export function markAsRead(
  companyId,
  contactId,
  toLogId,
  logId,
  numbersFilter
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/MARK_AS_READ",
      logId: logId,
    });

    const data = {
      companyId: companyId,
      contactId: contactId,
      toLogId: toLogId,
      logId: logId,
      numbersFilter: numbersFilter,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "threads/mark-as-read.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_AS_READ.SUCCESS",
          payload: response.data,
          companyId: companyId,
          contactId: contactId,
          toLogId: toLogId,
          logId: logId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_AS_READ.ERROR",
          payload: error,
        });
      });
  };
}

export function markAllAsRead(companyId, numbersFilter) {
  return function (dispatch) {
    dispatch({ type: "THREADS/MARK_ALL_AS_READ" });

    const data = {
      companyId: companyId,
      numbersFilter: numbersFilter,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "threads/mark-all-as-read.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_ALL_AS_READ.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_ALL_AS_READ.ERROR",
          payload: error,
        });
      });
  };
}

export function markAsUnread(companyId, contactId, logId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/MARK_AS_UNREAD",
      logId: logId,
    });

    const data = {
      companyId: companyId,
      contactId: contactId,
      logId: logId,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "threads/mark-as-unread.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_AS_UNREAD.SUCCESS",
          payload: response.data,
          companyId: companyId,
          contactId: contactId,
          logId: logId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_AS_UNREAD.ERROR",
          payload: error,
        });
      });
  };
}

export function markAllUndeliveredAsRead(companyId, numbersFilter) {
  return function (dispatch) {
    dispatch({ type: "THREADS/MARK_ALL_UNDELIVERED_AS_READ" });

    const data = {
      companyId: companyId,
      numbersFilter: numbersFilter,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          "threads/mark-all-undelivered-as-read.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_ALL_UNDELIVERED_AS_READ.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_ALL_UNDELIVERED_AS_READ.ERROR",
          payload: error,
        });
      });
  };
}

export function setFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/FILTER",
      filter: filter,
    });
  };
}

export function setVoiceFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/VOICE_FILTER",
      filter: filter,
    });
  };
}

/**
 * Method for reload items status in Redux store
 *
 * @param companyId
 * @param scheduledMessageIds
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function updateItemsStatus(companyId, scheduledMessageIds) {
  return function (dispatch) {
    dispatch({ type: "THREADS/UPDATE_ITEMS_STATUS" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        scheduledMessageIds: scheduledMessageIds,
      },
    });
    return axios
      .get(
        AxiosConfig.getEndpointAddress() + "threads/items-status.json",
        config
      )
      .then((response) => {
        dispatch({
          type: "THREADS/UPDATE_ITEMS_STATUS.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/UPDATE_ITEMS_STATUS.ERROR",
          payload: error,
        });
      });
  };
}

export function reloadItem(companyId, groupSmsBlastId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/RELOAD_ITEM",
      groupSmsBlastId,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        groupSmsBlastId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + `threads/item.json`, config)
      .then((response) => {
        dispatch({
          type: "THREADS/RELOAD_ITEM.SUCCESS",
          payload: response.data,
          companyId,
          groupSmsBlastId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/RELOAD_ITEM.ERROR",
          payload: error,
        });
      });
  };
}

export function refreshGroupMsgStats(companyId, groupSmsBlastId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/REFRESH_GROUP_MSG_STATS",
      groupSmsBlastId,
    });

    const data = {
      companyId,
      groupSmsBlastId,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          `threads/refresh-group-msg-stats.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/REFRESH_GROUP_MSG_STATS.SUCCESS",
          payload: response.data,
          companyId,
          groupSmsBlastId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/REFRESH_GROUP_MSG_STATS.ERROR",
          payload: error,
        });
      });
  };
}

export function setScheduledVisible(visible) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/SCHEDULED_VISIBLE",
      visible,
    });
  };
}

export function clearItems(threadType, interlocutorId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/CLEAR_ITEMS",
      threadType,
      interlocutorId,
    });
  };
}
