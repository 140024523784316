import React from "react";
import { store } from "./store";

import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import AxiosConfig from "./AxiosConfig";
import { breakpoints } from "./utils/breakpoints";

export const tooltipProps = {
  // Props for Tooltip from Reacstrap
  delay: { show: 500, hide: 100 },
  placement: "bottom",
  trigger: "hover",

  // Props for direct Bootstrap/jQuery tooltip
  "data-toggle": "tooltip",
  "data-placement": "bottom",
  "data-delay": '{ "show": 500, "hide": 100 }',
  "data-trigger": "hover",
};

export const tooltipPropsTop = {
  ...tooltipProps,
  placement: "top",
  "data-placement": "top",
};

export const tooltipPropsAuto = {
  ...tooltipProps,
  placement: "auto",
  "data-placement": "auto",
};

export const timeAgo = (time, timezoneOffset) => {
  const date = new Date(time);
  const diff = (new Date().getTime() - date.getTime()) / 1000;
  if (diff < -86400) {
    let exDate = new Date(time);
    exDate.setTime(exDate.getTime() + timezoneOffset * 1000);
    let text = "";
    if (diff < -432000) {
      text =
        ("0" + exDate.getUTCDate()).substr(-2) +
        "." +
        ("0" + (exDate.getUTCMonth() + 1)).substr(-2);
    } else {
      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      text = weekdays[exDate.getUTCDay()];
    }
    text +=
      ", " +
      ("0" + exDate.getUTCHours()).substr(-2) +
      ":" +
      ("0" + exDate.getUTCMinutes()).substr(-2);
    return text;
  } else if (diff < -3600) {
    const hours = Math.abs(Math.ceil(diff / 3600 - 0.5));
    return "In " + hours + (hours > 1 ? " hrs" : " hr");
  } else if (diff < 0) {
    return "In " + Math.abs(Math.ceil(diff / 60 - 0.5)) + " min";
  } else if (diff < 10) {
    return "few seconds ago";
  } else if (diff < 60) {
    return Math.floor(diff) + " sec";
  } else if (diff < 3600) {
    return Math.floor(diff / 60) + " min";
  } else if (diff < 86400) {
    return Math.floor(diff / 3600) + " hr";
  }
  return Math.floor(diff / 86400) + " d";
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. August 16th, 2018 | 01:40 PM
 */
export const formatDateTime = (time, timezoneOffset) => {
  let date = new Date(time);
  date.setTime(date.getTime() + timezoneOffset * 1000);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfMonth = date.getUTCDate();
  let text = monthNames[date.getUTCMonth()] + " " + dayOfMonth;
  // Assumption Days do not go beyond 31
  // or else we would have to check for numbers like 111 or 211
  if (dayOfMonth >= 11 && dayOfMonth <= 19) {
    text += "th";
  } else {
    let dayString = dayOfMonth.toString();
    let lastNumber = Number(dayString.charAt(dayString.length - 1));

    if (lastNumber === 1) {
      text += "st";
    } else if (lastNumber === 2) {
      text += "nd";
    } else if (lastNumber === 3) {
      text += "rd";
    } else {
      text += "th";
    }
  }
  text += ", " + date.getUTCFullYear() + " | ";
  const hours = date.getUTCHours();
  let showHours = hours;
  if (showHours === 0) {
    showHours = 12;
  } else if (showHours > 12) {
    showHours -= 12;
  }
  const ampm = hours >= 12 ? "PM" : "AM";
  text +=
    ("0" + showHours).substr(-2) +
    ":" +
    ("0" + date.getUTCMinutes()).substr(-2) +
    " " +
    ampm;

  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. Thursday, August 5th or just Thursday (if last 6 days)
 */
export const formatDateTime2 = (time, timezoneOffset) => {
  if (typeof time === "undefined" || time === null) {
    return null;
  }
  let date = new Date(time);
  date.setTime(date.getTime() + timezoneOffset * 1000);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // If date is from last 6 days, return just week day
  const currentDate = new Date();
  currentDate.setUTCHours(date.getUTCHours());
  currentDate.setUTCMinutes(date.getUTCMinutes());
  currentDate.setUTCSeconds(date.getUTCSeconds());
  currentDate.setUTCMilliseconds(date.getUTCMilliseconds());
  if ((currentDate.getTime() - date.getTime()) / 86400000 < 7) {
    return weekDays[date.getUTCDay()];
  }

  const dayOfMonth = date.getUTCDate();
  let text =
    weekDays[date.getUTCDay()] +
    ", " +
    monthNames[date.getUTCMonth()] +
    " " +
    dayOfMonth;
  // Assumption Days do not go beyond 31
  // or else we would have to check for numbers like 111 or 211
  if (dayOfMonth >= 11 && dayOfMonth <= 19) {
    text += "th";
  } else {
    let dayString = dayOfMonth.toString();
    let lastNumber = Number(dayString.charAt(dayString.length - 1));

    if (lastNumber === 1) {
      text += "st";
    } else if (lastNumber === 2) {
      text += "nd";
    } else if (lastNumber === 3) {
      text += "rd";
    } else {
      text += "th";
    }
  }

  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. August 16, 2018
 */
export const formatDateTime3 = (time, timezoneOffset) => {
  let date = new Date(time);
  date.setTime(date.getTime() + timezoneOffset * 1000);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfMonth = date.getUTCDate();
  let text = monthNames[date.getUTCMonth()] + " " + dayOfMonth;
  text += ", " + date.getUTCFullYear();

  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. August 20th, 2018 | 1.55 AM
 */
export const formatDateTime4 = (time, timezoneOffset) => {
  let date = new Date(time);
  if (timezoneOffset) {
    date.setTime(date.getTime() + timezoneOffset * 1000);
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfMonth = date.getUTCDate();
  let text =
    monthNames[date.getUTCMonth()] +
    " " +
    dayOfMonth +
    englishOrdinalSuffix(dayOfMonth);
  text += ", " + date.getUTCFullYear();
  const hours = date.getUTCHours();

  let showHours = hours;
  if (showHours === 0) {
    showHours = 12;
  } else if (showHours > 12) {
    showHours -= 12;
  }
  text +=
    " | " +
    showHours +
    "." +
    ("0" + date.getUTCMinutes()).substr(-2) +
    " " +
    (hours >= 12 ? "PM" : "AM");

  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. 2019-06-30 14:00:00
 */
export const formatDateTime5 = (time, timezoneOffset) => {
  let date = new Date(time);
  if (timezoneOffset) {
    date.setTime(date.getTime() + timezoneOffset * 1000);
  }

  let text =
    date.getUTCFullYear() +
    "-" +
    ("0" + (date.getUTCMonth() + 1)).substr(-2) +
    "-" +
    ("0" + date.getUTCDate()).substr(-2) +
    " " +
    ("0" + date.getUTCHours()).substr(-2) +
    ":" +
    ("0" + date.getUTCMinutes()).substr(-2) +
    ":" +
    ("0" + date.getUTCSeconds()).substr(-2);
  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. Aug 16, 2018
 */
export const formatDateTime6 = (time, timezoneOffset) => {
  let date = new Date(time);
  date.setTime(date.getTime() + timezoneOffset * 1000);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfMonth = date.getUTCDate();
  let text = monthNames[date.getUTCMonth()] + " " + dayOfMonth;
  text += ", " + date.getUTCFullYear();

  return text;
};

export const englishOrdinalSuffix = (date) => {
  return date % 10 === 1 && date !== 11
    ? "st"
    : date % 10 === 2 && date !== 12
    ? "nd"
    : date % 10 === 3 && date !== 13
    ? "rd"
    : "th";
};

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  let res = [];
  if (hours) {
    res.push(("0" + hours).substr(-2));
  }
  res.push(("0" + minutes).substr(-2));
  res.push(("0" + Math.floor(seconds + 0.5)).substr(-2));
  return res.join(":");
};

export const timezoneOffsetToStr = (timezoneOffset) => {
  const offset = Math.abs(timezoneOffset / 60);
  const offsetStr =
    (timezoneOffset >= 0 ? "+" : "-") +
    `0${Math.floor(offset / 60)}`.slice(-2) +
    ":" +
    `0${(offset / 60 - Math.floor(offset / 60)) * 60}`.slice(-2);
  return offsetStr;
};

export const ucfirst = (string) => {
  if (!string || string === "") {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const trim = (string, length) => {
  if (!string || string === "") {
    return "";
  }
  return (
    string.trim().substr(0, length).trim() +
    (string.length > length ? "..." : "")
  );
};

/**
 * Format number. Similar function you can use in API endpoint, NumbersComponent::formatNumber
 * @param number Full number (with country code, without + prefix)
 * @param allowNonDigit
 * @returns {string} Formatted number
 */
export const formatPhoneNumber = (
  number,
  phonecode,
  allowNonDigit = false,
  useShorterVersion = false
) => {
  number = number ? String(number) : "";
  if (allowNonDigit && number.replace(/[^0-9]/g, "") !== number) {
    return number;
  }
  if (number.match(/[a-zA-Z]+/)) {
    return number;
  }
  number = number.replace(/[^0-9]/g, "");
  if (phonecode) {
    if (useShorterVersion) {
      number = `+${phonecode} ${number}`;
      number = number.length <= 4 ? number : `...${number.substr(-4)}`;
    } else if (number.length === 10 && phonecode) {
      number = `+${phonecode} ${number.replace(
        /([0-9]{3})([0-9]{3})([0-9]{4})/,
        "($1) $2-$3"
      )}`;
    } else {
      number = `+${phonecode} ${number}`;
    }
  } else {
    if (useShorterVersion) {
      number = number.length <= 4 ? number : `...${number.substr(-4)}`;
    } else if (number.length >= 7 && number.length <= 9) {
      number = number.replace(/([0-9]{3})([0-9]{4})/, "$1-$2");
    } else if (number.length === 10) {
      number = number.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, "($1) $2-$3");
    } else if (number.length === 11) {
      number = number.replace(
        /([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{4})/,
        "+$1 ($2) $3-$4"
      );
    }
  }
  return number;
};
export const contactsName = (contacts, asHtml, maxCount) => {
  // filter only primary contacts
  let filteredContacts = [];
  if (contacts.length > 1) {
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].is_primary) {
        filteredContacts.push(contacts[i]);
      }
    }
  } else {
    filteredContacts = contacts;
  }
  contacts = filteredContacts;

  if (contacts && contacts.length === 1) {
    let name = contactName(
      contacts[0].first_name,
      contacts[0].last_name,
      "" +
        (contacts[0].country ? contacts[0].country.phonecode : "") +
        contacts[0].phone_number
    );
    if (asHtml) {
      return (
        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {name}
        </span>
      );
    }
  }

  let names = [];
  for (let i = 0; i < contacts.length; i++) {
    /*if (contacts[i].is_duplicate) {
      continue;
    }*/
    names.push(
      contactName(
        contacts[i].first_name,
        contacts[i].last_name,
        "" +
          (contacts[i].country ? contacts[i].country.phonecode : "") +
          contacts[i].phone_number
      )
    );
  }
  maxCount = 4;
  let namesUnique = [...new Set(names)];
  if (namesUnique.length > maxCount) {
    namesUnique = namesUnique.slice(0, maxCount);
  }

  if (!asHtml) {
    if (contacts.length > namesUnique.length) {
      namesUnique.push("+" + (contacts.length - namesUnique.length));
    }
    return namesUnique.join(", ");
  }

  let namesArr = [];
  for (let i = 0; i < namesUnique.length; i++) {
    namesArr.push(
      <span
        key={namesUnique[i]}
        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {namesUnique[i]}
        {i + 1 < namesUnique.length ? "," : ""}&nbsp;
      </span>
    );
  }

  return (
    <React.Fragment>
      {namesArr}
      {contacts.length > namesUnique.length &&
        "+" + (contacts.length - namesUnique.length)}
    </React.Fragment>
  );
};

export const contactName = (first_name, last_name, phone_number) => {
  let str = first_name && first_name !== "" ? first_name : "";
  str += last_name && last_name !== "" ? " " + last_name : "";
  str = str.trim();
  if (str === "") {
    str = phone_number ? formatPhoneNumber(phone_number) : "Unnamed/Empty";
  }
  return str;
};

export const getAppTime = () => {
  if (!store.getState().settings || !store.getState().settings.settings) {
    return null;
  }
  return (
    new Date().getTime() - store.getState().settings.settings._appTimeDiffMs
  );
};

export const getCampaignType = (log, scheduleMessage) => {
  let campaignId = null;
  let contestId = null;
  let questionId = null;
  let autoresponderId = null;

  if (scheduleMessage) {
    campaignId = scheduleMessage.automessage_id;
    contestId = scheduleMessage.contest_id;
    questionId = scheduleMessage.question_id;
  } else if (log) {
    campaignId = log.campaign_id;
    contestId = log.contest_id;
    questionId = log.question_id;
    autoresponderId = log.autoresponder_id;
  }

  if (campaignId > 0) {
    return "am";
  } else if (contestId > 0) {
    return "contests";
  } else if (questionId > 0) {
    return "poll";
  } else if (autoresponderId > 0) {
    return "dc";
  } /* else if (birthdayId > 0) { // Birthday flag is not supported in main PHP app yet
    return 'birthday';
  }*/
  return null;
};

export const getAccountIssueInfo = (
  isBillingIssue,
  isSuspended,
  numberIsDeleted,
  billingPackage,
  credits,
  billingIssueDays,
  isAdmin,
  isInvalidEmail
) => {
  if (isBillingIssue === 0 && credits > 0 && isInvalidEmail === 0) {
    return null;
  }

  let issueKey = null;
  let icon = "payment";
  let alertClass = "";
  let message = "";
  let shortMessage = "";
  let dismissible = true;
  let sendLocked = false;
  let url = null;
  let isOverlay = false;
  if (isBillingIssue === 1) {
    issueKey = "soft";
    alertClass = "info";
    if (billingPackage <= 35) {
      message =
        "Your trial has ended. Continue testing our platform by extending your trial period. Use the invite code <strong>GRACE</strong> when choosing a plan and pay only $1 for 14 more days.";
      shortMessage = "Your trial has ended. Please pick a plan.";
      url = isAdmin ? getPrimaryUrl("users/billing") : null;
    } else {
      message =
        "Uh oh, it looks like your payment failed. Your account will be suspended in " +
        billingIssueDays +
        " days if you decide not to do anything.";
      shortMessage = "Your payment has been declined.";
      url = isAdmin ? getPrimaryUrl("users/payBilling") : null;
    }
  } else if (isBillingIssue === 2 && isSuspended === 1) {
    issueKey = "medium";
    alertClass = "warning";
    if (billingPackage <= 35) {
      message =
        "Your trial has ended and sending messages is temporarily disabled. Your number(s) will be released soon.<br/>Continue testing our platform by extending your trial period. Use the invite code <strong>GRACE</strong> when choosing a plan and pay only $1 for 14 more days.";
      shortMessage = "Your trial has ended. Please pick a plan.";
      url = isAdmin ? getPrimaryUrl("users/billing") : null;
    } else {
      message =
        "It looks like your payment failed again. Sending messages is disabled. Your account will lose access to your number(s) and all your credits in " +
        billingIssueDays +
        " days if this issue isn’t rectified asap.";
      shortMessage = "It looks like your payment failed again.";
      url = isAdmin ? getPrimaryUrl("users/payBilling") : null;
    }
    sendLocked = true;
  } else if (isBillingIssue === 3 && isSuspended === 2) {
    issueKey = "hard";
    alertClass = "danger";
    isOverlay = true;
    if (billingPackage <= 35) {
      message =
        "Within 48 hrs, you will lose access to your messaging credits, phone number(s) and access.";
      shortMessage = "Your trial has ended. Please pick a plan.";
    } else {
      message =
        "Payment Declined: In 48 hrs, your number(s) and messaging credits will be deleted and the account will be closed.";
      shortMessage = message;
    }
    dismissible = false;
    sendLocked = true;
  } else if (isBillingIssue === 4 && isSuspended === 2 && numberIsDeleted) {
    issueKey = "inactive";
    alertClass = "inactive";
    isOverlay = true;
    if (billingPackage <= 35) {
      message = "Your account is no longer active.";
      shortMessage = "Your trial has ended. Please pick a plan.";
    } else {
      message =
        "Account closed: Your number(s) and messaging credits have been deleted.";
      shortMessage = message;
    }
    dismissible = false;
    sendLocked = true;
  } else if (credits !== null && credits <= 0) {
    issueKey = "no-credits";
    icon = "warning";
    alertClass = "info";
    message = "You don’t have enough credits.";
    shortMessage = message;
    sendLocked = true;
    if (isAdmin) {
      url = getPrimaryUrl("users/billing");
    }
  } else if (isInvalidEmail) {
    issueKey = "invalid-email";
    icon = "warning";
    alertClass = "info";
    message = "Your email appears to be invalid. Click here to edit.";
    shortMessage = message;
    dismissible = false;
    sendLocked = false;
    if (isAdmin) {
      url = getPrimaryUrl("users/user_setting");
    }
  }

  if (!message) {
    return null;
  }

  return {
    issueKey,
    icon,
    alertClass,
    message,
    shortMessage,
    dismissible,
    sendLocked,
    url,
    billingPackage,
    billingIssueDays,
    isOverlay,
  };
};

export const getFormattedAccountCredits = (credits) => {
  const creditsFull =
    credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " Messages";
  let creditsSimple = "";
  if (credits > 1000000) {
    creditsSimple = "Over " + Math.floor(credits / 1000000) + "M Messages";
  } else if (credits > 500000) {
    creditsSimple = "Over 0.5M Messages";
  } else if (credits > 100000) {
    creditsSimple = "Over " + Math.floor(credits / 100000) * 100 + "K Messages";
  } else if (credits > 10000) {
    creditsSimple = "Over " + Math.floor(credits / 10000) * 10 + "K Messages";
  } else {
    creditsSimple = creditsFull;
  }

  return { creditsSimple, creditsFull };
};

export const contactsStats = (contacts) => {
  let blockedCount = 0;
  let unsubscribedCount = 0;
  let optedoutCount = 0;
  let donotcallCount = 0;
  let duplicatesCount = 0;
  let voipCount = 0;
  let landlineCount = 0;
  let mobileCount = 0;
  let uncheckedCount = 0;
  let failedCount = 0;

  let blockedNumbers = "";
  let unsubscribedNumbers = "";
  let optedoutNumbers = "";
  let donotcallNumbers = "";
  let duplicatesNumbers = "";
  let voipNumbers = "";
  let landlineNumbers = "";
  let mobileNumbers = "";
  let uncheckedNumbers = "";
  let failedNumbers = "";

  for (let i = 0; i < contacts.length; i++) {
    // ommit groups
    if (typeof contacts[i].group_name !== "undefined") {
      continue;
    }
    const contact = contacts[i];
    const phoneNumber = formatPhoneNumber(
      "" +
        (contact.country ? contact.country.phonecode : "") +
        contact.phone_number
    );

    if (contact.status === 3 && contact.unsub_by_user_id > 0) {
      unsubscribedCount++;
      unsubscribedNumbers +=
        (unsubscribedNumbers.length ? ", " : "") + phoneNumber;
    }
    if (contact.status === 3 && contact.unsub_by_user_id === 0) {
      optedoutCount++;
      optedoutNumbers += (optedoutNumbers.length ? ", " : "") + phoneNumber;
    }
    if (contact.status === 1) {
      blockedCount++;
      blockedNumbers += (blockedNumbers.length ? ", " : "") + phoneNumber;
    }
    if (contact.is_duplicate === 1) {
      duplicatesCount++;
      duplicatesNumbers += (duplicatesNumbers.length ? ", " : "") + phoneNumber;
    }
    if (contact.number_lookup) {
      if (contact.number_lookup.type === "voip") {
        voipCount++;
        voipNumbers += (voipNumbers.length ? ", " : "") + phoneNumber;
      } else if (contact.number_lookup.type === "landline") {
        landlineCount++;
        landlineNumbers += (landlineNumbers.length ? ", " : "") + phoneNumber;
      } else if (contact.number_lookup.type === "mobile") {
        mobileCount++;
        mobileNumbers += (mobileNumbers.length ? ", " : "") + phoneNumber;
      } else if (contact.number_lookup.type === "zeroNumber") {
        donotcallCount++;
        donotcallNumbers += (donotcallNumbers.length ? ", " : "") + phoneNumber;
      } else {
        failedCount++;
        failedNumbers += (failedNumbers.length ? ", " : "") + phoneNumber;
      }
    } else {
      uncheckedCount++;
      uncheckedNumbers += (uncheckedNumbers.length ? ", " : "") + phoneNumber;
    }
  }

  return {
    blockedCount,
    unsubscribedCount,
    optedoutCount,
    donotcallCount,
    duplicatesCount,
    voipCount,
    landlineCount,
    mobileCount,
    uncheckedCount,
    failedCount,
    blockedNumbers,
    unsubscribedNumbers,
    optedoutNumbers,
    donotcallNumbers,
    duplicatesNumbers,
    voipNumbers,
    landlineNumbers,
    mobileNumbers,
    uncheckedNumbers,
    failedNumbers,
  };
};

export const isIntegrationContact = ({
  is_ccb,
  is_pco,
  is_mc,
  is_elvanto,
  is_breeze,
}) => {
  if (is_ccb) {
    return "CCB";
  }
  if (is_pco) {
    return "PCO";
  }
  if (is_mc) {
    return "MailChimp";
  }
  if (is_elvanto) {
    return "Elvanto";
  }
  if (is_breeze) {
    return "Breeze";
  }
  return false;
};

export const isIOs = () => {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
};

export const isMobile = () => {
  return document.body.clientWidth < 992;
};

export const getDeviceSize = () => {
  if (document.body.clientWidth >= 1200) {
    return "xl";
  } else if (document.body.clientWidth >= 992) {
    return "lg";
  } else if (document.body.clientWidth >= 768) {
    return "md";
  } else if (document.body.clientWidth >= 576) {
    return "sm";
  } else {
    return "xs";
  }
};

export const isCordovaApp = () => {
  return (
    typeof cordova == "object" ||
    navigator.userAgent.toLowerCase().indexOf("cordova") > -1
  );
};

export const isAndroid = () => {
  return /Android/.test(navigator.userAgent);
};

export const isRecordingAudioSupported = () => {
  if (
    typeof MediaRecorder !== "undefined" &&
    typeof navigator.mediaDevices !== "undefined" &&
    typeof navigator.mediaDevices.getUserMedia !== "undefined"
  ) {
    return true;
  }
  return false;
};

export const flattenErrorsObject = (errors) => {
  return [].concat.apply(
    [],
    Object.keys(errors).map((field) => {
      return Object.values(errors[field]);
    })
  );
};

export const parseApiErrors = (
  data,
  defaultErrors = {},
  insertBreakLines = false
) => {
  const errors = defaultErrors;
  if (typeof data === "string") {
    errors["form"] = data;
  } else if (typeof data === "object") {
    if (!data) {
      errors["form"] = "An error occurred.";
    } else if (
      typeof data.message !== "undefined" &&
      typeof data.url !== "undefined" &&
      typeof data.code !== "undefined"
    ) {
      errors["form"] = data.message;
      if (errors["form"].substr(-1) !== ".") {
        errors["form"] += ".";
      }
    } else {
      const fields = Object.keys(data);
      for (let i = 0; i < fields.length; i += 1) {
        let field = fields[i];
        if (field === "_") {
          field = "form";
        }
        errors[field] = Object.values(data[fields[i]]);
        for (let j = 0; j < errors[field].length; j += 1) {
          if (errors[field][j].substr(-1) !== ".") {
            errors[field][j] += ".";
          }
        }
      }
    }
  }

  if (insertBreakLines) {
    const errorsFields = Object.keys(errors);
    for (let i = 0; i < errorsFields.length; i += 1) {
      const errorsField = errorsFields[i];
      if (errorsField === "form" || !errors[errorsField]) {
        continue;
      }
      const errorsWithBr = [];
      for (let j = 0; j < errors[errorsField].length; j += 1) {
        if (j > 0) {
          errorsWithBr.push(<br key={j} />);
        }
        errorsWithBr.push(errors[errorsField][j]);
      }
      errors[errorsField] = errorsWithBr;
    }
  }

  return errors;
};

export const injectTextIntoField = (field, text, smartInsert = true) => {
  if (!text || text.length === 0) {
    return;
  }

  const { maxLength } = field;

  //IE support
  if (document.selection) {
    field.focus();
    let sel = document.selection.createRange();
    sel.text = text;
  }
  //MOZILLA and others
  else if (field.selectionStart || field.selectionStart === 0) {
    let startPos = field.selectionStart;
    let endPos = field.selectionEnd;

    let insertText = text;
    if (smartInsert) {
      if (startPos === endPos) {
        const preChar =
          startPos > 0 ? field.value.substr(startPos - 1, 1) : null;
        const postChar =
          endPos < field.value.length ? field.value.substr(endPos, 1) : null;

        if (
          preChar !== null &&
          preChar !== "(" &&
          preChar !== "[" &&
          preChar !== "<" &&
          preChar !== " "
        ) {
          insertText = ` ${insertText}`;
        }
        if (
          postChar !== null &&
          postChar !== ")" &&
          postChar !== "]" &&
          postChar !== ">" &&
          postChar !== " "
        ) {
          insertText = `${insertText} `;
        }
      }
    }

    const newValue =
      field.value.substring(0, startPos) +
      insertText +
      field.value.substring(endPos, field.value.length);
    if (typeof maxLength !== "undefined" && newValue.length > maxLength) {
      field.focus();
      throw new Error("too-long");
    }

    field.value = newValue;
    field.setSelectionRange(
      startPos + insertText.length,
      startPos + insertText.length
    );
  } else {
    const newValue = field.value + (field.value.length ? " " : "") + text;
    if (typeof maxLength !== "undefined" && newValue.length > maxLength) {
      field.focus();
      throw new Error("too-long");
    }

    field.value += newValue;
  }
  field.focus();
};

export const injectTextInfoTextField = (field, text, smartInsert = true) => {
  if (!text || text.length === 0) {
    return null;
  }

  let newValue = field.value;
  const { maxLength } = field;
  let restorePosition = 0;

  // MOZILLA and others (without old IE)
  if (field.selectionStart || field.selectionStart === 0) {
    let startPos = field.selectionStart;
    let endPos = field.selectionEnd;

    let insertText = text;
    if (smartInsert) {
      if (startPos === endPos) {
        const preChar =
          startPos > 0 ? field.value.substr(startPos - 1, 1) : null;
        const postChar =
          endPos < field.value.length ? field.value.substr(endPos, 1) : null;

        if (
          preChar !== null &&
          preChar !== "(" &&
          preChar !== "[" &&
          preChar !== "<" &&
          preChar !== " "
        ) {
          insertText = ` ${insertText}`;
        }
        if (
          postChar !== null &&
          postChar !== ")" &&
          postChar !== "]" &&
          postChar !== ">" &&
          postChar !== " "
        ) {
          insertText = `${insertText} `;
        }
      }
    }

    newValue =
      field.value.substring(0, startPos) +
      insertText +
      field.value.substring(endPos, field.value.length);
    if (typeof maxLength !== "undefined" && newValue.length > maxLength) {
      throw new Error("too-long");
    }

    restorePosition = startPos + insertText.length;
  } else {
    newValue = field.value + (field.value.length ? " " : "") + text;
    if (typeof maxLength !== "undefined" && newValue.length > maxLength) {
      throw new Error("too-long");
    }
  }

  return { newValue, restorePosition };
};

export const restoreCursorPosition = (field, position) => {
  field.setSelectionRange(position, position);
  field.focus();
};

let lastUsedNumbers = null;
let lastReturnedNumbers = null;
export const getOnlyNonPersonalNumbers = (numbers) => {
  if (numbers === lastUsedNumbers) {
    return lastReturnedNumbers;
  }

  const filteredNumbers = [];
  if (numbers) {
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].src === 4) {
        continue;
      }
      filteredNumbers.push(numbers[i]);
    }
  }

  lastUsedNumbers = numbers;
  lastReturnedNumbers = filteredNumbers;
  return filteredNumbers;
};

let lastUsedNumbersForShortCode = null;
let lastReturnedShortcode = null;
export const getFirstShortCodeNumber = (numbers) => {
  if (numbers === lastUsedNumbersForShortCode) {
    return lastReturnedShortcode;
  }

  let shortCode = null;
  if (numbers) {
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].src === 5) {
        shortCode = numbers[i].number;
        break;
      }
    }
  }

  lastUsedNumbersForShortCode = numbers;
  lastReturnedShortcode = shortCode;
  return lastReturnedShortcode;
};

export const getVerifiedTollFreeNumber = (numbers) => {
  if (!numbers) return;

  for (let number of numbers) {
    if (number.src === 6) return number.number;
  }
};

/**
 *
 * @param company
 * @param users
 * @param userId
 * @param number
 * @param useDefaultNumber - 0 - don't, 1 - only if not passed, 2 - always
 * @returns {{number: string, signaturePreview: string, userId: number}}
 */
export const getSignatureData = (
  company,
  users,
  userId,
  number,
  useDefaultNumber = 0,
  signatureProp = "short_code_signature_normal_send"
) => {
  let signaturePreview = "";
  let useUserId = 0;
  let useUser = null;
  let useNumber = "";

  if (userId > 0) {
    for (let i = 0; i < users.length; i += 1) {
      if (users[i].user.id === userId) {
        useUserId = userId;
        useUser = users[i];
        break;
      }
    }
  }

  if (useUser) {
    if (number !== "") {
      for (let i = 0; i < useUser.numbers.length; i += 1) {
        if (useUser.numbers[i].number === number) {
          useNumber = useUser.numbers[i].number;
          break;
        }
      }
    }
    if (
      useNumber === "" &&
      (useDefaultNumber === 2 || (useDefaultNumber === 1 && number === ""))
    ) {
      if (useDefaultNumber && useUser.numbers.length === 1) {
        useNumber = useUser.numbers[0].number;
      } else {
        for (let i = 0; i < useUser.numbers.length; i += 1) {
          if (useUser.numbers[i].is_default) {
            useNumber = useUser.numbers[i].number;
            break;
          }
        }
      }
    }
  }

  if (useUser && useNumber !== "") {
    const template = company[signatureProp];

    signaturePreview = template
      .replace("[User Primary Number]", useNumber)
      .replace("[Org Short Name]", company.short_name)
      .replace("[User Short Name]", useUser.user.short_name);
  }

  return {
    userId: useUserId,
    number: useNumber,
    signaturePreview,
  };
};

/**
 * Similar method is coded in API
 */
export const isGsmAlphabet = (text) => {
  const regexp = new RegExp(
    "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
  );
  return regexp.test(text);
};

export const getNonGsmCharactersCount = (text) => {
  const regexp = new RegExp(
    "[^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]",
    "g"
  );
  const matches = text.match(regexp);
  return matches ? [...matches].length : 0;
};

export const getExtGsmCharactersCount = (text) => {
  // Count characters: ~ ^ | \ [ ] { } €
  const regexp = new RegExp(
    "[\u007B\u007C\u007D\u007E\u005B\u005C\u005D\u005E\u20AC\\\\]",
    "g"
  );
  const matches = text.match(regexp);
  return matches ? [...matches].length : 0;
};

export const groupName = (group) => {
  let adHocName = null;

  if (group.group_name && group.adhoc_name == null) {
    return group.group_name;
  } else if (group.adhoc_name && group.adhoc_name.length > 0) {
    adHocName = group.adhoc_name;
  } else if (group.adhoc_name && group.adhoc_name.length === 0) {
    return "Unnamed/Empty";
  } else {
    return group ? "Unnamed/Empty" : "Unknown";
  }

  if (adHocName) {
    adHocName =
      adHocName.length > 20 ? `${adHocName.substring(0, 20)}...` : adHocName;
  }

  return adHocName;
};

export const getNameBy = (contactId, groupId, contactsData, groupsData) => {
  let name = "...";

  if (contactId > 0) {
    const contactData = contactsData[contactId];
    name = contactName(
      contactData.first_name,
      contactData.last_name,
      contactData.phone_number
    );
  } else if (groupId > 0) {
    name = groupName(groupsData[groupId]);
  }

  return name;
};

export const LightTooltip = withStyles((theme) => ({
  popper: {
    zIndex: 111111,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
    border: "1px solid #acacac",
    padding: "2px 8px",
    borderRadius: "0.25rem",
    maxWidth: 200,
    textAlign: "center",
  },
  arrow: {
    color: theme.palette.common.white,
    "&:before": {
      border: "1px solid #acacac",
    },
  },
}))(Tooltip);

export const validateNumber = (value) => {
  var numReg = /^[0-9]+$/;

  return value.match(numReg) ? true : false;
};

export const spliceNonNumericCharacters = (string) => {
  return string.replace(/[^0-9]+/g, "");
};

export const getDuplicateViewInfo = (contactsData) => {
  let notArchivedCounter = 0;

  let singleUnArchivedContact = null;

  if (contactsData == null) return {};

  for (const contactData of contactsData) {
    if (contactData.is_archived === 0) {
      notArchivedCounter += 1;

      singleUnArchivedContact = contactData;
    }

    if (notArchivedCounter > 1) {
      singleUnArchivedContact = null;
    }
  }

  return {
    isSingleMemberDuplicateRestArchived: notArchivedCounter === 1,
    singleUnArchivedContact,
  };
};

export const getThreadName = (threadObj) => {
  if (!threadObj) return null;
  let name = "";
  const isGroup = typeof threadObj.group_name !== "undefined";
  if (isGroup) {
    name = threadObj.group_name;
  } else if (threadObj.multiName) {
    name = threadObj.multiName.props?.children[0]
      ?.map((item) => item.key)
      ?.join(",");
  } else {
    name = contactName(
      threadObj.first_name,
      threadObj.last_name,
      "" +
        (threadObj.country ? threadObj.country.phonecode : "") +
        threadObj.phone_number
    );
  }
  return name;
};

export const getPrimaryUrl = (url) => {
  const urlObject = new URL(`${AxiosConfig.getPrimaryAppAddress()}${url}`);
  const isSearchParamFound = !!urlObject.search;
  const compId = store.getState().companies?.currentCompany?.id;
  const newUrl = urlObject.href.replace(urlObject.hash, "");

  return `${newUrl}${isSearchParamFound ? "&" : "?"}compId=${compId}${
    urlObject.hash
  }`;
};

export const groupDuplicatesByApp = (contactIds, contactsData) => {
  let contactIdsGrouped = [];
  let membersKeys = [];
  for (let i = 0; i < contactIds.length; i += 1) {
    const contact = contactsData[contactIds[i]];
    const phonecode = contact.country ? contact.country.phonecode : "";
    const phone = `${phonecode}${contact.phone_number}`;
    let add = false;
    if (
      phone === "" ||
      phone === "1" ||
      phone === "10" ||
      phone === "-" ||
      phone === "1-"
    ) {
      add = true;
    } else {
      let app = "pl";
      if (contact.is_breeze) {
        app = "breeze";
      } else if (contact.is_ccb) {
        app = "ccb";
      } else if (contact.is_elvanto) {
        app = "elvanto";
      } else if (contact.is_mc) {
        app = "mc";
      } else if (contact.is_pco) {
        app = "pco";
      }

      const key = `${app}-${phonecode}${contact.phone_number}`;
      add = typeof membersKeys[key] === "undefined";
      membersKeys[key] = true;
    }

    if (add) {
      contactIdsGrouped.push(contactIds[i]);
    }
  }
  return contactIdsGrouped;
};

export const isAdminOrOwner = (currentCompany) =>
  currentCompany.is_admin_user ||
  currentCompany.is_admin_plus ||
  currentCompany.is_main_owner;

export const getUsersForSignature = ({
  users,
  currentCompany,
  numbers,
  loggedUser,
}) =>
  isAdminOrOwner(currentCompany)
    ? users
    : [
        {
          user: loggedUser,
          numbers: numbers,
        },
      ];

export const hasNumberAssigned = (numbers) => {
  if (!numbers) return false;

  for (let i = 0; i < numbers.length; i++) {
    if (numbers[i].is_default) {
      return true;
    }
  }

  return false;
};

export const getBreakPoints = (deviceWidth) => {
  if (0 < deviceWidth && deviceWidth < 468) {
    return breakpoints[468];
  } else if (468 <= deviceWidth && deviceWidth <= 768) {
    return breakpoints[768];
  } else if (768 < deviceWidth && deviceWidth <= 992) {
    return breakpoints[992];
  } else if (992 < deviceWidth && deviceWidth <= 1200) {
    return breakpoints[1200];
  } else if (deviceWidth >= 1200) {
    return breakpoints[1500];
  }
  return "";
};

export const appendAddNewToDataObject = (oldData, newData) => {
  const groups = newData || {};

  for (let key in groups) {
    oldData[key] = oldData[key]
      ? {
          ...oldData[key],
          ...groups[key],
        }
      : groups[key];
  }

  return oldData;
};

export const getV3Url = (url) => {
  return `${process.env.REACT_APP_V3_APP_URL}${url}`;
};
