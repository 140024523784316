import React from "react";

import { ReactComponent as HubIcon } from "../assets/img/icons/hub-icon.svg";

const AppLoading = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#5517C1",
        color: "#fff",
        fontSize: "large",
        textAlign: "center",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <HubIcon width="48" height="40" alt="" />
        <p className="d-block  mt-3">The HUB | PastorsLine 2.0</p>
        <div
          className="clear-icon spinner-border text-white"
          role="status"
          style={{ width: "17px", height: "17px", borderWidth: "1.5px" }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default AppLoading;
