import React from "react";
import { connect } from "react-redux";
import { ModalsContext } from "../../ModalsContext";
import { withRouter } from "react-router";
import { contactName } from "../../helpers";

import { editContactField } from "../../actions/contacts";

function mapStateToProps(store, ownProps) {
  let contactId = parseInt(ownProps.param);
  let saveStatus = null;
  if (
    store.contacts.editFieldQueue &&
    typeof store.contacts.editFieldQueue["unsubscribe"] !== "undefined"
  ) {
    saveStatus = store.contacts.editFieldQueue["unsubscribe"].status;
  }
  return {
    currentCompanyId: store.companies.currentCompany.id,
    contactData:
      typeof store.contacts.data[contactId] === "undefined"
        ? null
        : store.contacts.data[contactId],
    contactStatus:
      typeof store.contacts.dataStatus[contactId] === "undefined"
        ? null
        : store.contacts.dataStatus[contactId],
    saveStatus: saveStatus,
    contactId: contactId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editContactField: (companyId, contactId, contactData, requestId) =>
      dispatch(editContactField(companyId, contactId, contactData, requestId)),
  };
}

class UnsubscribeContactModal extends React.Component {
  static contextType = ModalsContext;

  constructor(props) {
    super(props);

    this.updateView = this.updateView.bind(this);
    this.confirm = this.confirm.bind(this);
    this.state = {
      isShown: false,
      isHide: false,
    };
  }

  componentDidMount() {
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
    }
    if (
      this.props.saveStatus !== prevProps.saveStatus &&
      this.props.saveStatus === "success"
    ) {
      this.props.history.goBack();
    }
  }

  updateView() {
    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  confirm() {
    this.props.editContactField(
      this.props.currentCompanyId,
      this.props.contactId,
      { status: 3 },
      "block"
    );
  }

  render() {
    let contactNameStr = "...";
    if (this.props.contactStatus === "success") {
      contactNameStr = contactName(
        this.props.contactData.first_name,
        this.props.contactData.last_name,
        this.props.contactData.phone_number
      );
    }

    return (
      <div
        className={
          "modal fade inbox-modal confirm-group-message" +
          (this.state.isShown && !this.state.isHide ? " show" : "")
        }
        style={
          this.props.show || this.state.isShown ? { display: "block" } : {}
        }
        id="UnsubscribeContactModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="UnsubscribeContactModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-backdrop fade show"
          onClick={(e) => {
            e.preventDefault();
            this.props.history.goBack();
          }}
        />
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          style={{ zIndex: 100 }}
        >
          <div className="modal-content">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.goBack();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.86"
                height="20.86"
                viewBox="0 0 20.86 20.86"
              >
                <g id="close-icon" transform="translate(-806.57 -377.07)">
                  <rect
                    id="Rectangle_390"
                    data-name="Rectangle 390"
                    width="24"
                    height="5.5"
                    transform="translate(806.57 394.041) rotate(-45)"
                    fill="#122b28"
                  ></rect>
                  <rect
                    id="Rectangle_391"
                    data-name="Rectangle 391"
                    width="24"
                    height="5.5"
                    transform="translate(810.459 377.07) rotate(45)"
                    fill="#122b28"
                  ></rect>
                </g>
              </svg>
            </button>
            <div className="modal-body">
              <div className="title mb-5">
                <h4>Confirm unsubscribe of {contactNameStr}</h4>
              </div>
              <div className="content caller-info">
                <p>Are you sure you want to unsubscribe {contactNameStr}?</p>
              </div>
              <div className="modal-footer mt-40">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    this.props.history.goBack();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.confirm}
                >
                  Yes, unsubscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnsubscribeContactModal)
);
