/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import Cookies from "universal-cookie";
import { cookies } from "../store";

export function fetchCompanies() {
  return function (dispatch) {
    dispatch({ type: "FETCH_COMPANIES" });

    const url = "companies.json";
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_COMPANIES_SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_COMPANIES_ERROR",
          payload: error,
        });
      });
  };
}

export function setCurrentCompany(id, isAutoSwitched = false) {
  return function (dispatch) {
    dispatch({
      type: "SET_CURRENT_COMPANY",
      payload: {
        companyId: id,
        isAutoSwitched,
      },
    });
    const cookies = new Cookies();
    let expires = new Date();
    expires.setDate(expires.getDate() + 365);
    const companyCookieName = "company_id_" + AxiosConfig.getCookieNameDomain();
    cookies.set(companyCookieName, id, {
      path: "/",
      expires: expires,
      domain: AxiosConfig.getSharedCookieDomain(),
    });

    // save new company on old app session
    //axios.get(AxiosConfig.getPrimaryAppAddress() + 'users/select_company/' + id, {withCredentials: true});
  };
}

export function closeAccountIssueBar(companyId, issueKey) {
  return function (dispatch) {
    if (issueKey === null) {
      cookies.remove("account_issue_closed__" + companyId, { path: "/" });
    } else {
      cookies.set("account_issue_closed__" + companyId, issueKey, {
        path: "/",
      });
    }
    dispatch({ type: "COMPANIES/CLOSE_ACCOUNT_ISSUE_BAR" });
  };
}

export function fetchUsers(companyId) {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/FETCH_USERS" });

    const url = `companies/users.json?companyId=${companyId}`;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "COMPANIES/FETCH_USERS.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "COMPANIES/FETCH_USERS.ERROR",
          payload: error,
        });
      });
  };
}

export function clearIsAutoSwitched() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/CLEAR_AUTO_SWITCHED_FLAG" });
  };
}
