import { formatDateTime6 } from "../helpers";

const initialState = {
  // Check template name
  checkTemplateNameStatus: null,
  checkTemplateNameExists: null,

  // Estimate price
  estimatePriceStatus: null,
  estimatePriceSubscribers: null,
  estimatePriceCredits: null,

  // Tts price
  ttsPriceStatus: null,
  ttsPriceCreditsPerBlock: null,

  // Add Broadcast
  addStatus: null,
  addId: null,
  addErrors: null,
  addTemplateStatus: null,
  addTemplateId: null,
  addTemplateName: null,
  addTemplateErrors: null,

  // Edit Broadcast
  editStatus: null,
  editId: null,
  editErrors: null,

  // Languages
  languagesStatus: null,
  languages: null,

  // Templates
  templatesStatus: null,
  templates: null,
  templatesPage: null,
  templatesLoadedAll: null,
  templatesPagination: {},

  // Schedule Message
  scheduleMessageStatus: null,
  scheduleMessageId: null,
  scheduleMessageData: null,

  // History
  historyStatus: null,
  history: null,
  historyPage: null,
  historyLoadedAll: null,
};

export default function reducer(state = initialState, action) {
  let templates = null;
  let newTemplates = null;

  switch (action.type) {
    // Check template name
    case "BROADCASTS/CHECK_TEMPLATE_NAME":
      return {
        ...state,
        checkTemplateNameStatus: "loading",
        checkTemplateNameExists: null,
      };

    case "BROADCASTS/CHECK_TEMPLATE_NAME.SUCCESS":
      return {
        ...state,
        checkTemplateNameStatus: "success",
        checkTemplateNameExists: action.payload.exists,
      };

    case "BROADCASTS/CHECK_TEMPLATE_NAME.ERROR":
      return {
        ...state,
        checkTemplateNameStatus: "error",
        checkTemplateNameExists: null,
      };

    case "BROADCASTS/CHECK_TEMPLATE_NAME.CLEAR":
      return {
        ...state,
        checkTemplateNameStatus: null,
        checkTemplateNameExists: null,
      };

    // Add Broadcast Template
    case "BROADCASTS/ADD_TEMPLATE":
      return {
        ...state,
        addTemplateStatus: "loading",
        addTemplateErrors: null,
      };

    case "BROADCASTS/ADD_TEMPLATE.SUCCESS":
    case "BROADCASTS/ADD_TEMPLATE.ERROR":
      if (action.payload) {
        return {
          ...state,
          addTemplateStatus: action.payload.broadcastTemplateStatus,
          addTemplateId: action.payload.broadcastTemplate
            ? action.payload.broadcastTemplate.id
            : null,
          addTemplateName: action.payload.broadcastTemplate
            ? action.payload.broadcastTemplate.name
            : null,
          addTemplateErrors: action.payload.broadcastTemplateErrors,
        };
      }
      return {
        ...state,
        addTemplateStatus: "error",
        addTemplateId: null,
        addTemplateName: null,
        addTemplateErrors: { _: { _: "An error occurred." } },
      };

    case "BROADCASTS/ADD_TEMPLATE.CLEAR":
      return {
        ...state,
        addTemplateStatus: null,
        addTemplateId: null,
        addTemplateName: null,
        addTemplateErrors: null,
      };

    // Estimate price
    case "BROADCASTS/ESTIMATE_PRICE":
      return {
        ...state,
        estimatePriceStatus: "loading",
      };

    case "BROADCASTS/ESTIMATE_PRICE.SUCCESS":
      return {
        ...state,
        estimatePriceStatus: "success",
        estimatePriceSubscribers: action.payload.subscribers,
        estimatePriceCredits: action.payload.credits,
      };

    case "BROADCASTS/ESTIMATE_PRICE.ERROR":
      return {
        ...state,
        estimatePriceStatus: "error",
        estimatePriceSubscribers: null,
        estimatePriceCredits: null,
      };

    case "BROADCASTS/ESTIMATE_PRICE.CLEAR":
      return {
        ...state,
        estimatePriceStatus: null,
        estimatePriceSubscribers: null,
        estimatePriceCredits: null,
      };

    // Tts price
    case "BROADCASTS/TTS_PRICE":
      return {
        ...state,
        ttsPriceStatus: "loading",
      };

    case "BROADCASTS/TTS_PRICE.SUCCESS":
      return {
        ...state,
        ttsPriceStatus: "success",
        ttsPriceCreditsPerBlock: action.payload.creditsPerBlock,
      };

    case "BROADCASTS/TTS_PRICE.ERROR":
      return {
        ...state,
        ttsPriceStatus: "error",
        ttsPriceCreditsPerBlock: null,
      };

    // Add Broadcast
    case "BROADCASTS/ADD":
      return {
        ...state,
        addStatus: "loading",
        addErrors: null,
        addTemplateStatus: "loading",
        addTemplateErrors: null,
      };

    case "BROADCASTS/ADD.SUCCESS":
    case "BROADCASTS/ADD.ERROR":
      if (action.payload) {
        return {
          ...state,
          addStatus: action.payload.broadcastStatus,
          addId: action.payload.broadcast ? action.payload.broadcast.id : null,
          addErrors: action.payload.broadcastErrors,
          addTemplateStatus: action.payload.broadcastTemplateStatus,
          addTemplateId: action.payload.broadcastTemplate
            ? action.payload.broadcastTemplate.id
            : null,
          addTemplateErrors: action.payload.broadcastTemplateErrors,
        };
      }
      return {
        ...state,
        addStatus: "error",
        addId: null,
        addErrors: { _: { _: "An error occurred." } },
        addTemplateStatus: null,
        addTemplateId: null,
        addTemplateErrors: null,
      };

    case "BROADCASTS/ADD.CLEAR":
      return {
        ...state,
        addStatus: null,
        addId: null,
        addErrors: null,
        addTemplateStatus: null,
        addTemplateId: null,
        addTemplateErrors: null,
      };

    // Edit Broadcast
    case "BROADCASTS/EDIT":
      return {
        ...state,
        editStatus: "loading",
        editId: action.scheduleMessageid,
        editErrors: null,
        addTemplateStatus: "loading",
        addTemplateErrors: null,
      };

    case "BROADCASTS/EDIT.SUCCESS":
    case "BROADCASTS/EDIT.ERROR":
      return {
        ...state,
        editStatus: action.payload.broadcastStatus,
        editId: action.payload.broadcast ? action.payload.broadcast.id : null,
        editErrors: action.payload.broadcastErrors,
        addTemplateStatus: action.payload.broadcastTemplateStatus,
        addTemplateId: action.payload.broadcastTemplate
          ? action.payload.broadcastTemplate.id
          : null,
        addTemplateErrors: action.payload.broadcastTemplateErrors,
      };

    case "BROADCASTS/EDIT.CLEAR":
      return {
        ...state,
        editStatus: null,
        editId: null,
        editErrors: null,
        addTemplateStatus: null,
        addTemplateId: null,
        addTemplateErrors: null,
      };

    // Languages
    case "BROADCASTS/LANGUAGES":
      return {
        ...state,
        languagesStatus: "loading",
      };

    case "BROADCASTS/LANGUAGES.SUCCESS":
      return {
        ...state,
        languagesStatus: "success",
        languages: action.payload.languages,
      };

    case "BROADCASTS/LANGUAGES.ERROR":
      return {
        ...state,
        languagesStatus: "error",
        languages: [],
      };

    // Templates
    case "BROADCASTS/TEMPLATES":
      return {
        ...state,
        templatesStatus: "loading",
      };

    case "BROADCASTS/TEMPLATES.SUCCESS":
      templates = [];
      newTemplates = action.payload.broadcast_templates;
      for (let i = 0; i < newTemplates.length; i += 1) {
        newTemplates[i].created_formatted = formatDateTime6(
          newTemplates[i].created,
          action.userTimeZoneOffset
        );
      }

      if (action.page === 1) {
        templates = newTemplates;
      } else {
        templates = state.templates ? [].concat(state.templates) : [];
        templates = templates.concat(newTemplates);
      }
      return {
        ...state,
        templatesStatus: "success",
        templatesPagination: action.payload.pagination,
        templates: templates,
        templatesPage: action.page,
        templatesLoadedAll: action.payload.broadcast_templates.length === 0,
      };

    case "BROADCASTS/TEMPLATES.ERROR":
      return {
        ...state,
        templatesStatus: "error",
        templates: null,
        templatesPage: null,
        templatesLoadedAll: null,
      };

    // Schedule Message
    case "BROADCASTS/FETCH_SCHEDULE_MESSAGE":
      return {
        ...state,
        scheduleMessageStatus: "loading",
        scheduleMessageId: action.scheduleMessageId,
      };

    case "BROADCASTS/FETCH_SCHEDULE_MESSAGE.SUCCESS":
      return {
        ...state,
        scheduleMessageStatus: "success",
        scheduleMessageId: action.scheduleMessageId,
        scheduleMessageData: action.payload.scheduleMessage,
      };

    case "BROADCASTS/FETCH_SCHEDULE_MESSAGE.ERROR":
      return {
        ...state,
        scheduleMessageStatus: "error",
        scheduleMessageId: action.scheduleMessageId,
        scheduleMessageData: null,
      };

    case "BROADCASTS/FETCH_SCHEDULE_MESSAGE.CLEAR":
      return {
        ...state,
        scheduleMessageStatus: null,
        scheduleMessageId: null,
        scheduleMessageData: null,
      };

    // Templates
    case "BROADCASTS/HISTORY":
      return {
        ...state,
        historyStatus: "loading",
      };

    case "BROADCASTS/HISTORY.SUCCESS":
      templates = [];
      newTemplates = action.payload.broadcasts;
      for (let i = 0; i < newTemplates.length; i += 1) {
        newTemplates[i].send_on_formatted = formatDateTime6(
          newTemplates[i].send_on,
          action.userTimeZoneOffset
        );
      }

      if (action.page === 1) {
        templates = newTemplates;
      } else {
        templates = state.history ? [].concat(state.history) : [];
        templates = templates.concat(newTemplates);
      }
      return {
        ...state,
        historyStatus: "success",
        history: templates,
        historyPage: action.page,
        historyLoadedAll: action.payload.broadcasts.length === 0,
      };

    case "BROADCASTS/HISTORY.ERROR":
      return {
        ...state,
        historyStatus: "error",
        history: null,
        historyPage: null,
        historyLoadedAll: null,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
