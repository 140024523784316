const initialState = {
  sendStatus: null,
  sendError: null,
  sendErrorStatus: null,
  sendErrorData: null,
  sendLogId: null,

  // Send Scheduled Now
  sendScheduledNowStatus: null,
  sendScheduledNowId: null,

  // Stop Scheduled Now
  stopScheduledNowStatus: null,
  stopScheduledNowId: null,

  // Delete message
  deleteMessageStatus: null,
  deleteMessageLogId: null,
  deleteMessageGroupSmsBlastId: null,
  deleteMessageScheduleMessageId: null,
  deleteMessageError: null,
  deleteMessageEnableScheduleStopNow: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_MESSAGE":
      return {
        ...state,
        sendStatus: "loading",
        sendError: null,
        sendErrorStatus: null,
        sendErrorData: null,
        sendLogId: null,
      };
    case "SEND_MESSAGE_SUCCESS":
      return {
        ...state,
        sendStatus: "success",
        sendError: action.payload.message,
        sendErrorStatus: action.payload.status,
        sendErrorData: null,
        sendLogId: action.payload.log_id,
      };
    case "SEND_MESSAGE_ERROR":
      return {
        ...state,
        sendStatus: "error",
        sendError: action.payload
          ? action.payload.message
          : "An error occurred while sending message.",
        sendErrorStatus: action.payload ? action.payload.status : null,
        sendErrorData: {
          restrictedPhrases: action.payload
            ? action.payload.restricted_phrases
            : null,
        },
      };

    // Send Scheduled Now
    case "MESSAGES/SEND_SCHEDULED_NOW":
      return {
        ...state,
        sendScheduledNowStatus: "loading",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/SEND_SCHEDULED_NOW.SUCCESS":
      return {
        ...state,
        sendScheduledNowStatus: "success",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/SEND_SCHEDULED_NOW.ERROR":
      return {
        ...state,
        sendScheduledNowStatus: "error",
        sendScheduledNowId: action.scheduleMessageId,
      };

    // Stop Scheduled Now
    case "MESSAGES/STOP_SCHEDULED_NOW":
      return {
        ...state,
        sendScheduledNowStatus: "loading",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/STOP_SCHEDULED_NOW.SUCCESS":
      return {
        ...state,
        sendScheduledNowStatus: "success",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/STOP_SCHEDULED_NOW.ERROR":
      return {
        ...state,
        sendScheduledNowStatus: "error",
        sendScheduledNowId: action.scheduleMessageId,
      };

    // Delete message
    case "MESSAGES/DELETE":
      return {
        ...state,
        deleteMessageStatus: "loading",
        deleteMessageLogId: action.logId,
        deleteMessageGroupSmsBlastId: action.groupSmsBlastId,
        deleteMessageScheduleMessageId: action.scheduleMessageId,
        deleteMessageError: null,
        deleteMessageEnableScheduleStopNow: false,
        sendError: null,
        sendErrorStatus: null,
        sendErrorData: null,
      };

    case "MESSAGES/DELETE.SUCCESS":
      return {
        ...state,
        deleteMessageStatus: "success",
        deleteMessageLogId: action.logId,
        deleteMessageGroupSmsBlastId: action.groupSmsBlastId,
        deleteMessageScheduleMessageId: action.scheduleMessageId,
        deleteMessageError: null,
        deleteMessageEnableScheduleStopNow: false,
      };

    case "MESSAGES/DELETE.ERROR":
      return {
        ...state,
        deleteMessageStatus: "error",
        deleteMessageLogId: action.logId,
        deleteMessageGroupSmsBlastId: action.groupSmsBlastId,
        deleteMessageScheduleMessageId: action.scheduleMessageId,
        deleteMessageError: action.payload ? action.payload.error : null,
        deleteMessageEnableScheduleStopNow: action.payload
          ? action.payload.enableScheduleStopNow
          : null,
      };

    case "MESSAGES/DELETE.CLEAR":
      return {
        ...state,
        deleteMessageStatus: null,
        deleteMessageLogId: null,
        deleteMessageGroupSmsBlastId: null,
        deleteMessageScheduleMessageId: null,
        deleteMessageError: null,
        deleteMessageEnableScheduleStopNow: false,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
