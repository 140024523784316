import { resetFetchThreadsRequests } from "../actions/threads";

const initialState = {
  filter: null,
  voiceFilter: null,
  scheduledVisible: false,

  // Fetch threads list
  threads: null,
  threadsStatus: null,
  threadsError: null,
  threadsPage: null,
  threadsLoadedAll: null,
  threadsCompanyId: null,
  threadsUpdated: 0,
  threadsOutdated: false,

  // Fetch threads filtered
  threadsFiltered: null,
  threadsFilteredStatus: null,
  threadsFilteredPage: null,
  threadsFilteredLoadedAll: null,
  threadsFilteredUpdated: 0,

  // Fetch threads list
  threadsSpecial: null,
  threadsSpecialStatus: null,
  threadsSpecialPage: null,
  threadsSpecialLoadedAll: null,
  threadsSpecialUpdated: 0,

  // Fetch voice threads
  voiceThreads: null,
  voiceThreadsStatus: null,
  voiceThreadsPage: null,
  voiceThreadsLoadedAll: null,
  voiceThreadsUpdated: null,

  // Fetch voice threads filtered
  voiceThreadsFiltered: null,
  voiceThreadsFilteredStatus: null,
  voiceThreadsFilteredPage: null,
  voiceThreadsFilteredLoadedAll: null,
  voiceThreadsFilteredUpdated: 0,
  voiceThreadsFilteredTotal: null,
  voiceThreadsOutdated: false,

  // Fetch thread items
  threadItems: null,
  threadItemsStatus: null,
  threadItemsError: null,
  threadItemsLoadedAll: null,
  threadItemsCompanyId: null,
  threadItemsThreadType: null,
  threadItemsInterlocutorId: null,
  threadItemsItemKey: null,
  threadItemsOutdated: null,
  threadItemsNewCount: 0,
  threadItemsUpdated: 0,
  threadItemsUpdatedDirection: null,

  // Fetch voice thread items
  voiceThreadItems: null,
  voiceThreadItemsStatus: null,
  voiceThreadItemsLoadedAll: null,
  voiceThreadItemsThreadType: null,
  voiceThreadItemsInterlocutorId: null,
  voiceThreadItemsItemKey: null,
  voiceThreadItemsNewCount: 0,
  voiceThreadItemsUpdated: 0,
  voiceThreadItemsUpdatedDirection: null,

  // Search receivers
  receivers: null,
  receiversStatus: null,
  receiversPhoneCountryCode: null,
  receiversQuery: null,

  // Unread
  unreads: null,
  unreadsStatus: null,
  unreadsTotal: null,
  unreadsPage: null,
  unreadsLoadedAll: null,

  // Undelivered
  undelivereds: null,
  undeliveredsStatus: null,
  undeliveredsTotal: null,

  // Counts
  countsStatus: null,
  undeliveredReadTotal: null,
  receivedTotal: null,
  sentTotal: null,
  campaignsAmTotal: null,
  campaignsAutoRepliesTotal: null,
  campaignsBirthdaysTotal: null,
  campaignsContestsTotal: null,
  campaignsDcTotal: null,
  campaignsPollsTotal: null,
  favoritesTotal: null,
  scheduledContactsTotal: null,
  scheduledGroupsTotal: null,

  // Mark as read
  markAsReadStatus: null,
  markAsReadLogId: null,
  markAllAsReadStatus: null,
  markAllUndeliveredAsReadStatus: null,
  unreadIgnored: false,

  // Mark as unread
  markAsUnreadStatus: null,
  markAsUnreadLogId: null,

  // Items status
  updateItemsStatusStatus: null,
  updateItemsScheduledStatus: null,
};

const isDeletedItem = (item, logId, groupSmsBlastId, scheduleMessageId) => {
  if (logId) {
    if (typeof item.log !== "undefined" && item.log && item.log.id === logId) {
      return true;
    }
  } else if (groupSmsBlastId) {
    if (
      typeof item.group_sms_blast !== "undefined" &&
      item.group_sms_blast &&
      item.group_sms_blast.id === groupSmsBlastId
    ) {
      return true;
    }
  } else if (scheduleMessageId) {
    if (
      typeof item.schedule_message !== "undefined" &&
      item.schedule_message &&
      item.schedule_message.id === scheduleMessageId
    ) {
      return true;
    }
  }
  return false;
};

export default function reducer(state = initialState, action) {
  let threads = null;
  let threadsUpdated = null;
  let threadsFiltered = null;
  let threadsFilteredUpdated = null;
  let threadItems = null;
  let threadItemsOutdated = null;
  let threadItemsUpdated = null;
  let voiceThreads = null;
  let voiceThreadsUpdated = null;
  let voiceThreadsFiltered = null;
  let voiceThreadsFilteredUpdated = null;
  let voiceThreadItems = null;
  let voiceThreadItemsUpdated = null;
  let groupSmsBlastId = null;
  switch (action.type) {
    case "THREADS/FILTER":
      return {
        ...state,
        filter: action.filter,
      };

    case "THREADS/VOICE_FILTER":
      return {
        ...state,
        voiceFilter: action.filter,
      };

    // Fetch threads list
    case "FETCH_THREADS":
      return {
        ...state,
        threadsStatus: "loading",
        threadsError: null,
        threadsOutdated: false,
      };
    case "FETCH_THREADS_SUCCESS":
      threads = action.payload.threads;
      if (state.threads && state.threadsPage + 1 === action.page) {
        let existsKeys = [];
        for (let i = 0; i < threads.length; i++) {
          existsKeys.push(threads[i].item_key);
        }
        let prevThreads = [];
        for (let i = 0; i < state.threads.length; i++) {
          if (existsKeys.indexOf(state.threads[i].item_key) === -1) {
            prevThreads.push(state.threads[i]);
          }
        }
        threads = prevThreads.concat(threads);
      }

      return {
        ...state,
        threads: threads,
        threadsStatus: "success",
        threadsError: null,
        threadsPage: action.page,
        threadsLoadedAll: action.payload.threads.length === 0,
        threadsCompanyId: action.companyId,
        threadsUpdated: state.threadsUpdated + 1,
      };
    case "FETCH_THREADS_ERROR":
      return {
        ...state,
        threads: null,
        threadsStatus: "error",
        threadsError: null,
        threadsPage: null,
        threadsCompanyId: null,
      };
    case "THREADS/FETCH.OUTDATED":
      return {
        ...state,
        threadsOutdated: true,
      };

    // Fetch threads filtered
    case "THREADS/FILTERED":
      return {
        ...state,
        threadsFilteredStatus: "loading",
      };
    case "THREADS/FILTERED.SUCCESS":
      threads = [];
      if (
        state.threadsFiltered &&
        state.threadsFilteredPage + 1 === action.page
      ) {
        threads = state.threadsFiltered.concat(action.payload.threads);
      } else {
        threads = action.payload.threads;
      }
      return {
        ...state,
        threadsFiltered: threads,
        threadsFilteredStatus: "success",
        threadsFilteredPage: action.page,
        threadsFilteredLoadedAll: action.payload.threads.length === 0,
        threadsFilteredUpdated: 0,
      };
    case "THREADS/FILTERED.ERROR":
      return {
        ...state,
        threadsFiltered: null,
        threadsFilteredStatus: "error",
        threadsFilteredPage: null,
        threadsFilteredLoadedAll: null,
        threadsFilteredUpdated: 0,
      };

    // Fetch threads list
    case "THREADS/SPECIAL":
      return {
        ...state,
        threadsSpecial: action.page === 1 ? null : state.threadsSpecial,
        threadsSpecialStatus: "loading",
      };
    case "THREADS/SPECIAL.SUCCESS":
      threads = [];
      if (
        state.threadsSpecial &&
        state.threadsSpecialPage + 1 === action.page
      ) {
        threads = state.threadsSpecial.concat(action.payload.threads);
      } else {
        threads = action.payload.threads;
      }
      return {
        ...state,
        threadsSpecial: threads,
        threadsSpecialStatus: "success",
        threadsSpecialPage: action.page,
        threadsSpecialLoadedAll: action.payload.threads.length === 0,
      };
    case "THREADS/SPECIAL.ERROR":
      return {
        ...state,
        threadsSpecial: null,
        threadsSpecialStatus: "error",
        threadsSpecialPage: null,
      };

    // Fetch threads list
    case "THREADS/VOICE_THREADS":
      return {
        ...state,
        voiceThreadsStatus: "loading",
        voiceThreadsOutdated: false,
      };
    case "THREADS/VOICE_THREADS.SUCCESS":
      threads = [];
      if (state.voiceThreads && state.voiceThreadsPage + 1 === action.page) {
        threads = state.voiceThreads.concat(action.payload.threads);
      } else {
        threads = action.payload.threads;
      }
      return {
        ...state,
        voiceThreads: threads,
        voiceThreadsStatus: "success",
        voiceThreadsPage: action.page,
        voiceThreadsLoadedAll:
          Object.values(action.payload.threads).length === 0,
      };
    case "THREADS/VOICE_THREADS.ERROR":
      return {
        ...state,
        voiceThreads: null,
        voiceThreadsStatus: "error",
        voiceThreadsPage: null,
      };
    case "THREADS/VOICE_THREADS.OUTDATED":
      return {
        ...state,
        voiceThreadsOutdated: true,
      };

    // Fetch temporary voice thread
    case "THREADS/TEMP_VOICE.SUCCESS":
      threads = action.payload.threads;
      let newVoiceThreadsKeys = [];
      for (let i = 0; i < threads.length; i++) {
        threads[i]["__temp"] = true;
        newVoiceThreadsKeys.push(threads[i].item_key);
      }
      if (state.voiceThreads) {
        for (let i = 0; i < state.voiceThreads.length; i++) {
          if (
            newVoiceThreadsKeys.indexOf(state.voiceThreads[i].item_key) === -1
          ) {
            threads.push(state.voiceThreads[i]);
          }
        }
      }

      return {
        ...state,
        voiceThreads: threads,
      };

    // Fetch single voice thread
    case "THREADS/SINGLE_VOICE.SUCCESS":
      voiceThreads = state.voiceThreads ? [].concat(state.voiceThreads) : [];
      let singleVoiceThread = action.payload.threads[0];
      for (let i = 0; i < voiceThreads.length; i++) {
        if (voiceThreads[i].item_key === singleVoiceThread.item_key) {
          voiceThreads[i] = singleVoiceThread;
          break;
        }
      }

      return {
        ...state,
        voiceThreads,
      };

    // Fetch voice threads filtered
    case "THREADS/VOICE_FILTERED":
      return {
        ...state,
        voiceThreadsFilteredStatus: "loading",
      };
    case "THREADS/VOICE_FILTERED.SUCCESS":
      threads = [];
      if (
        state.voiceThreadsFiltered &&
        state.voiceThreadsFilteredPage + 1 === action.page
      ) {
        threads = state.voiceThreadsFiltered.concat(action.payload.threads);
      } else {
        threads = action.payload.threads;
      }
      return {
        ...state,
        voiceThreadsFiltered: threads,
        voiceThreadsFilteredStatus: "success",
        voiceThreadsFilteredPage: action.page,
        voiceThreadsFilteredLoadedAll: action.payload.threads.length === 0,
        voiceThreadsFilteredUpdated: 0,
        voiceThreadsFilteredTotal: action.payload.total,
      };
    case "THREADS/VOICE_FILTERED.ERROR":
      return {
        ...state,
        voiceThreadsFiltered: null,
        voiceThreadsFilteredStatus: "error",
        voiceThreadsFilteredPage: null,
        voiceThreadsFilteredLoadedAll: null,
        voiceThreadsFilteredUpdated: 0,
        voiceThreadsFilteredTotal: null,
      };

    // Fetch incoming thread
    case "FETCH_INCOMING_THREAD_SUCCESS":
      threads = action.payload.threads;
      threadItemsOutdated = state.threadItemsOutdated;
      threadsUpdated = state.threadsUpdated;
      if (state.threads) {
        threads = threads.concat(state.threads);
        for (let i = 1; i < threads.length; i++) {
          if (threads[0].item_key === threads[i].item_key) {
            threads.splice(i, 1);
            threadsUpdated = state.threadsUpdated + 1;
            break;
          }
        }
      }

      threadsFiltered = state.threadsFiltered;
      threadsFilteredUpdated = state.threadsFilteredUpdated;
      if (threadsFiltered) {
        for (let i = 0; i < threadsFiltered.length; i++) {
          if (
            action.payload.threads[0].item_key === threadsFiltered[i].item_key
          ) {
            threadsFiltered[i] = action.payload.threads[0];
            threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
            break;
          }
        }
      }

      threadItems = state.threadItems;
      let threadItemsNewCount = state.threadItemsNewCount;
      if (
        state.threadItemsStatus === "success" &&
        action.companyId === state.threadItemsCompanyId &&
        state.threadItemsItemKey === action.payload.threads[0].item_key
      ) {
        threadItemsOutdated = true;
      }

      return {
        ...state,
        threads,
        threadItemsOutdated,
        threadsUpdated,
        threadsFiltered,
        threadsFilteredUpdated,
        threadItems,
        threadItemsNewCount,
      };

    // Fetch temporary thread
    case "THREADS/TEMP.SUCCESS":
      threads = action.payload.threads;
      let newThreadsKeys = [];
      for (let i = 0; i < threads.length; i++) {
        threads[i]["__temp"] = true;
        newThreadsKeys.push(threads[i].item_key);
      }
      if (state.threads) {
        for (let i = 0; i < state.threads.length; i++) {
          if (newThreadsKeys.indexOf(state.threads[i].item_key) === -1) {
            threads.push(state.threads[i]);
          }
        }
      }

      return {
        ...state,
        threads: threads,
        threadsUpdated: state.threadsUpdated + 1,
      };

    // Fetch single thread
    case "THREADS/SINGLE.SUCCESS":
      threads = state.threads ? [].concat(state.threads) : [];
      let singleThread = action.payload.threads[0];
      for (let i = 0; i < threads.length; i++) {
        if (threads[i].item_key === singleThread.item_key) {
          threads[i] = singleThread;
          break;
        }
      }

      return {
        ...state,
        threads,
        threadsUpdated: state.threadsUpdated + 1,
      };

    case "THREADS_RESET_NEW_ITEMS_COUNT":
      return {
        ...state,
        threadItemsNewCount: 0,
      };

    // Fetch thread items
    case "FETCH_THREAD_ITEMS":
      let threadChanged = false;
      if (
        state.threadItemsCompanyId !== action.companyId ||
        state.threadItemsThreadType !== action.threadType ||
        state.threadItemsInterlocutorId !== action.interlocutorId
      ) {
        threadChanged = true;
      }
      return {
        ...state,
        threadItems: threadChanged ? null : state.threadItems,
        threadItemsStatus: "loading",
        threadItemsError: null,
        threadItemsLoadedAll: false,
        threadItemsCompanyId: action.companyId,
        threadItemsThreadType: action.threadType,
        threadItemsInterlocutorId: action.interlocutorId,
        threadItemsItemKey: action.threadItemKey,
        threadItemsNewCount: threadChanged ? 0 : state.threadItemsNewCount,
      };
    case "FETCH_THREAD_ITEMS_SUCCESS":
      let threadItemsUpdatedDirection = null;
      threadItems = Object.values(action.payload.items);

      // Count new incoming items - only if loading new items
      threadItemsNewCount = state.threadItemsNewCount;
      if (action.fromExcId) {
        for (let i = 0; i < threadItems.length; i++) {
          if (
            threadItems[i].log &&
            !threadItems[i].schedule_message &&
            threadItems[i].log.route === "inbox"
          ) {
            threadItemsNewCount++;
          }
        }
      }

      // Merge items
      if (state.threadItems && action.toExcId > 0) {
        threadItemsUpdatedDirection = "begin";
        threadItems = threadItems.concat(state.threadItems);
      } else if (state.threadItems && action.fromExcId > 0) {
        threadItemsUpdatedDirection = "end";
        threadItems = state.threadItems.concat(threadItems);
      }

      return {
        ...state,
        threadItems,
        threadItemsUpdatedDirection,
        threadItemsStatus: "success",
        threadItemsError: null,
        threadItemsLoadedAll: Object.values(action.payload.items).length === 0,
        threadItemsCompanyId: action.companyId,
        threadItemsThreadType: action.threadType,
        threadItemsInterlocutorId: action.interlocutorId,
        threadItemsOutdated:
          action.fromExcId > 0 ? false : state.threadItemsOutdated,
        threadItemsNewCount,
      };
    case "FETCH_THREAD_ITEMS_ERROR":
      return {
        ...state,
        threadItems: null,
        threadItemsStatus: "error",
        threadItemsError: null,
        threadItemsLoadedAll: false,
        threadItemsCompanyId: action.companyId,
        threadItemsThreadType: action.threadType,
        threadItemsInterlocutorId: action.interlocutorId,
      };

    // Fetch voice thread items
    case "THREAD/VOICE_ITEMS":
      let voiceThreadChanged = false;
      if (
        state.voiceThreadItemsThreadType !== action.threadType ||
        state.voiceThreadItemsInterlocutorId !== action.interlocutorId
      ) {
        voiceThreadChanged = true;
      }
      return {
        ...state,
        voiceThreadItems: voiceThreadChanged ? null : state.voiceThreadItems,
        voiceThreadItemsStatus: "loading",
        voiceThreadItemsLoadedAll: false,
        voiceThreadItemsThreadType: action.threadType,
        voiceThreadItemsInterlocutorId: action.interlocutorId,
        voiceThreadItemsItemKey: action.threadItemKey,
        voiceThreadItemsNewCount: voiceThreadChanged
          ? 0
          : state.voiceThreadItemsNewCount,
      };
    case "THREAD/VOICE_ITEMS.SUCCESS":
      let voiceThreadItemsUpdatedDirection = null;
      threadItems = Object.values(action.payload.items);
      if (state.voiceThreadItems && action.toExcId > 0) {
        voiceThreadItemsUpdatedDirection = "begin";
        threadItems = threadItems.concat(state.voiceThreadItems);
      } else if (state.voiceThreadItems && action.fromExcId > 0) {
        voiceThreadItemsUpdatedDirection = "end";
        threadItems = state.voiceThreadItems.concat(threadItems);
      }

      return {
        ...state,
        voiceThreadItems: threadItems,
        voiceThreadItemsStatus: "success",
        voiceThreadItemsLoadedAll:
          Object.values(action.payload.items).length === 0,
        voiceThreadItemsThreadType: action.threadType,
        voiceThreadItemsInterlocutorId: action.interlocutorId,
        voiceThreadItemsUpdatedDirection,
      };
    case "THREAD/VOICE_ITEMS.ERROR":
      return {
        ...state,
        voiceThreadItems: null,
        voiceThreadItemsStatus: "error",
        voiceThreadItemsLoadedAll: false,
        voiceThreadItemsThreadType: action.threadType,
        voiceThreadItemsInterlocutorId: action.interlocutorId,
      };

    // Search receivers
    case "THREADS_SEARCH_RECEIVERS":
      return {
        ...state,
        receiversStatus: "loading",
      };
    case "THREADS_SEARCH_RECEIVERS_SUCCESS":
      return {
        ...state,
        receivers: action.payload.items,
        receiversStatus: "success",
        receiversPhoneCountryId: action.phoneCountryId,
        receiversQuery: action.query,
      };
    case "THREADS_SEARCH_RECEIVERS_ERROR":
      return {
        ...state,
        receivers: null,
        receiversStatus: "error",
        receiversPhoneCountryId: action.phoneCountryId,
        receiversQuery: action.query,
      };
    case "THREADS/SEARCH_RECEIVERS.CLEAR":
      return {
        ...state,
        receivers: null,
        receiversStatus: null,
        receiversPhoneCountryId: null,
        receiversQuery: null,
      };

    // Unread
    case "THREADS/UNREAD":
      return {
        ...state,
        unreadsStatus: "loading",
      };
    case "THREADS/UNREAD.SUCCESS":
      threads = [];
      if (state.unreads && state.unreadsPage + 1 === action.payload.page) {
        threads = state.unreads.concat(Object.values(action.payload.threads));
      } else {
        threads = Object.values(action.payload.threads);
      }
      return {
        ...state,
        unreads: threads,
        unreadsTotal: action.payload.total,
        unreadsStatus: "success",
        unreadsPage: action.payload.page,
        unreadsLoadedAll: Object.values(action.payload.threads).length === 0,
      };
    case "THREADS/UNREAD.ERROR":
      return {
        ...state,
        unreads: null,
        unreadsTotal: null,
        unreadsStatus: "error",
        unreadsPage: null,
        unreadsLoadedAll: null,
      };

    // Undelivered
    case "THREADS/UNDELIVERED":
      return {
        ...state,
        undeliveredsStatus: "loading",
      };
    case "THREADS/UNDELIVERED.SUCCESS":
      return {
        ...state,
        undelivereds: Object.values(action.payload.threads),
        undeliveredsTotal: action.payload.total,
        undeliveredsStatus: "success",
      };
    case "THREADS/UNDELIVERED.ERROR":
      return {
        ...state,
        undelivereds: null,
        undeliveredsTotal: null,
        undeliveredsStatus: "error",
      };

    // Unread and undelivered counts
    case "THREADS/COUNTS":
      return {
        ...state,
        countsStatus: null,
      };
    case "THREADS/COUNTS.SUCCESS":
      return {
        ...state,
        countsStatus: "success",
        unreads: null,
        unreadsStatus: null,
        unreadsTotal: action.payload.unread || state.unreadsTotal,
        undelivereds: null,
        undeliveredsStatus: null,
        undeliveredsTotal:
          action.payload.undelivered || state.undeliveredsTotal,
        undeliveredReadTotal:
          action.payload.undeliveredRead || state.undeliveredReadTotal,
        receivedTotal: action.payload.received || state.receivedTotal,
        sentTotal: action.payload.sent || state.sentTotal,
        campaignsAmTotal: action.payload.campaignsAm || state.campaignsAmTotal,
        campaignsAutoRepliesTotal:
          action.payload.campaignsAutoReplies ||
          state.campaignsAutoRepliesTotal,
        campaignsBirthdaysTotal:
          action.payload.campaignsBirthdays || state.campaignsBirthdaysTotal,
        campaignsContestsTotal:
          action.payload.campaignsContests || state.campaignsContestsTotal,
        campaignsDcTotal: action.payload.campaignsDc || state.campaignsDcTotal,
        campaignsPollsTotal:
          action.payload.campaignsPolls || state.campaignsPollsTotal,
        favoritesTotal: action.payload.favorites || state.favoritesTotal,
        scheduledContactsTotal:
          action.payload.scheduledContacts || state.scheduledContactsTotal,
        scheduledGroupsTotal:
          action.payload.scheduledGroups || state.scheduledGroupsTotal,
      };

    // Mark as read
    case "THREADS/MARK_AS_READ":
      return {
        ...state,
        markAsReadStatus: "loading",
        markAsReadLogId: action.logId,
      };
    case "THREADS/MARK_ALL_AS_READ":
      return {
        ...state,
        markAllAsReadStatus: "loading",
      };
    case "THREADS/MARK_AS_READ.SUCCESS":
    case "THREADS/MARK_ALL_AS_READ.SUCCESS":
      threads = state.threads;
      threadsUpdated = state.threadsUpdated;
      if (action.payload.logsIds.length && threads) {
        for (let i = 0; i < threads.length; i++) {
          if (
            threads[i].log &&
            action.payload.logsIds.indexOf(threads[i].log.id) !== -1
          ) {
            threads[i].log.read = 1;
            threadsUpdated = state.threadsUpdated + 1;
          }
          if (
            typeof action.payload.threadCounts[threads[i].item_key] !==
            "undefined"
          ) {
            threads[i]["undelivered_count"] =
              action.payload.threadCounts[
                threads[i].item_key
              ].undelivered_count;
            threads[i]["unread_count"] =
              action.payload.threadCounts[threads[i].item_key].unread_count;
            threadsUpdated = state.threadsUpdated + 1;
          }
        }
      }

      threadsFiltered = state.threadsFiltered;
      threadsFilteredUpdated = state.threadsFilteredUpdated;
      if (action.payload.logsIds.length && threadsFiltered) {
        for (let i = 0; i < threadsFiltered.length; i++) {
          if (
            threadsFiltered[i].log &&
            action.payload.logsIds.indexOf(threadsFiltered[i].log.id) !== -1
          ) {
            threadsFiltered[i].log.read = 1;
            threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
          }
          if (
            typeof action.payload.threadCounts[threadsFiltered[i].item_key] !==
            "undefined"
          ) {
            threadsFiltered[i]["undelivered_count"] =
              action.payload.threadCounts[
                threadsFiltered[i].item_key
              ].undelivered_count;
            threadsFiltered[i]["unread_count"] =
              action.payload.threadCounts[
                threadsFiltered[i].item_key
              ].unread_count;
            threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
          }
        }
      }

      threadItems = state.threadItems;
      threadItemsUpdated = state.threadItemsUpdated;
      if (
        state.threadItemsThreadType === "contact" &&
        action.payload.logsIds.length &&
        threadItems
      ) {
        for (let i = 0; i < threadItems.length; i++) {
          if (
            threadItems[i].log &&
            action.payload.logsIds.indexOf(threadItems[i].log.id) !== -1
          ) {
            threadItems[i].log.read = 1;
            threadItemsUpdated = state.threadItemsUpdated + 1;
          }
        }
      }

      voiceThreads = state.voiceThreads;
      voiceThreadsUpdated = state.voiceThreadsUpdated;
      if (action.payload.logsIds.length && voiceThreads) {
        for (let i = 0; i < voiceThreads.length; i++) {
          if (
            voiceThreads[i].log &&
            action.payload.logsIds.indexOf(voiceThreads[i].log.id) !== -1
          ) {
            voiceThreads[i].log.read = 1;
            voiceThreadsUpdated = state.voiceThreadsUpdated + 1;
          }
          if (
            typeof action.payload.threadCounts[voiceThreads[i].item_key] !==
            "undefined"
          ) {
            voiceThreads[i]["undelivered_count"] =
              action.payload.threadCounts[
                voiceThreads[i].item_key
              ].undelivered_count;
            voiceThreads[i]["unread_count"] =
              action.payload.threadCounts[
                voiceThreads[i].item_key
              ].unread_count;
            voiceThreadsUpdated = state.voiceThreadsUpdated + 1;
          }
        }
      }

      voiceThreadsFiltered = state.voiceThreadsFiltered;
      voiceThreadsFilteredUpdated = state.voiceThreadsFilteredUpdated;
      if (action.payload.logsIds.length && voiceThreadsFiltered) {
        for (let i = 0; i < voiceThreadsFiltered.length; i++) {
          if (
            voiceThreadsFiltered[i].log &&
            action.payload.logsIds.indexOf(voiceThreadsFiltered[i].log.id) !==
              -1
          ) {
            voiceThreadsFiltered[i].log.read = 1;
            voiceThreadsFilteredUpdated = state.threadsFilteredUpdated + 1;
          }
          if (
            typeof action.payload.threadCounts[
              voiceThreadsFiltered[i].item_key
            ] !== "undefined"
          ) {
            voiceThreadsFiltered[i]["undelivered_count"] =
              action.payload.threadCounts[
                voiceThreadsFiltered[i].item_key
              ].undelivered_count;
            voiceThreadsFiltered[i]["unread_count"] =
              action.payload.threadCounts[
                voiceThreadsFiltered[i].item_key
              ].unread_count;
            voiceThreadsFilteredUpdated = state.threadsFilteredUpdated + 1;
          }
        }
      }

      voiceThreadItems = state.voiceThreadItems;
      voiceThreadItemsUpdated = state.voiceThreadItemsUpdated;
      if (
        state.voiceThreadItemsThreadType === "contact" &&
        action.payload.logsIds.length &&
        voiceThreadItems
      ) {
        for (let i = 0; i < voiceThreadItems.length; i++) {
          if (
            voiceThreadItems[i].log &&
            action.payload.logsIds.indexOf(voiceThreadItems[i].log.id) !== -1
          ) {
            voiceThreadItems[i].log.read = 1;
            voiceThreadItemsUpdated = state.voiceThreadItemsUpdated + 1;
          }
        }
      }

      return {
        ...state,
        threads: threads,
        threadsUpdated: threadsUpdated,
        threadsFiltered: threadsFiltered,
        threadsFilteredUpdated: threadsFilteredUpdated,
        threadItems: threadItems,
        threadItemsUpdated: threadItemsUpdated,
        voiceThreads: voiceThreads,
        voiceThreadsUpdated: voiceThreadsUpdated,
        voiceThreadsFiltered: voiceThreadsFiltered,
        voiceThreadsFilteredUpdated: voiceThreadsFilteredUpdated,
        voiceThreadItems: voiceThreadItems,
        voiceThreadItemsUpdated: voiceThreadItemsUpdated,
        unreads: null,
        unreadsStatus: null,
        unreadsTotal: action.payload.unread,
        markAsReadStatus:
          action.type === "THREADS/MARK_AS_READ.SUCCESS"
            ? "success"
            : state.markAsReadStatus,
        markAsReadLogId:
          action.type === "THREADS/MARK_AS_READ.SUCCESS"
            ? null
            : state.markAsReadLogId,
        markAllAsReadStatus:
          action.type === "THREADS/MARK_ALL_AS_READ.SUCCESS"
            ? "success"
            : state.markAllAsReadStatus,
        //unreadIgnored: action.type === 'THREADS/MARK_ALL_AS_READ.SUCCESS' ? true : state.unreadIgnored,
        undelivereds: null,
        undeliveredsStatus: null,
        undeliveredsTotal: action.payload.undelivered,
      };
    case "THREADS/MARK_AS_READ.ERROR":
      return {
        ...state,
        markAsReadStatus: "error",
      };

    // Mark as unread
    case "THREADS/MARK_AS_UNREAD":
      return {
        ...state,
        markAsUnreadStatus: "loading",
        markAsUnreadLogId: action.logId,
      };
    case "THREADS/MARK_AS_UNREAD.SUCCESS":
      threads = state.threads;
      threadsUpdated = state.threadsUpdated;
      threadsFiltered = state.threadsFiltered;
      threadsFilteredUpdated = state.threadsFilteredUpdated;
      if (action.payload.logsIds.length) {
        if (threads) {
          for (let i = 0; i < threads.length; i++) {
            if (
              threads[i].log &&
              action.payload.logsIds.indexOf(threads[i].log.id) !== -1
            ) {
              threads[i].log.read = 0;
              threadsUpdated = state.threadsUpdated + 1;
            }
            if (
              typeof action.payload.threadCounts[threads[i].item_key] !==
              "undefined"
            ) {
              threads[i]["undelivered_count"] =
                action.payload.threadCounts[
                  threads[i].item_key
                ].undelivered_count;
              threads[i]["unread_count"] =
                action.payload.threadCounts[threads[i].item_key].unread_count;
              threadsUpdated = state.threadsUpdated + 1;
            }
          }
        }
        if (threadsFiltered) {
          for (let i = 0; i < threadsFiltered.length; i++) {
            if (
              threadsFiltered[i].log &&
              action.payload.logsIds.indexOf(threadsFiltered[i].log.id) !== -1
            ) {
              threadsFiltered[i].log.read = 0;
              threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
            }
            if (
              typeof action.payload.threadCounts[
                threadsFiltered[i].item_key
              ] !== "undefined"
            ) {
              threadsFiltered[i]["undelivered_count"] =
                action.payload.threadCounts[
                  threadsFiltered[i].item_key
                ].undelivered_count;
              threadsFiltered[i]["unread_count"] =
                action.payload.threadCounts[
                  threadsFiltered[i].item_key
                ].unread_count;
              threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
            }
          }
        }
      }

      threadItems = state.threadItems;
      threadItemsUpdated = state.threadItemsUpdated;
      if (
        state.threadItemsThreadType === "contact" &&
        action.payload.logsIds.length &&
        threadItems
      ) {
        for (let i = 0; i < threadItems.length; i++) {
          if (
            threadItems[i].log &&
            action.payload.logsIds.indexOf(threadItems[i].log.id) !== -1
          ) {
            threadItems[i].log.read = 0;
            threadItemsUpdated = state.threadItemsUpdated + 1;
          }
        }
      }

      voiceThreads = state.voiceThreads;
      voiceThreadsUpdated = state.voiceThreadsUpdated;
      voiceThreadsFiltered = state.voiceThreadsFiltered;
      voiceThreadsFilteredUpdated = state.voiceThreadsFilteredUpdated;
      if (action.payload.logsIds.length) {
        if (voiceThreads) {
          for (let i = 0; i < voiceThreads.length; i++) {
            if (
              voiceThreads[i].log &&
              action.payload.logsIds.indexOf(voiceThreads[i].log.id) !== -1
            ) {
              voiceThreads[i].log.read = 0;
              voiceThreadsUpdated = state.voiceThreadsUpdated + 1;
            }
            if (
              typeof action.payload.threadCounts[voiceThreads[i].item_key] !==
              "undefined"
            ) {
              voiceThreads[i]["undelivered_count"] =
                action.payload.threadCounts[
                  voiceThreads[i].item_key
                ].undelivered_count;
              voiceThreads[i]["unread_count"] =
                action.payload.threadCounts[
                  voiceThreads[i].item_key
                ].unread_count;
              voiceThreadsUpdated = state.voiceThreadsUpdated + 1;
            }
          }
        }
        if (voiceThreadsFiltered) {
          for (let i = 0; i < voiceThreadsFiltered.length; i++) {
            if (
              voiceThreadsFiltered[i].log &&
              action.payload.logsIds.indexOf(voiceThreadsFiltered[i].log.id) !==
                -1
            ) {
              voiceThreadsFiltered[i].log.read = 0;
              voiceThreadsFilteredUpdated =
                state.voiceThreadsFilteredUpdated + 1;
            }
            if (
              typeof action.payload.threadCounts[
                voiceThreadsFiltered[i].item_key
              ] !== "undefined"
            ) {
              voiceThreadsFiltered[i]["undelivered_count"] =
                action.payload.threadCounts[
                  voiceThreadsFiltered[i].item_key
                ].undelivered_count;
              voiceThreadsFiltered[i]["unread_count"] =
                action.payload.threadCounts[
                  voiceThreadsFiltered[i].item_key
                ].unread_count;
              voiceThreadsFilteredUpdated =
                state.voiceThreadsFilteredUpdated + 1;
            }
          }
        }
      }

      voiceThreadItems = state.voiceThreadItems;
      voiceThreadItemsUpdated = state.voiceThreadItemsUpdated;
      if (
        state.voiceThreadItemsThreadType === "contact" &&
        action.payload.logsIds.length &&
        voiceThreadItems
      ) {
        for (let i = 0; i < voiceThreadItems.length; i++) {
          if (
            voiceThreadItems[i].log &&
            action.payload.logsIds.indexOf(voiceThreadItems[i].log.id) !== -1
          ) {
            voiceThreadItems[i].log.read = 0;
            voiceThreadItemsUpdated = state.voiceThreadItemsUpdated + 1;
          }
        }
      }

      return {
        ...state,
        threads: threads,
        threadsUpdated: threadsUpdated,
        threadsFiltered: threadsFiltered,
        threadsFilteredUpdated: threadsFilteredUpdated,
        threadItems: threadItems,
        threadItemsUpdated: threadItemsUpdated,
        voiceThreads: voiceThreads,
        voiceThreadsUpdated: voiceThreadsUpdated,
        voiceThreadsFiltered: voiceThreadsFiltered,
        voiceThreadsFilteredUpdated: voiceThreadsFilteredUpdated,
        voiceThreadItems: voiceThreadItems,
        voiceThreadItemsUpdated: voiceThreadItemsUpdated,
        unreads: null,
        unreadsStatus: null,
        unreadsTotal: action.payload.unread,
        markAsUnreadStatus:
          action.type === "THREADS/MARK_AS_UNREAD.SUCCESS"
            ? "success"
            : state.markAsUnreadStatus,
        markAsUnreadLogId:
          action.type === "THREADS/MARK_AS_UNREAD.SUCCESS"
            ? null
            : state.markAsUnreadLogId,
      };
    case "THREADS/MARK_AS_UNREAD.ERROR":
      return {
        ...state,
        markAsUnreadStatus: "error",
      };

    // Mark all undelivered as read
    case "THREADS/MARK_ALL_UNDELIVERED_AS_READ":
      return {
        ...state,
        markAllUndeliveredAsReadStatus: "loading",
      };
    case "THREADS/MARK_ALL_UNDELIVERED_AS_READ.SUCCESS":
      threads = state.threads;
      threadsUpdated = state.threadsUpdated;
      if (action.payload.logsIds.length && threads) {
        for (let i = 0; i < threads.length; i++) {
          if (
            threads[i].log &&
            action.payload.logsIds.indexOf(threads[i].log.id) !== -1
          ) {
            threads[i].log.read_failed = 1;
            threadsUpdated = state.threadsUpdated + 1;
          }
          if (
            typeof action.payload.threadCounts[threads[i].item_key] !==
            "undefined"
          ) {
            threads[i]["undelivered_count"] =
              action.payload.threadCounts[
                threads[i].item_key
              ].undelivered_count;
            threads[i]["unread_count"] =
              action.payload.threadCounts[threads[i].item_key].unread_count;
            threadsUpdated = state.threadsUpdated + 1;
          }
        }
      }

      threadsFiltered = state.threadsFiltered;
      threadsFilteredUpdated = state.threadsFilteredUpdated;
      if (action.payload.logsIds.length && threadsFiltered) {
        for (let i = 0; i < threadsFiltered.length; i++) {
          if (
            threadsFiltered[i].log &&
            action.payload.logsIds.indexOf(threadsFiltered[i].log.id) !== -1
          ) {
            threadsFiltered[i].log.read_failed = 1;
            threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
          }
          if (
            typeof action.payload.threadCounts[threadsFiltered[i].item_key] !==
            "undefined"
          ) {
            threadsFiltered[i]["undelivered_count"] =
              action.payload.threadCounts[
                threadsFiltered[i].item_key
              ].undelivered_count;
            threadsFiltered[i]["unread_count"] =
              action.payload.threadCounts[
                threadsFiltered[i].item_key
              ].unread_count;
            threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
          }
        }
      }

      threadItems = state.threadItems;
      threadItemsUpdated = state.threadItemsUpdated;
      if (action.payload.logsIds.length && threadItems) {
        for (let i = 0; i < threadItems.length; i++) {
          if (
            threadItems[i].log &&
            action.payload.logsIds.indexOf(threadItems[i].log.id) !== -1
          ) {
            threadItems[i].log.read_failed = 1;
            threadItemsUpdated = state.threadItemsUpdated + 1;
          }
        }
      }

      return {
        ...state,
        threads: threads,
        threadsUpdated: threadsUpdated,
        threadsFiltered: threadsFiltered,
        threadsFilteredUpdated: threadsFilteredUpdated,
        threadItems: threadItems,
        threadItemsUpdated: threadItemsUpdated,
        undelivereds: null,
        undeliveredsStatus: null,
        undeliveredsTotal: action.payload.undelivered,
        markAllUndeliveredAsReadStatus: "success",
      };

    case "THREADS/IGNORE_UNREAD":
      return {
        ...state,
        unreadIgnored: true,
      };

    case "THREADS/UNIGNORE_UNREAD":
      return {
        ...state,
        unreadIgnored: false,
      };

    case "CONTACTS/EDIT_FIELD.SUCCESS":
      threads = state.threads;
      threadsUpdated = state.threadsUpdated;
      threadItems = state.threadItems;
      threadItemsUpdated = state.threadItemsUpdated;

      let editedFields = Object.keys(action.contactData);

      if (threads) {
        for (let i = 0; i < threads.length; i++) {
          if (
            threads[i].contact &&
            threads[i].contact.id === action.contactId
          ) {
            for (let j = 0; j < editedFields.length; j++) {
              if (typeof threads[i].contact[editedFields[j]] !== "undefined") {
                threads[i].contact[editedFields[j]] =
                  action.contactData[editedFields[j]];
                threadsUpdated = state.threadsUpdated + 1;
              }
            }
          }
        }
      }

      if (threadItems) {
        for (let i = 0; i < threadItems.length; i++) {
          if (
            threadItems[i].contact &&
            threadItems[i].contact.id === action.contactId
          ) {
            for (let j = 0; j < editedFields.length; j++) {
              if (
                typeof threadItems[i].contact[editedFields[j]] !== "undefined"
              ) {
                threadItems[i].contact[editedFields[j]] =
                  action.contactData[editedFields[j]];
                threadItemsUpdated = state.threadItemsUpdated + 1;
              }
            }
          }
        }
      }

      return {
        ...state,
        threads: threads,
        threadsUpdated: threadsUpdated,
        threadItems: threadItems,
        threadItemsUpdated: threadItemsUpdated,
      };

    case "SEND_MESSAGE_SUCCESS":
      return {
        ...state,
        undelivereds: null,
        undeliveredsStatus: null,
        undeliveredsTotal: action.payload.undelivered,
      };

    case "NUMBERS/FILTER":
      return initialState;

    case "THREADS/UPDATE_ITEMS_STATUS":
      return {
        ...state,
        updateItemsStatusStatus: "loading",
      };
    case "THREADS/UPDATE_ITEMS_STATUS.SUCCESS":
      const newStatuses = action.payload.scheduledMessageStatus;
      threadItems = state.threadItems;
      threadItemsUpdated = state.threadItemsUpdated;
      if (threadItems) {
        for (let i = threadItems.length - 1; i >= 0; i--) {
          if (
            !threadItems[i].schedule_message ||
            typeof newStatuses["" + threadItems[i].schedule_message.id] ===
              "undefined"
          ) {
            continue;
          }
          threadItems[i].schedule_message.is_processing =
            newStatuses["" + threadItems[i].schedule_message.id].is_processing;
          threadItems[i].schedule_message.sent =
            newStatuses["" + threadItems[i].schedule_message.id].sent;
          threadItemsUpdated = state.threadItemsUpdated + 1;
        }
      }

      return {
        ...state,
        updateItemsStatusStatus: "success",
        updateItemsScheduledStatus: action.payload.scheduledMessageStatus,
        threadItems: threadItems,
        threadItemsUpdated: threadItemsUpdated,
      };
    case "THREADS/UPDATE_ITEMS_STATUS.ERROR":
      return {
        ...state,
        updateItemsStatusStatus: "error",
        updateItemsScheduledStatus: null,
      };

    case "THREADS/RELOAD_ITEM":
    case "THREADS/RELOAD_ITEM.SUCCESS":
    case "THREADS/RELOAD_ITEM.ERROR":
      ({ groupSmsBlastId } = action);
      ({ threadItems, threadItemsUpdated } = state);
      if (threadItems) {
        for (let i = threadItems.length - 1; i >= 0; i--) {
          if (
            threadItems[i].group_sms_blast &&
            threadItems[i].group_sms_blast.id === groupSmsBlastId
          ) {
            threadItems = [...threadItems];

            if (action.type === "THREADS/RELOAD_ITEM") {
              threadItems[i].reloadStatus = "loading";
            } else if (action.type === "THREADS/RELOAD_ITEM.SUCCESS") {
              threadItems[i] = {
                ...threadItems[i],
                ...action.payload.item,
              };
              threadItems[i].reloadStatus = "success";
            } else if (action.type === "THREADS/RELOAD_ITEM.ERROR") {
              threadItems[i].reloadStatus = "error";
            }

            threadItemsUpdated += 1;
            break;
          }
        }
      }
      return {
        ...state,
        threadItems,
        threadItemsUpdated,
      };

    // Reset lists after delete contacts
    case "CONTACTS/BATCH_DELETE.SUCCESS":
    case "CONTACTS/DELETE.SUCCESS":
      return {
        ...state,
        // Fetch threads list
        threads: null,
        threadsStatus: null,
        threadsError: null,
        threadsPage: null,
        threadsLoadedAll: null,
        threadsCompanyId: null,
        threadsUpdated: 0,

        // Fetch threads filtered
        threadsFiltered: null,
        threadsFilteredStatus: null,
        threadsFilteredPage: null,
        threadsFilteredLoadedAll: null,
        threadsFilteredUpdated: 0,

        // Fetch threads list
        threadsSpecial: null,
        threadsSpecialStatus: null,
        threadsSpecialPage: null,
        threadsSpecialLoadedAll: null,
        threadsSpecialUpdated: 0,

        // Fetch voice threads
        voiceThreads: null,
        voiceThreadsStatus: null,
        voiceThreadsPage: null,
        voiceThreadsLoadedAll: null,
        voiceThreadsUpdated: null,

        // Fetch voice threads filtered
        voiceThreadsFiltered: null,
        voiceThreadsFilteredStatus: null,
        voiceThreadsFilteredPage: null,
        voiceThreadsFilteredLoadedAll: null,
        voiceThreadsFilteredUpdated: 0,
        voiceThreadsFilteredTotal: null,
      };

    case "THREADS/SCHEDULED_VISIBLE":
      return {
        ...state,
        scheduledVisible: action.visible,
      };

    case "THREADS/CLEAR_ITEMS":
      // Intended not changed object to prevent updates
      let newState = state;

      if (
        state.threadItemsThreadType === action.threadType &&
        state.threadItemsInterlocutorId === action.interlocutorId
      ) {
        newState = {
          ...newState,
          threadItems: null,
          threadItemsStatus: null,
          threadItemsError: null,
          threadItemsLoadedAll: null,
          threadItemsCompanyId: null,
          threadItemsThreadType: null,
          threadItemsInterlocutorId: null,
          threadItemsItemKey: null,
          threadItemsOutdated: null,
          threadItemsNewCount: 0,
          threadItemsUpdated: 0,
          threadItemsUpdatedDirection: null,
        };
      }
      if (
        state.voiceThreadItemsThreadType === action.threadType &&
        state.voiceThreadItemsInterlocutorId === action.interlocutorId
      ) {
        newState = {
          ...newState,
          voiceThreadItems: null,
          voiceThreadItemsStatus: null,
          voiceThreadItemsLoadedAll: null,
          voiceThreadItemsThreadType: null,
          voiceThreadItemsInterlocutorId: null,
          voiceThreadItemsItemKey: null,
          voiceThreadItemsNewCount: 0,
          voiceThreadItemsUpdated: 0,
          voiceThreadItemsUpdatedDirection: null,
        };
      }
      return newState;

    // Delete message
    case "MESSAGES/DELETE":
    case "MESSAGES/DELETE.SUCCESS":
    case "MESSAGES/DELETE.ERROR":
      threadItems = state.threadItems;
      voiceThreadItems = state.voiceThreadItems;

      if (threadItems) {
        for (let i = 0; i < threadItems.length; i += 1) {
          if (
            isDeletedItem(
              threadItems[i],
              action.logId,
              action.groupSmsBlastId,
              action.scheduleMessageId
            )
          ) {
            threadItems = [].concat(threadItems);
            if (action.type === "MESSAGES/DELETE") {
              threadItems[i].deleting = true;
            } else if (action.type === "MESSAGES/DELETE.SUCCESS") {
              threadItems[i].deleting = false;
              threadItems[i].deleted = true;
            } else if (action.type === "MESSAGES/DELETE.ERROR") {
              threadItems[i].deleting = false;
              threadItems[i].deletingError = true;
            }
            break;
          }
        }
      }

      if (voiceThreadItems) {
        for (let i = 0; i < voiceThreadItems.length; i += 1) {
          if (
            isDeletedItem(
              voiceThreadItems[i],
              action.logId,
              action.groupSmsBlastId,
              action.scheduleMessageId
            )
          ) {
            voiceThreadItems = [].concat(voiceThreadItems);
            if (action.type === "MESSAGES/DELETE") {
              voiceThreadItems[i].deleting = true;
            } else if (action.type === "MESSAGES/DELETE.SUCCESS") {
              voiceThreadItems[i].deleting = false;
              voiceThreadItems[i].deleted = true;
            } else if (action.type === "MESSAGES/DELETE.ERROR") {
              voiceThreadItems[i].deleting = false;
              voiceThreadItems[i].deletingError = true;
            }
            break;
          }
        }
      }

      return {
        ...state,
        threadItems,
        voiceThreadItems,
      };

    case "THREADS/REFRESH_GROUP_MSG_STATS":
    case "THREADS/REFRESH_GROUP_MSG_STATS.SUCCESS":
    case "THREADS/REFRESH_GROUP_MSG_STATS.ERROR":
      ({ groupSmsBlastId } = action);
      ({ threadItems, threadItemsUpdated } = state);
      if (threadItems) {
        for (let i = threadItems.length - 1; i >= 0; i--) {
          if (
            threadItems[i].group_sms_blast &&
            threadItems[i].group_sms_blast.id === groupSmsBlastId
          ) {
            threadItems = [...threadItems];

            if (action.type === "THREADS/REFRESH_GROUP_MSG_STATS") {
              threadItems[i].refreshGroupMsgStatsStatus = "loading";
            } else if (
              action.type === "THREADS/REFRESH_GROUP_MSG_STATS.SUCCESS"
            ) {
              threadItems[i] = {
                ...threadItems[i],
                ...action.payload.item,
              };
              threadItems[i].refreshGroupMsgStatsStatus = "success";
            } else if (
              action.type === "THREADS/REFRESH_GROUP_MSG_STATS.ERROR"
            ) {
              threadItems[i].refreshGroupMsgStatsStatus = "error";
            }

            threadItemsUpdated += 1;
            break;
          }
        }
      }
      return {
        ...state,
        threadItems,
        threadItemsUpdated,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      resetFetchThreadsRequests();
      return initialState;

    default:
      return state;
  }
}
