import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import {
  timeAgo,
  contactsName,
  formatPhoneNumber,
  formatDuration,
  isCordovaApp,
} from "../../helpers";
import {
  tooltipProps,
  tooltipPropsAuto,
  getDuplicateViewInfo,
} from "../../helpers";

import { ReactComponent as FavoriteFilledIconSvg } from "../../assets/img/icons/favorite-filled-icon.svg";
import { ReactComponent as MessageSingleFilledSvg } from "../../assets/img/icons/message-single-filled.svg";
import { ReactComponent as PeopleGroupSvg } from "../../assets/img/icons/people_group.svg";
import { ReactComponent as BroadcastIconSvg } from "../../assets/img/icons/broadcast-icon.svg";
import { ReactComponent as BlockedIcon } from "../../assets/img/icons/group-status-1.svg";
import { ReactComponent as UnsubscribedIcon } from "../../assets/img/icons/group-status-2.svg";
import { ReactComponent as OptedOutIcon } from "../../assets/img/icons/group-status-3.svg";
import { ReactComponent as MobileIcon } from "../../assets/img/icons/group-status-8.svg";
import { ReactComponent as LandlineIcon } from "../../assets/img/icons/group-status-7.svg";
import { ReactComponent as VoipIcon } from "../../assets/img/icons/group-status-6.svg";
import { ReactComponent as CcbSvg } from "../../assets/img/icons/ccb.svg";
import { ReactComponent as PcoSvg } from "../../assets/img/icons/pco.svg";
import { ReactComponent as McSvg } from "../../assets/img/icons/mc.svg";
import { ReactComponent as ElvantoSvg } from "../../assets/img/icons/elvanto.svg";
import { ReactComponent as BreezeSvg } from "../../assets/img/icons/breeze.svg";
import { ReactComponent as MissedCallSvg } from "../../assets/img/icons/missed-call.svg";
import { ReactComponent as CallOutgoingSvg } from "../../assets/img/icons/call_outgoing.svg";
import { ReactComponent as CallIncomingSvg } from "../../assets/img/icons/incoming-call.svg";
import { ReactComponent as PictureIconSvg } from "../../assets/img/icons/picture-icon.svg";
import { ReactComponent as VoicemailSvg } from "../../assets/img/icons/voicemail.svg";
import { ReactComponent as SavedSearchesIconSvg } from "../../assets/img/icons/saved-searches-icon.svg";
import { ReactComponent as ProcessQueuesIconSvg } from "../../assets/img/icons/process-queues-icon.svg";

function mapStateToProps(store, ownProps) {
  let contacts = [];
  if (ownProps.newContact) {
    contacts.push(ownProps.newContact);
  } else if (
    ownProps.item_key.substr(0, 7) === "contact" &&
    ownProps.contacts_ids &&
    ownProps.contacts_ids.length
  ) {
    if (
      (typeof ownProps.doNotGroup === "undefined" || !ownProps.doNotGroup) &&
      typeof store.contacts.data[ownProps.contacts_ids[0]] !== "undefined" &&
      store.contacts.data[ownProps.contacts_ids[0]] &&
      store.contacts.data[ownProps.contacts_ids[0]].primary_contact_id > 0 &&
      store.contacts.data[ownProps.contacts_ids[0]]
        .multiple_primary_contacts === 0 &&
      typeof store.contacts.data[
        store.contacts.data[ownProps.contacts_ids[0]].primary_contact_id
      ] !== "undefined" &&
      store.contacts.data[
        store.contacts.data[ownProps.contacts_ids[0]].primary_contact_id
      ]
    ) {
      contacts.push(
        store.contacts.data[
          store.contacts.data[ownProps.contacts_ids[0]].primary_contact_id
        ]
      );
    } else {
      for (let i = 0; i < ownProps.contacts_ids.length; i++) {
        if (
          typeof store.contacts.data[ownProps.contacts_ids[i]] !==
            "undefined" &&
          store.contacts.data[ownProps.contacts_ids[i]]
        ) {
          contacts.push(store.contacts.data[ownProps.contacts_ids[i]]);
        }
      }
    }
  }

  let group = ownProps.group;
  if (group && typeof store.groups.data[group.id] !== "undefined") {
    group = store.groups.data[group.id];
  } else if (ownProps.item_key.substr(0, 5) === "group") {
    const groupId = parseInt(ownProps.item_key.substr(6));
    if (typeof store.groups.data[groupId] !== "undefined") {
      group = store.groups.data[groupId];
    }
  }

  return {
    userTimeZoneOffset: store.users.loggedUser.timezone_offset,
    contacts: contacts,
    group: group,
    loggedUser: store.users.loggedUser, //@todo remove it, tmp only
  };
}

const ThreadItem = (props) => {
  const itemRef = props.itemRef ? props.itemRef : undefined;
  useEffect(() => {
    const refCopy = detailRef.current;
    if (itemTooltipProps && detailRef.current) {
      window.$(detailRef.current).tooltip("dispose");
      window.$(detailRef.current).tooltip();
    }
    return () => {
      window.$(refCopy).tooltip("dispose");
    };
  });

  const {
    isSingleMemberDuplicateRestArchived,
    singleUnArchivedContact,
  } = useMemo(() => {
    return getDuplicateViewInfo(props.contacts);
  }, [props.contacts]);

  const isGroup = props.item_key.indexOf("group-") >= 0;
  const isAdhocGroup = isGroup && props.group && props.group.addhoc_id > 0;
  const isAdhocName =
    isGroup && props.group.adhoc_name && props.group.adhoc_name.length;
  const isAllPeopleGroup = isGroup && props.group.my_group_status > 0;
  let isMultipleContacts =
    !isGroup &&
    props.contacts &&
    props.contacts.length > 1 &&
    !isSingleMemberDuplicateRestArchived;
  const isSavedSearch = isGroup && props.group.ccb_search_id > 0;
  const isProcessQueue = isGroup && props.group.ccb_sync_queue_id > 0;
  const isPcoWorkflow = isGroup && props.group.pco_workflow_id > 0;

  let name = "Unknown";
  let phoneNumber = null;
  let isContactUpdating = false;
  let groupName = "Unknown";
  if (isGroup) {
    if (isAdhocName) {
      groupName = props.group.adhoc_name;
    } else if (
      props.group &&
      props.group.group_name &&
      props.group.group_name !== ""
    ) {
      groupName = props.group.group_name;
    } else if (props.group) {
      groupName = "Unnamed/Empty";
    }
    name = (
      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {groupName}
      </span>
    );
  } else if (props.contacts && props.purpose === "group-members-modal") {
    name = `${formatPhoneNumber(
      props.contacts[0].phone_number,
      props.contacts[0].country ? props.contacts[0].country.phonecode : ""
    )}`;
  } else if (props.contacts && props.contacts.length) {
    name = contactsName(props.contacts, true);
    if (
      props.contacts[0].phone_number &&
      props.contacts[0].phone_number !== "0"
    ) {
      phoneNumber =
        "" +
        (props.contacts[0].country ? props.contacts[0].country.phonecode : "") +
        props.contacts[0].phone_number;
    }
    if (props.contacts[0].is_pco && props.contacts[0].is_pco_pending) {
      isContactUpdating = true;
    }
  } else if (props.contact) {
    name = contactsName([props.contact], true);
    if (props.contact.phone_number && props.contact.phone_number !== "0") {
      phoneNumber =
        "" +
        (props.contact.country ? props.contact.country.phonecode : "") +
        props.contact.phone_number;
    }
    if (props.contact.is_pco && props.contact.is_pco_pending) {
      isContactUpdating = true;
    }
  }

  const tab = props.tab ? props.tab : "messages";
  const withGroupMembersCount = tab === "groups";
  const groupMembersCount =
    isGroup && props.group ? props.group.members_count : 0;

  const showGroupFullNameOnHover =
    groupName.length >= 16 ? `${groupName}<br/>` : "";
  const showContactNameOnHover =
    name?.props?.children?.length >= 16
      ? `${name?.props?.children}<br/>${formatPhoneNumber(phoneNumber)}`
      : formatPhoneNumber(phoneNumber);
  const contactTooltip = isContactUpdating
    ? "updating..."
    : phoneNumber
    ? showContactNameOnHover
    : `${
        name?.props?.children?.length >= 16
          ? `${name?.props?.children} <br/>`
          : ""
      } No phone number`;

  let itemTooltipProps = null;
  if (isGroup) {
    itemTooltipProps = {
      ...tooltipProps,
      "data-placement": "top",
    };
    itemTooltipProps["data-html"] = true;
    itemTooltipProps.title = `<div style="line-height: 1.8;font-size: 0.7rem;">${showGroupFullNameOnHover}${groupMembersCount} Member${
      groupMembersCount !== 1 ? "s" : ""
    }</div>`;
  } else if (
    phoneNumber ||
    isContactUpdating ||
    name?.props?.children?.length
  ) {
    itemTooltipProps = {
      ...tooltipProps,
      "data-placement": "top",
    };
    itemTooltipProps["data-html"] = true;
    itemTooltipProps.title = `<div style="line-height: 1.8;font-size: 0.7rem;">${contactTooltip}</div>`;
  }

  if (
    itemTooltipProps &&
    !isContactUpdating &&
    props.contacts &&
    props.contacts.length > 1
  ) {
    const dupesNames = [];
    for (let i = 0; i < props.contacts.length; i += 1) {
      dupesNames.push(
        `${props.contacts[i].first_name} ${props.contacts[i].last_name}`.trim()
      );
    }
    itemTooltipProps["data-html"] = true;
    const phoneNumFormatted = phoneNumber
      ? formatPhoneNumber(phoneNumber)
      : "No phone number";
    const dupesNamesJoined = dupesNames.join("<br/>");
    itemTooltipProps.title = `<div style="line-height: 1.8;font-size: 0.7rem;">${phoneNumFormatted}<br/>${dupesNamesJoined}</div>`;
  }

  const withoutLog =
    props.handleNewContact ||
    props.purpose === "call-modal" ||
    props.withoutLog;
  const log = withoutLog ? null : props.log;
  const groupSmsBlast = withoutLog ? null : props.group_sms_blast;
  const scheduleMessage = withoutLog ? null : props.schedule_message;

  let isEmail = null;
  let msgType = "text";
  let route = "outbox";
  let duration = 0;
  let textMessage = "";
  let isImage = false;
  let emailSubject = null;
  let createdOrSendOnUtc = null;
  if (log) {
    isEmail = log.is_email;
    msgType = log.msg_type;
    route = log.route;
    duration = log.duration;
    textMessage = log.text_message;
    emailSubject = props.log.email_subject;
    createdOrSendOnUtc = log.created_utc;
    isImage = props.log.image_url !== "";
    if (log.group_broadcast_id > 0) {
      textMessage = log.broadcast_text_message;
    }
  } else if (groupSmsBlast) {
    msgType = groupSmsBlast.is_voice === 1 ? "broadcast" : "text";
    textMessage = groupSmsBlast.msg;
    if (props.voice_message) {
      msgType = "broadcast";
      textMessage = props.voice_message.text_message;
    }
    createdOrSendOnUtc = groupSmsBlast.created_utc;
  } else if (scheduleMessage) {
    if (scheduleMessage.msg_type === 2) {
      textMessage = scheduleMessage.mms_text;
      isImage = scheduleMessage.message !== "";
    } else {
      textMessage = scheduleMessage.message;
    }
    createdOrSendOnUtc = scheduleMessage.send_on_utc;
  }

  // determine item type
  let itemType = "text";
  if (isEmail === 1) {
    itemType = "email";
  } else if (msgType === "text") {
    itemType = "text";
  } else {
    if (msgType === "voice" && duration > 0) {
      itemType = "voicemail";
    } else if (msgType === "broadcast") {
      itemType = "broadcast";
    } else if (
      (msgType === "voice" || msgType === "called") &&
      duration === 0
    ) {
      itemType = "call-missed";
    } else if (msgType === "callforward") {
      itemType = "call-forwarded";
    } else {
      itemType = "call-connected";
    }
  }

  let text = "";
  let icon = null;

  text = textMessage ? textMessage.substr(0, 100) : "";
  if (itemType === "email") {
    text = text.length > 0 ? text : ("Subject: " + emailSubject).substr(0, 100);
  } else if (itemType === "voicemail") {
    icon = (
      <VoicemailSvg className={"mr-2 icon-black"} width={15} height={6.66} />
    );
    text = "New Voicemail";
  } else if (itemType === "broadcast") {
    icon = <BroadcastIconSvg className={"mr-2 icon-black"} height={14} />;
    if (text.length === 0) {
      text = "Broadcast";
    }
  } else if (itemType === "call-missed") {
    if (route === "inbox") {
      icon = <MissedCallSvg className={"mr-2 icon-black"} height={12} />;
    } else {
      icon = <CallOutgoingSvg className={"mr-2 icon-black"} height={14} />;
    }
    text = "Missed Call";
  } else if (itemType === "call-forwarded") {
    icon = (
      <React.Fragment>
        <CallOutgoingSvg className={"mr-2 icon-black"} height={14} />
        <CallIncomingSvg className={"mr-2 icon-black"} height={14} />
      </React.Fragment>
    );
    text =
      "Forwarded Call" + (duration > 0 ? " " + formatDuration(duration) : "");
  } else if (itemType === "call-connected") {
    icon = <CallOutgoingSvg className={"mr-2 icon-black"} height={14} />;
    text = "Called" + (duration > 0 ? " " + formatDuration(duration) : "");
  } else if (isImage) {
    icon = <PictureIconSvg className={"mr-2 icon-black"} height={14} />;
    if (text.length === 0) {
      text = "Picture";
    }
  }

  let url = "#error";
  if (props.purpose === "call-modal") {
    if (isGroup && props.group) {
      url = "#modal-new-voice-broadcast/" + props.group.id;
    } else if (!isGroup && log && log.contact_id) {
      url = "#modal-call-contact/" + log.contact_id;
    } else if (!isGroup && props.contacts_ids && props.contacts_ids.length) {
      url = "#modal-call-contact/" + props.contacts_ids[0];
    }
  } else {
    if (isGroup && props.group) {
      if (props.tab === "groups") {
        url = "/hub/" + tab + "/" + props.group.id;
      } else {
        url = "/hub/" + tab + "/group/" + props.group.id;
      }
    } else if (!isGroup && props.contacts_ids && props.contacts_ids.length) {
      url = `/hub/${tab}/contact/${
        isSingleMemberDuplicateRestArchived
          ? singleUnArchivedContact.id
          : props.contacts_ids[0]
      }`;
    }
  }

  let fromPrefix = "";
  if (route === "outbox" && props.user && props.loggedUser) {
    let otherUserName = null;
    if (props.user.id === props.loggedUser.id) {
      otherUserName = "You";
    } else {
      otherUserName = props.user.first_name.trim();
      if (props.user.last_name.trim().length) {
        otherUserName += ` ${props.user.last_name
          .split("-")[0]
          .trim()
          .substr(0, 1)}`;
      }
    }
    fromPrefix = otherUserName.length > 0 ? `${otherUserName}: ` : "";
  }

  const secondRow =
    log || props.withContactDetails || text || props.undelivered_count > 0;

  let style = props.style ? Object.assign({}, props.style) : {};

  let className =
    "message-single" + (props.className ? " " + props.className : "");
  className += props.handleNewContact ? " new-contact" : "";
  if (!props.purpose || props.purpose.indexOf("modal") === -1) {
    if (typeof props.unread_count !== "undefined" && props.unread_count > 0) {
      className += " unread";
    }
    className += props.isActive ? " active" : "";
  }
  if (!secondRow) {
    className += " contact-tab-single";
  }
  if (props.withContactDetails) {
    className += " with-detail";
    style["alignItems"] = "center";
  }

  let favorite = false;
  if (isGroup && props.group && props.group.is_favorite === 1) {
    favorite = true;
  } else if (props.contacts) {
    for (let i = 0; i < props.contacts.length; i++) {
      if (
        props.contacts[i].is_favorite ||
        props.contacts[i].is_number_favorite
      ) {
        favorite = true;
        break;
      }
    }
  }

  const contact =
    props.contacts && props.contacts.length
      ? props.contacts[0]
      : props.contact
      ? props.contact
      : null;

  let integrationIcon = null;
  if (props.withContactDetails || props.withIntegrationIcon) {
    if (isGroup && props.group) {
      if (props.group.ccb_status) {
        integrationIcon = <CcbSvg className={"icon-grey"} width={14.299} />;
      } else if (props.group.pco_status) {
        integrationIcon = <PcoSvg className={"icon-grey"} width={14.112} />;
      } else if (props.group.mc_status) {
        integrationIcon = <McSvg className={"icon-grey"} width={14.112} />;
      } else if (props.group.is_elvanto) {
        integrationIcon = <ElvantoSvg className="icon-grey" width={14.112} />;
      } else if (props.group.is_breeze) {
        integrationIcon = <BreezeSvg className="icon-grey" width={14.112} />;
      }
    } else if (!isGroup && !isMultipleContacts && contact) {
      if (contact.is_ccb) {
        integrationIcon = <CcbSvg className={"icon-grey"} width={14.299} />;
      } else if (contact.is_pco) {
        integrationIcon = <PcoSvg className={"icon-grey"} width={14.112} />;
      } else if (contact.is_mc) {
        integrationIcon = <McSvg className={"icon-grey"} width={14.112} />;
      } else if (contact.is_elvanto) {
        integrationIcon = <ElvantoSvg className="icon-grey" width={14.112} />;
      } else if (contact.is_breeze) {
        integrationIcon = <BreezeSvg className="icon-grey" width={14.112} />;
      }
    }
  }

  const isMultiplePrimaryContacts =
    props.contacts &&
    props.contacts.length > 1 &&
    !isSingleMemberDuplicateRestArchived; //contact && contact.multiple_primary_contacts === 1 ? true : false;

  const detailRef = React.createRef();
  const detailVisible =
    props.purpose !== "new-call-modal" ||
    (!props.withContactDetails &&
      (text || props.undelivered_count > 0 || props.unread_count > 0)) ||
    (props.withContactDetails && contact);
  const content = (
    <React.Fragment>
      <Avatar
        isGroup={isGroup}
        isAdhocGroup={isAdhocGroup}
        isAllPeopleGroup={isAllPeopleGroup}
        isMultipleContacts={isMultiplePrimaryContacts}
        firstName={
          isGroup || isMultipleContacts || !contact ? "" : contact.first_name
        }
        lastName={
          isGroup || isMultipleContacts || !contact ? "" : contact.last_name
        }
        email={isGroup || isMultipleContacts || !contact ? "" : contact.email}
        bgColor={isGroup || isMultipleContacts || !contact ? "" : contact.color}
      />
      <div
        ref={detailRef}
        className={
          "detail" + (secondRow ? "" : detailVisible ? " d-flex" : " d-none")
        }
        {...itemTooltipProps}
      >
        {props.purpose !== "new-call-modal" && (
          <h3 style={{ maxWidth: "100%" }}>
            <span
              className="name"
              style={{
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </span>
            {!!withGroupMembersCount && (
              <small
                className="ml-2 d-flex text-muted"
                style={{ alignItems: "center", fontSize: "16px" }}
              >
                {groupMembersCount}
              </small>
            )}
            {favorite && (
              <span className="ml-1 d-flex" style={{ alignItems: "center" }}>
                <FavoriteFilledIconSvg width={13.191} />
              </span>
            )}
            {props.contacts_ids &&
              props.contacts_ids.length > 1 &&
              !props.withContactDetails && (
                <PeopleGroupSvg
                  className={"ml-2 icon-black"}
                  width={18}
                  style={{ minWidth: "18px" }}
                />
              )}
            {createdOrSendOnUtc &&
              createdOrSendOnUtc !== "0000-00-00 00:00:00" && (
                <span className="date" style={{ paddingLeft: "6px" }}>
                  {scheduleMessage && <i className={"fa fa-clock-o"} />}
                  {timeAgo(createdOrSendOnUtc, props.userTimeZoneOffset)}
                </span>
              )}
          </h3>
        )}
        {!props.withContactDetails &&
          (text || props.undelivered_count > 0 || props.unread_count > 0) && (
            <div className="message-info">
              <p className="message-text">
                {icon}
                {fromPrefix}
                {text}
              </p>

              {!props.withContactDetails &&
                (props.unread_count > 0 || props.undelivered_count > 0) && (
                  <div className="info">
                    {props.unread_count > 0 && (
                      <span className="unread-noti">{props.unread_count}</span>
                    )}
                    {props.undelivered_count > 0 && (
                      <i className="fa fa-warning color-secondary"></i>
                    )}
                  </div>
                )}
            </div>
          )}
        {props.withContactDetails && contact && (
          <p className="message-text">
            {isContactUpdating
              ? "updating..."
              : formatPhoneNumber(
                  "" +
                    (contact.country ? contact.country.phonecode : "") +
                    contact.phone_number
                )}
            {props.contacts_ids && props.contacts_ids.length > 1 && (
              <span
                className="status-icon optedout-icon ml-1"
                style={{ verticalAlign: "text-bottom" }}
              >
                <PeopleGroupSvg
                  className="icon-grey"
                  width={18}
                  style={{ minWidth: "18px" }}
                />
              </span>
            )}
            {!isMultipleContacts && contact && (
              <React.Fragment>
                {contact.status === 1 && (
                  <span
                    className="status-icon optedout-icon ml-1"
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <BlockedIcon className="icon-grey" width={12} height={12} />
                  </span>
                )}
                {contact.status === 3 && contact.unsub_by_user_id > 0 && (
                  <span
                    className="status-icon optedout-icon ml-1"
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <UnsubscribedIcon
                      className="icon-grey"
                      width={12}
                      height={12}
                    />
                  </span>
                )}
                {contact.status === 3 && contact.unsub_by_user_id <= 0 && (
                  <span
                    className="status-icon optedout-icon ml-1"
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <OptedOutIcon
                      className="icon-grey"
                      width={12}
                      height={12}
                    />
                  </span>
                )}
                {contact.number_lookup &&
                  contact.number_lookup.type === "mobile" && (
                    <span
                      className="status-icon phone-icon ml-1"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      <MobileIcon
                        className="icon-grey"
                        width={12}
                        height={12}
                      />
                    </span>
                  )}
                {contact.number_lookup &&
                  contact.number_lookup.type === "landline" && (
                    <span
                      className="status-icon phone-icon ml-1"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      <LandlineIcon
                        className="icon-grey"
                        width={12}
                        height={12}
                      />
                    </span>
                  )}
                {contact.number_lookup &&
                  contact.number_lookup.type === "voip" && (
                    <span
                      className="status-icon phone-icon ml-1"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      <VoipIcon className="icon-grey" width={12} height={12} />
                    </span>
                  )}
              </React.Fragment>
            )}
          </p>
        )}
      </div>
      {props.purpose === "new-call-modal" && (
        <span
          className="name"
          style={{
            display: "flex",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </span>
      )}
      {props.handleNewContact &&
        props.purpose !== "new-call-modal" &&
        props.purpose !== "group-members-modal" && (
          <div className="action">
            {props.purpose !== "modal" && !isCordovaApp() && (
              <a
                data-toggle="tab"
                href="#void"
                onClick={(e) =>
                  props.handleNewContact(e, props.newContact, "call")
                }
              >
                <span className="icon">
                  <i className="fa fa-phone"></i>
                </span>
              </a>
            )}
            {props.purpose !== "call-modal" && (
              <a
                data-toggle="tab"
                href="#void"
                onClick={(e) =>
                  props.handleNewContact(e, props.newContact, "message")
                }
              >
                <MessageSingleFilledSvg width={20.911} />
              </a>
            )}
          </div>
        )}
      {integrationIcon && isSavedSearch && (
        <span className="icon ml-2">
          <SavedSearchesIconSvg
            className={"icon-grey"}
            width={14.299}
            title="Saved Search"
          />
        </span>
      )}
      {integrationIcon && isProcessQueue && (
        <span className="icon ml-2">
          <ProcessQueuesIconSvg
            className={"icon-grey"}
            width={14.299}
            title="Process Queue"
          />
        </span>
      )}
      {integrationIcon && isPcoWorkflow && (
        <span className="icon ml-2">
          <ProcessQueuesIconSvg
            className={"icon-grey"}
            width={14.299}
            title="PCO Workflow"
          />
        </span>
      )}
      {integrationIcon && <span className="icon ml-2">{integrationIcon}</span>}
      {props.purpose === "new-call-modal" && (
        <React.Fragment>
          {isMultipleContacts && (
            <span className="icon ml-2">
              <PeopleGroupSvg
                className={"icon-black"}
                width={18}
                style={{ minWidth: "18px" }}
              />
            </span>
          )}
        </React.Fragment>
      )}
      {!props.handleNewContact && props.purpose === "call-modal" && isGroup && (
        <span
          className="icon"
          {...tooltipPropsAuto}
          title="Voice Broadcast"
          data-container=".message-single"
        >
          <BroadcastIconSvg width={13.538} />
        </span>
      )}
      {!props.handleNewContact && props.purpose === "call-modal" && !isGroup && (
        <span className="icon">
          <i className="fa fa-phone"></i>
        </span>
      )}
    </React.Fragment>
  );

  let clickHandler = null;
  if (props.onClick) {
    if (isGroup) {
      clickHandler = (e) =>
        props.onClick(isGroup ? props.group : props.contacts[0]);
    } else if (props.contacts.length === 1) {
      clickHandler = (e) => props.onClick(props.contacts[0]);
    } else if (props.contacts.length > 1) {
      clickHandler = (e) => props.onClick(props.contacts[0], props.contacts);
    }

    style["cursor"] = "pointer";
  } else if (props.purpose === "call-modal") {
    if (!isGroup && props.handleNewContact) {
      clickHandler = (e) => props.handleNewContact(e, props.newContact, "call");
      style["cursor"] = "pointer";
    }
  } else if (props.handleNewContact) {
    clickHandler = (e) =>
      props.handleNewContact(e, props.newContact, "message");
    style["cursor"] = "pointer";
  }

  const replace = !!props.linkReplace;

  return (
    <React.Fragment>
      {clickHandler && (
        <div
          ref={itemRef}
          className={className}
          style={style}
          onClick={clickHandler}
        >
          {content}
        </div>
      )}
      {!clickHandler && (
        <React.Fragment>
          {url.substr(0, 4) === "http" && (
            <a ref={itemRef} className={className} style={style} href={url}>
              {content}
            </a>
          )}
          {url.substr(0, 4) !== "http" && (
            <Link
              innerRef={itemRef}
              className={className}
              style={style}
              to={url}
              aria-selected={props.isActive}
              replace={replace}
            >
              {content}
            </Link>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(ThreadItem);
