import { resetFetchContactsRequests } from "../actions/contacts";

const fromEntries = require("fromentries");

const initialState = {
  // Contacts data
  data: {},
  dataStatus: {},

  // List
  contacts: [],
  contactsStatus: null,
  contactsPage: null,
  contactsLoadedAll: null,

  // Filtered list
  filteredContacts: [],
  filteredContactsStatus: null,
  filteredContactsPage: null,
  filteredContactsLoadedAll: null,
  filteredContactsTotal: null,

  // Add quick
  addQuickStatus: null,
  addQuickError: null,
  addQuickErrorStatus: null,
  addQuickId: null,

  // Edit contact
  editFieldQueue: {},

  // Bathch edit contacts
  batchEditFieldQueue: {},

  // Delete contact
  deleteStatus: null,
  deleteId: null,

  // Batch delete contacts
  batchDeleteStatus: null,
  batchDeleteIds: null,

  // Check phone number
  checkPhoneNumberStatus: null,
  checkPhoneNumberDuplicates: null,
  checkPhoneNumberCountryId: null,
  checkPhoneNumberPhoneNumber: null,

  // Add contact
  addId: null,
  addStatus: null,
  addErrors: null,

  // Contact groups
  groups: null,
  groupsStatus: null,
  groupsPage: null,
  groupsQuery: "",
  groupsLoadedAll: null,
  groupsContactId: null,

  // Edit contact groups
  patchGroupsStatus: null,

  // Contact tags
  tags: null,
  tagsStatus: null,
  tagsPage: null,
  tagsLoadedAll: null,
  tagsContactId: null,
  tagsQuery: "",

  // Edit contact tags
  patchTagsStatus: null,

  // Contact Automated Messages Campaigns
  amCampaigns: null,
  amCampaignsStatus: null,
  amCampaignsPage: null,
  amCampaignsQuery: "",
  amCampaignsLoadedAll: null,
  amCampaignsContactId: null,

  // Edit contact Automated Messages Campaigns
  patchAmCampaignsStatus: null,

  // Number lookup price
  numberLookupPriceStatus: null,
  numberLookupPrice: null,
  numberLookupNumbersCount: null,

  // Number lookup price
  numberLookupStatus: null,
  numberLookupResult: null,

  // Queue number lookup price
  queueNumberLookupStatus: null,

  //export
  exportContactsStatus: {},
  exportContactsCSV: {},

  filter: null,

  //long name modal content
  longNameModalContent: null,
};

export default function reducer(state = initialState, action) {
  let data = null;
  let dataStatus = null;
  let contacts = null;
  let editFieldQueue = null;
  let batchEditFieldQueue = null;
  let groups = null;
  let tags = null;
  let amCampaigns = null;
  switch (action.type) {
    // List
    case "CONTACTS/FETCH":
      return {
        ...state,
        contactsStatus: "loading",
      };
    case "CONTACTS/FETCH.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.contacts).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };

      contacts = action.payload.contacts_ids;
      if (state.contacts && state.contactsPage + 1 === action.page) {
        let prevContacts = [];
        for (let i = 0; i < state.contacts.length; i++) {
          if (contacts.indexOf(state.contacts[i]) === -1) {
            prevContacts.push(state.contacts[i]);
          }
        }
        contacts = prevContacts.concat(contacts);
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        contacts: contacts,
        contactsStatus: "success",
        contactsPage: action.page,
        contactsLoadedAll: action.payload.contacts_ids.length === 0,
      };
    case "CONTACTS/FETCH.ERROR":
      return {
        ...state,
        contacts: null,
        contactsStatus: "error",
        contactsPage: null,
        contactsLoadedAll: null,
      };

    case "CONTACTS/FETCH_TEMP.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      dataStatus[action.contactId] = "success";

      contacts = [...state.contacts];
      if (contacts.indexOf(action.contactId) === -1) {
        contacts.unshift(action.contactId);
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        contacts: contacts,
      };

    // Filtered list
    case "CONTACTS/FETCH_FILTERED":
      return {
        ...state,
        filteredContactsStatus: "loading",
      };
    case "CONTACTS/FETCH_FILTERED.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.contacts).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };
      if (action.page === 1 || !state.filteredContacts) {
        contacts = action.payload.contacts_ids;
      } else {
        contacts = state.filteredContacts.concat(action.payload.contacts_ids);
      }
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        filteredContacts: contacts,
        filteredContactsStatus: "success",
        filteredContactsPage: action.page,
        filteredContactsLoadedAll: action.payload.contacts_ids.length === 0,
        filteredContactsTotal: action.payload.total,
      };
    case "CONTACTS/FETCH_FILTERED.ERROR":
      return {
        ...state,
        filteredContacts: null,
        filteredContactsStatus: "error",
        filteredContactsPage: null,
        filteredContactsLoadedAll: null,
        filteredContactsTotal: null,
      };

    // Fetch contact data
    case "CONTACTS/FETCH_DATA":
      dataStatus = { ...state.dataStatus };
      dataStatus[action.contactId] = "loading";
      return {
        ...state,
        dataStatus: dataStatus,
      };
    case "CONTACTS/FETCH_DATA.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.contacts).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      data[action.contactId]["__switchedCompanyId"] =
        action.payload.switchedCompanyId;
      dataStatus[action.contactId] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
      };
    case "CONTACTS/FETCH_DATA.ERROR":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = null;
      dataStatus[action.contactId] = "error";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
      };

    // Add quick
    case "CONTACTS/ADD_QUICK":
      return {
        ...state,
        addQuickStatus: "loading",
        addQuickError: null,
        addQuickErrorStatus: null,
      };
    case "CONTACTS/ADD_QUICK.SUCCESS":
      return {
        ...state,
        addQuickStatus: "success",
        addQuickError: null,
        addQuickErrorStatus: null,
        addQuickId: action.payload.id,
        // reset list
        contacts: null,
        contactsStatus: null,
        contactsPage: null,
        contactsLoadedAll: null,
      };
    case "CONTACTS/ADD_QUICK.ERROR":
      return {
        ...state,
        addQuickStatus: "error",
        addQuickError: null,
        addQuickErrorStatus: null,
        addQuickId: null,
      };

    // Edit contact field
    case "CONTACTS/EDIT_FIELD":
      editFieldQueue = { ...state.editFieldQueue };
      editFieldQueue[action.requestId] = {
        contactId: action.contactId,
        contactData: action.contactData,
        status: "loading",
      };
      return {
        ...state,
        editFieldQueue,
      };
    case "CONTACTS/EDIT_FIELD.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.payload.contact.id] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      dataStatus[action.payload.contact.id] = "success";

      editFieldQueue = { ...state.editFieldQueue };
      if (typeof editFieldQueue[action.requestId] !== "undefined") {
        editFieldQueue[action.requestId].status = "success";
      }
      return {
        ...state,
        data,
        dataStatus,
        editFieldQueue,
      };
    case "CONTACTS/EDIT_FIELD.ERROR":
      editFieldQueue = { ...state.editFieldQueue };
      if (typeof editFieldQueue[action.requestId] !== "undefined") {
        editFieldQueue[action.requestId].status = "error";
      }
      return {
        ...state,
        editFieldQueue,
      };

    // Batch edit contacts field
    case "CONTACTS/BATCH_EDIT_FIELD":
      batchEditFieldQueue = { ...state.batchEditFieldQueue };
      batchEditFieldQueue[action.requestId] = {
        contactIds: action.contactIds,
        contactData: action.contactData,
        status: "loading",
      };
      return {
        ...state,
        batchEditFieldQueue,
      };
    case "CONTACTS/BATCH_EDIT_FIELD.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };

      let editedIds = [];
      for (let i = 0; i < action.payload.contacts.length; i++) {
        editedIds.push(action.payload.contacts[i].id);
        data[action.payload.contacts[i].id] = action.payload.contacts[i];
        data[action.payload.contacts[i].id]["__full"] = true;
        dataStatus[action.payload.contacts[i].id] = "success";
      }

      batchEditFieldQueue = { ...state.batchEditFieldQueue };
      if (typeof batchEditFieldQueue[action.requestId] !== "undefined") {
        batchEditFieldQueue[action.requestId].status = "success";
      }
      return {
        ...state,
        data,
        dataStatus,
        batchEditFieldQueue,
      };
    case "CONTACTS/BATCH_EDIT_FIELD.ERROR":
      batchEditFieldQueue = { ...state.batchEditFieldQueue };
      if (typeof batchEditFieldQueue[action.requestId] !== "undefined") {
        batchEditFieldQueue[action.requestId].status = "error";
      }
      return {
        ...state,
        batchEditFieldQueue,
      };

    // Delete contact
    case "CONTACTS/DELETE":
      return {
        ...state,
        deleteStatus: "loading",
        deleteId: action.contactId,
      };
    case "CONTACTS/DELETE.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };

      if (typeof data[action.contactId] !== "undefined") {
        delete data[action.contactId];
      }
      dataStatus[action.contactId] = "deleted";

      return {
        ...state,
        data,
        dataStatus,
        deleteStatus: "success",
        deleteId: action.contactId,
      };
    case "CONTACTS/DELETE.ERROR":
      return {
        ...state,
        deleteStatus: "error",
        deleteId: action.contactId,
      };

    // Batch delete contacts
    case "CONTACTS/BATCH_DELETE":
      return {
        ...state,
        batchDeleteStatus: "loading",
        batchDeleteIds: action.contactIds,
      };
    case "CONTACTS/BATCH_DELETE.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      for (let i = 0; i < action.payload.deletedContactIds.length; i++) {
        const id = action.payload.deletedContactIds[i];
        if (typeof data[id] !== "undefined") {
          delete data[id];
        }
        dataStatus[id] = "deleted";
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,

        // Reset contacts list
        contacts: null,
        contactsStatus: null,
        contactsPage: null,
        contactsLoadedAll: null,

        batchDeleteStatus: "success",
        batchDeleteIds: action.payload.deletedContactIds,
      };
    case "CONTACTS/BATCH_DELETE.ERROR":
      return {
        ...state,
        batchDeleteStatus: "error",
        batchDeleteIds: action.contactIds,
      };

    // Check phone number
    case "CONTACTS/CHECK_PHONE_NUMBER":
      return {
        ...state,
        checkPhoneNumberStatus: "loading",
      };
    case "CONTACTS/CHECK_PHONE_NUMBER.SUCCESS":
      return {
        ...state,
        checkPhoneNumberStatus: "success",
        checkPhoneNumberDuplicates: action.payload.duplicates,
        checkPhoneNumberCountryId: action.countryId,
        checkPhoneNumberPhoneNumber: action.phoneNumber,
      };
    case "CONTACTS/CHECK_PHONE_NUMBER.ERROR":
      return {
        ...state,
        checkPhoneNumberStatus: "error",
        checkPhoneNumberDuplicates: null,
        checkPhoneNumberCountryId: action.countryId,
        checkPhoneNumberPhoneNumber: action.phoneNumber,
      };

    // Add contacts
    case "CONTACTS/ADD":
      return {
        ...state,
        addId: null,
        addStatus: "loading",
        addErrors: null,
      };
    case "CONTACTS/ADD.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.payload.contact.id] = action.payload.contact;
      //data[action.payload.contact.id]['__full'] = true;
      dataStatus[action.payload.contact.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        addId: action.payload.contact.id,
        addStatus: "success",
        addErrors: null,
        // reset list
        contacts: null,
        contactsStatus: null,
        contactsPage: null,
        contactsLoadedAll: null,
      };
    case "CONTACTS/ADD.ERROR":
      return {
        ...state,
        addId: null,
        addStatus: "error",
        addErrors:
          action.payload && typeof action.payload.errors !== "undefined"
            ? action.payload.errors
            : null,
      };
    case "CONTACTS/ADD.CLEAR":
      return {
        ...state,
        addId: null,
        addStatus: null,
        addErrors: null,
      };

    // Contact groups
    case "CONTACTS/GROUPS":
      return {
        ...state,
        groupsStatus: "loading",
        groupsContactId: action.contactId,
      };
    case "CONTACTS/GROUPS.SUCCESS":
      if (action.page === 1 || !state.groups) {
        groups = action.payload.groups;
      } else {
        groups = state.groups.concat(action.payload.groups);
      }
      return {
        ...state,
        groups: groups,
        groupsStatus: "success",
        groupsPage: action.page,
        groupsQuery: action.query,
        groupsLoadedAll: action.payload.groups.length === 0,
        groupsContactId: action.contactId,
      };
    case "CONTACTS/GROUPS.ERROR":
      return {
        ...state,
        groups: null,
        groupsStatus: "error",
        groupsPage: null,
        groupsQuery: "",
        groupsLoadedAll: null,
        groupsContactId: action.contactId,
      };
    case "CONTACTS/GROUPS.CLEAR":
      return {
        ...state,
        groups: null,
        groupsStatus: null,
        groupsPage: null,
        groupsQuery: "",
        groupsLoadedAll: null,
        groupsContactId: null,
      };

    // Edit contact groups
    case "CONTACTS/PATCH_GROUPS":
      return {
        ...state,
        patchGroupsStatus: "loading",
      };
    case "CONTACTS/PATCH_GROUPS.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      dataStatus[action.contactId] = "success";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchGroupsStatus: "success",
      };
    case "CONTACTS/PATCH_GROUPS.ERROR":
      return {
        ...state,
        patchGroupsStatus: "error",
      };
    case "CONTACTS/PATCH_GROUPS.CLEAR":
      return {
        ...state,
        patchGroupsStatus: null,
      };

    // Contact tags
    case "CONTACTS/TAGS":
      return {
        ...state,
        tagsStatus: "loading",
        tagsContactId: action.contactId,
      };
    case "CONTACTS/TAGS.SUCCESS":
      if (action.page === 1 || !state.tags) {
        tags = action.payload.tags;
      } else {
        tags = state.tags.concat(action.payload.tags);
      }
      return {
        ...state,
        tags: tags,
        tagsStatus: "success",
        tagsPage: action.page,
        tagsQuery: action.query,
        tagsLoadedAll: action.payload.tags.length === 0,
      };
    case "CONTACTS/TAGS.ERROR":
      return {
        ...state,
        tags: null,
        tagsStatus: "error",
        tagsPage: null,
        tagsQuery: "",
        tagsLoadedAll: null,
        tagsContactId: action.contactId,
      };
    case "CONTACTS/TAGS.CLEAR":
      return {
        ...state,
        tags: null,
        tagsStatus: null,
        tagsPage: null,
        tagsQuery: "",
        tagsLoadedAll: null,
        tagsContactId: null,
      };

    // Edit contact tags
    case "CONTACTS/PATCH_TAGS":
      return {
        ...state,
        patchTagsStatus: "loading",
      };
    case "CONTACTS/PATCH_TAGS.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      dataStatus[action.contactId] = "success";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchTagsStatus: "success",
      };
    case "CONTACTS/PATCH_TAGS.ERROR":
      return {
        ...state,
        patchTagsStatus: "error",
      };
    case "CONTACTS/PATCH_TAGS.CLEAR":
      return {
        ...state,
        patchTagsStatus: null,
      };

    // Contact Automated Messages Campaigns
    case "CONTACTS/AM_CAMPAIGNS":
      return {
        ...state,
        amCampaignsStatus: "loading",
        amCampaignsContactId: action.contactId,
      };
    case "CONTACTS/AM_CAMPAIGNS.SUCCESS":
      if (action.page === 1 || !state.amCampaigns) {
        amCampaigns = action.payload.campaigns;
      } else {
        amCampaigns = state.amCampaigns.concat(action.payload.campaigns);
      }
      return {
        ...state,
        amCampaigns: amCampaigns,
        amCampaignsStatus: "success",
        amCampaignsPage: action.page,
        amCampaignsQuery: action.query,
        amCampaignsLoadedAll: action.payload.campaigns.length === 0,
        amCampaignsContactId: action.contactId,
      };
    case "CONTACTS/AM_CAMPAIGNS.ERROR":
      return {
        ...state,
        amCampaigns: null,
        amCampaignsStatus: "error",
        amCampaignsPage: null,
        amCampaignsQuery: "",
        amCampaignsLoadedAll: null,
        amCampaignsContactId: action.contactId,
      };
    case "CONTACTS/AM_CAMPAIGNS.CLEAR":
      return {
        ...state,
        amCampaigns: null,
        amCampaignsStatus: null,
        amCampaignsPage: null,
        amCampaignsQuery: "",
        amCampaignsLoadedAll: null,
        amCampaignsContactId: null,
      };

    // Edit contact Automated Messages Campaigns
    case "CONTACTS/PATCH_AM_CAMPAIGNS":
      return {
        ...state,
        patchAmCampaignsStatus: "loading",
      };
    case "CONTACTS/PATCH_AM_CAMPAIGNS.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      dataStatus[action.contactId] = "success";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchAmCampaignsStatus: "success",
      };
    case "CONTACTS/PATCH_AM_CAMPAIGNS.ERROR":
      return {
        ...state,
        patchAmCampaignsStatus: "error",
      };
    case "CONTACTS/PATCH_AM_CAMPAIGNS.CLEAR":
      return {
        ...state,
        patchAmCampaignsStatus: null,
      };

    // Number lookup price
    case "CONTACTS/LOOKUP_PRICE":
      return {
        ...state,
        numberLookupPriceStatus: "loading",
        numberLookupPrice: null,
        numberLookupNumbersCount: null,
      };
    case "CONTACTS/LOOKUP_PRICE.SUCCESS":
      return {
        ...state,
        numberLookupPriceStatus: "success",
        numberLookupPrice: action.payload.price,
        numberLookupNumbersCount: action.payload.numbers_count,
      };
    case "CONTACTS/LOOKUP_PRICE.ERROR":
      return {
        ...state,
        numberLookupPriceStatus: "error",
        numberLookupPrice: null,
        numberLookupNumbersCount: null,
      };
    case "CONTACTS/LOOKUP_PRICE.CLEAR":
      return {
        ...state,
        numberLookupPriceStatus: null,
        numberLookupPrice: null,
        numberLookupNumbersCount: null,
      };

    // Number lookup
    case "CONTACTS/LOOKUP":
      return {
        ...state,
        numberLookupStatus: "loading",
        numberLookupResult: null,
      };
    case "CONTACTS/LOOKUP.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.contacts).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        numberLookupStatus: "success",
        numberLookupResult: action.payload.result,
      };
    case "CONTACTS/LOOKUP.ERROR":
      return {
        ...state,
        numberLookupStatus: "error",
        numberLookupResult: null,
      };
    case "CONTACTS/LOOKUP.CLEAR":
      return {
        ...state,
        numberLookupStatus: null,
        numberLookupResult: null,
      };

    // Queue number lookup
    case "CONTACTS/QUEUE_LOOKUP":
      return {
        ...state,
        queueNumberLookupStatus: "loading",
      };
    case "CONTACTS/QUEUE_LOOKUP.SUCCESS":
      return {
        ...state,
        queueNumberLookupStatus: "success",
      };
    case "CONTACTS/QUEUE_LOOKUP.ERROR":
      return {
        ...state,
        queueNumberLookupStatus: "error",
      };
    case "CONTACTS/QUEUE_LOOKUP.CLEAR":
      return {
        ...state,
        queueNumberLookupStatus: null,
      };

    case "CONTACTS/FILTER":
      return {
        ...state,
        filter: action.filter,
      };

    case "FETCH_THREADS_SUCCESS":
    case "THREADS/TEMP.SUCCESS":
    case "FETCH_INCOMING_THREAD_SUCCESS":
    case "THREADS/UNREAD.SUCCESS":
    case "THREADS/UNDELIVERED.SUCCESS":
    case "THREADS/FILTERED.SUCCESS":
    case "THREADS/SPECIAL.SUCCESS":
    case "THREADS_SEARCH_RECEIVERS_SUCCESS":
    case "THREADS/VOICE_THREADS.SUCCESS":
    case "THREADS/VOICE_FILTERED.SUCCESS":
    case "GROUPS/MEMBERS.SUCCESS":
    case "GROUPS/FILTERED_MEMBERS.SUCCESS":
      return {
        ...state,
        data: { ...state.data, ...action.payload.contacts },
        dataStatus: {
          ...state.dataStatus,
          ...fromEntries(
            new Map(
              Object.keys(action.payload.contacts).map((item, index) => {
                return [index, "success"];
              })
            )
          ),
        },
      };

    case "CONTACTS/EXPORT_CONTACTS": {
      return {
        ...state,
        exportContactsStatus: {
          ...state.exportContactsStatus,
          [action.statusId]: "loading",
        },
      };
    }

    case "CONTACTS/EXPORT_CONTACTS.SUCCESS": {
      return {
        ...state,
        exportContactsStatus: {
          ...state.exportContactsStatus,
          [action.statusId]: "success",
        },
        exportContactsCSV: {
          ...state.exportContactsCSV,
          [action.statusId]: action.payload,
        },
      };
    }

    case "CONTACTS/EXPORT_CONTACTS.FAILURE": {
      return {
        ...state,
        exportContactsStatus: {
          ...state.exportContactsStatus,
          [action.statusId]: "success",
        },
      };
    }

    case "CONTACTS/EXPORT_CONTACTS.CLEAR": {
      const exportContactsStatusCopy = { ...state.exportContactsStatus };
      const exportContactsCSVCopy = { ...state.exportContactsCSV };

      delete exportContactsStatusCopy[action.id];
      delete exportContactsCSVCopy[action.id];

      return {
        ...state,
        exportContactsStatus: exportContactsStatusCopy,
        exportContactsCSV: exportContactsCSVCopy,
      };
    }

    case "CONTACTS/LONG_NAME_MODAL_CONTENT":
      return {
        ...state,
        longNameModalContent: action.name,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      resetFetchContactsRequests();
      return initialState;

    default:
      return state;
  }
}
