import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import RedirectingToV3Modal from "../modals/RedirectingToV3Modal";

const NewVersionLink = (props) => {
  const [openRedirectToV3Modal, setOpenRedirectToV3Modal] = useState(false);
  const { children, link, className, doNotShowModal } = props;

  const toggleRedirectToV3Modal = useCallback(() => {
    setOpenRedirectToV3Modal(!openRedirectToV3Modal);
  }, [openRedirectToV3Modal]);

  const onClick = useCallback(() => {
    if (doNotShowModal) return null;
    toggleRedirectToV3Modal();
  }, [doNotShowModal, toggleRedirectToV3Modal]);

  return (
    <>
      <a className={className} href={link} onClick={onClick}>
        {children}
      </a>
      {openRedirectToV3Modal && !doNotShowModal && (
        <RedirectingToV3Modal
          show={openRedirectToV3Modal}
          closeModal={toggleRedirectToV3Modal}
        />
      )}
    </>
  );
};

NewVersionLink.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string,
  doNotShowModal: PropTypes.bool,
};

NewVersionLink.defaultProps = {
  link: "",
  className: "",
  doNotShowModal: false,
};

export default NewVersionLink;
