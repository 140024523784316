/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

/**
 *
 * @returns {Function}
 */
export function clearErrors() {
  return function (dispatch) {
    dispatch({ type: "CLEAR_ERRORS" });
  };
}

export function toggleSidebar() {
  return function (dispatch) {
    dispatch({
      type: "MAIN/TOGGLE_SIDEBAR",
    });
  };
}

export function setTopBarsHeight(height) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_TOP_BARS_HEIGHT",
      height,
    });
  };
}

export function setIsLeftPanelScrollingDown(isScrolling) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_IS_LEFT_PANEL_SCROLLING_DOWN",
      isScrolling,
    });
  };
}

export function setIsMainPanelScrollingUp(isScrolling) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_IS_MAIN_PANEL_SCROLLING_UP",
      isScrolling,
    });
  };
}

export function showLoading() {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SHOW_LOADING",
    });
  };
}

export function extendLegacySession(show) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/EXTEND_LEGACY_SESSION_OPENER",
      show,
    });
  };
}
