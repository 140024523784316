import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { getRequest } from "../utils/requests";

// We stores requestIds to not overwrite response from earlier request, from previous selected company
let fetchGroupsRequestIds = [];

export function resetFetchGroupsRequests() {
  fetchGroupsRequestIds = [];
}

function removeFetchGroupRequestId(requestId) {
  const index = fetchGroupsRequestIds.indexOf(requestId);
  if (index >= 0) {
    fetchGroupsRequestIds.splice(index, 1);
  }
}

export function fetchGroups(companyId, page) {
  return function (dispatch) {
    const requestId = `${new Date().getTime()}-${String(Math.random()).substr(
      2,
      3
    )}`;
    fetchGroupsRequestIds.push(requestId);
    dispatch({
      type: "GROUPS/FETCH",
      page: page,
    });

    const url =
      "groups.json?filter=named&companyId=" + companyId + "&page=" + page;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        if (fetchGroupsRequestIds.indexOf(requestId) >= 0) {
          removeFetchGroupRequestId(requestId);
          dispatch({
            type: "GROUPS/FETCH.SUCCESS",
            page: page,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        if (fetchGroupsRequestIds.indexOf(requestId) >= 0) {
          removeFetchGroupRequestId(requestId);
          dispatch({
            type: "GROUPS/FETCH.ERROR",
            page: page,
            payload: error,
          });
        }
      });
  };
}

export function fetchFilteredGroups(companyId, query, filter, page) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FETCH_FILTERED",
      query: query,
      page: page,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        query: query,
        filter: filter,
        page: page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "groups.json", config)
      .then((response) => {
        dispatch({
          type: "GROUPS/FETCH_FILTERED.SUCCESS",
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FETCH_FILTERED.ERROR",
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function fetchGroupData(companyId, groupId) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FETCH_DATA",
      groupId: groupId,
    });

    const url = "groups/" + groupId + ".json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/FETCH_DATA.SUCCESS",
          groupId: groupId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FETCH_DATA.ERROR",
          groupId: groupId,
          payload: error,
        });
      });
  };
}

export function fetchTempGroup(companyId, groupId) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FETCH_TEMP",
      groupId: groupId,
    });

    const url = "groups/" + groupId + ".json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/FETCH_TEMP.SUCCESS",
          groupId: groupId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FETCH_TEMP.ERROR",
          groupId: groupId,
          payload: error,
        });
      });
  };
}

export function fetchMembers(companyId, groupId, limit, page) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/MEMBERS",
      groupId: groupId,
      page: page,
    });

    const url =
      "groups/" +
      groupId +
      "/members.json?companyId=" +
      companyId +
      "&limit=" +
      limit +
      "&page=" +
      page;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/MEMBERS.SUCCESS",
          groupId: groupId,
          limit: limit,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/MEMBERS.ERROR",
          groupId: groupId,
          page: page,
          payload: error,
        });
      });
  };
}

export function fetchFilteredMembers(companyId, groupId, query, limit, page) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FILTERED_MEMBERS",
      groupId: groupId,
      query: query,
      page: page,
    });

    const url = "groups/" + groupId + "/members.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        query: query,
        limit: limit,
        page: page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "GROUPS/FILTERED_MEMBERS.SUCCESS",
          groupId: groupId,
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FILTERED_MEMBERS.ERROR",
          groupId: groupId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function patchMembers(
  companyId,
  groupId,
  addContactIds,
  addGroupIds,
  clearAdhoc = true
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/PATCH_MEMBERS",
      groupId: groupId,
    });

    const url = "groups/" + groupId + "/members.json";
    const data = Object.assign(
      {},
      {
        companyId: companyId,
        addContactIds: addContactIds,
        addGroupIds: addGroupIds,
        clearAdhoc: clearAdhoc,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/PATCH_MEMBERS.SUCCESS",
          groupId: groupId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/PATCH_MEMBERS.ERROR",
          groupId: groupId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function patchGroup(
  companyId,
  groupId,
  patchData,
  requestId,
  upload = false
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/PATCH",
      groupId: groupId,
      patchData: patchData,
      requestId: requestId,
    });

    const url = "groups/" + groupId + ".json";

    const data = upload
      ? new FormData()
      : Object.assign({}, patchData, { company_id: companyId });
    const config = AxiosConfig.getAuthConfig();
    if (upload) {
      data.append("company_id", companyId);
      for (var field in patchData) {
        data.append(field, patchData[field]);
      }
    }

    return axios
      .post(AxiosConfig.getEndpointAddress() + url, data, config)
      .then((response) => {
        dispatch({
          type: "GROUPS/PATCH.SUCCESS",
          groupId: groupId,
          patchData: patchData,
          requestId: requestId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/PATCH.ERROR",
          groupId: groupId,
          patchData: patchData,
          requestId: requestId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function addGroup(companyId, groupData) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/ADD",
      groupData: groupData,
    });

    const url = "groups.json";
    const data = Object.assign({}, groupData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/ADD.SUCCESS",
          groupData: groupData,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/ADD.ERROR",
          groupData: groupData,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearAddGroup() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/ADD.CLEAR" });
  };
}

export function fetchExtAppsData(companyId) {
  return function (dispatch) {
    dispatch({ type: "GROUPS/EXT_APPS_DATA" });

    const url = "groups/external-apps-data.json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/EXT_APPS_DATA.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/EXT_APPS_DATA.ERROR",
          payload: error,
        });
      });
  };
}

export function checkKeywords(
  companyId,
  keywords,
  ignoreGroupId = null,
  ignoreAutoresponderMessageId = null,
  ignoreContestId = null,
  ignorePollId = null,
  uid = null
) {
  const args = {
    companyId: companyId,
    keywords: keywords,
    ignoreGroupId: ignoreGroupId,
    ignoreAutoresponderMessageId: ignoreAutoresponderMessageId,
    ignoreContestId: ignoreContestId,
    ignorePollId: ignorePollId,
  };

  return function (dispatch) {
    dispatch(
      Object.assign({}, args, {
        type: "GROUPS/CHECK_KEYWORDS",
        uid: uid,
      })
    );

    const url = "groups/check-keywords.json";
    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        Object.assign({}, AxiosConfig.getAuthConfig(), { params: args })
      )
      .then((response) => {
        dispatch(
          Object.assign({}, args, {
            type: "GROUPS/CHECK_KEYWORDS.SUCCESS",
            payload: response.data,
          })
        );
      })
      .catch(function (error) {
        dispatch(
          Object.assign({}, args, {
            type: "GROUPS/CHECK_KEYWORDS.ERROR",
            payload: error.response ? error.response.data : null,
            error: error,
          })
        );
      });
  };
}

export function clearCheckKeywords() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CHECK_KEYWORDS.CLEAR" });
  };
}

export function setFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FILTER",
      filter: filter,
    });
  };
}

export function fetchGroupAmCampaigns(
  companyId,
  groupId,
  query = "",
  page = null
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/AM_CAMPAIGNS",
      groupId,
      query,
      page,
    });

    const url = "groups/" + groupId + "/am-campaigns.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        query,
        page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "GROUPS/AM_CAMPAIGNS.SUCCESS",
          groupId,
          query,
          page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/AM_CAMPAIGNS.ERROR",
          groupId,
          query,
          page,
          payload: error,
        });
      });
  };
}

export function clearGroupAmCampaigns() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/AM_CAMPAIGNS.CLEAR" });
  };
}

export function patchGroupAmCampaigns(companyId, groupId, campaignId) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/PATCH_AM_CAMPAIGNS",
      groupId,
      campaignId,
    });

    const url = "groups/" + groupId + "/am-campaigns.json";
    const data = Object.assign(
      {},
      {
        companyId,
        groupId,
        campaignId,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/PATCH_AM_CAMPAIGNS.SUCCESS",
          groupId,
          campaignId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/PATCH_AM_CAMPAIGNS.ERROR",
          groupId,
          campaignId,
          error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchGroupAmCampaigns() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/PATCH_AM_CAMPAIGNS.CLEAR" });
  };
}

export const addTag = (tag_name, company_id) => {
  return (dispatch) => {
    dispatch({ type: "GROUP/ADD_TAG" });

    const url = `groups/add-tag.json`;

    let data = {
      tag_name,
      company_id,
    };

    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((res) => {
        dispatch({ type: "GROUP/ADD_TAG.SUCCESS", payload: res.data.tag });
      })
      .catch(() => {
        dispatch({ type: "GROUP/ADD_TAG.ERROR" });
      });
  };
};

export function clearAddTag() {
  return function (dispatch) {
    dispatch({ type: "GROUP/ADD_TAG.CLEAR" });
  };
}

export function fetchGroupMembersCountStatus(companyId, groupId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_DATA_MEMBERS_STATUSES",
      `groups/members-stats/${groupId}/.json?companyId=${companyId}`,
      {
        requestDispatchData: {
          groupId,
        },
        successDispatchData: {
          groupId,
        },
        errorDispatchData: {
          groupId,
        },
      }
    );
}
