import React from "react";
import { Alert } from "reactstrap";
import { ReactComponent as AlertInfoSvg } from "../assets/img/icons/alert-info.svg";
import { ReactComponent as PaymentIssueSvg } from "../assets/img/icons/payment-issue.svg";

class AccountIssueBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.onClickClose = this.onClickClose.bind(this);

    this.state = {
      visible: true,
    };
  }

  onClose() {
    const { onClose } = this.props;
    if (onClose) {
      onClose(this.props.companyId, this.props.issueKey);
    }
  }

  onClickClose(e) {
    e.stopPropagation();
    e.preventDefault();
    this.onClose();
  }

  render() {
    const { closeState, issueKey, companyId } = this.props;
    const { onClose, showLoading } = this.props;

    if (closeState === issueKey) {
      return null;
    } else if (onClose) {
      onClose(companyId, null);
    }

    const classNameMobile = "top-alert d-lg-none " + this.props.alertClass;
    const contentMobile = (
      <React.Fragment>
        {this.props.icon === "payment" && (
          <PaymentIssueSvg
            className={"icon-black"}
            width={25.833}
            height={18}
          />
        )}
        {this.props.icon === "warning" && (
          <AlertInfoSvg className={"icon-black"} width={16} height={16} />
        )}
        <div
          className="ml-2"
          dangerouslySetInnerHTML={{ __html: this.props.shortMessage }}
        ></div>
      </React.Fragment>
    );

    const className = "top-alert d-none d-lg-flex " + this.props.alertClass;
    const content = (
      <React.Fragment>
        {this.props.icon === "payment" && (
          <PaymentIssueSvg
            className={"icon-black"}
            width={25.833}
            height={18}
          />
        )}
        {this.props.icon === "warning" && (
          <AlertInfoSvg className={"icon-black"} width={16} height={16} />
        )}
        <div
          className="ml-2"
          dangerouslySetInnerHTML={{ __html: this.props.message }}
        ></div>
        {this.props.dismissible && (
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.onClickClose}
          >
            <i className="fa fa-x-mark-circle-o"></i>
          </button>
        )}
      </React.Fragment>
    );

    const mobileModal =
      this.props.issueKey !== "hard" &&
      this.props.issueKey !== "inactive" &&
      this.props.issueKey !== "invalid-email";

    const constProps = {
      closeClassName: "d-none",
      isOpen: this.state.visible,
      toggle: this.onClose,
    };

    return (
      <React.Fragment>
        {mobileModal && (
          <Alert
            {...constProps}
            tag="a"
            href="#modal-account-issue"
            className={classNameMobile}
          >
            {contentMobile}
          </Alert>
        )}
        {!mobileModal && (
          <Alert {...constProps} className={classNameMobile}>
            {contentMobile}
          </Alert>
        )}
        {this.props.url && (
          <Alert
            tag="a"
            href={this.props.url}
            className={className}
            onClick={showLoading}
          >
            {content}
          </Alert>
        )}
        {!this.props.url && <Alert className={className}>{content}</Alert>}
      </React.Fragment>
    );
  }
}

export default AccountIssueBar;
