import React from "react";
import CustomModal from "./CustomModal";

const UnsupportedBrowserModal = (props) => {
  const { mode, closeModal } = props;
  return (
    <CustomModal
      {...props}
      className={"locked-info-modal"}
      dialogClassName={"modal-dialog-centered"}
      noHeader
      noFooter
      shakeModal={closeModal}
    >
      <div onClick={closeModal}>
        {mode === "microphone" && (
          <React.Fragment>
            <h4 className="mb-4 text-white">Microphone access unavailable</h4>
            <p className="mb-2 text-white">
              Access to the microphone is not available via this browser.
            </p>
            <p className="text-white">
              You may consider trying a different browser.
            </p>
          </React.Fragment>
        )}
        {mode === "media-recorder" && (
          <React.Fragment>
            <h4 className="mb-4 text-white">WebRTC disabled/Not Supported</h4>
            <p className="mb-2 text-white">
              The voice recording feature is not supported or disabled on this
              browser.
            </p>
            <p className="text-white">
              You may consider trying a different browser.
            </p>
          </React.Fragment>
        )}
      </div>
    </CustomModal>
  );
};

export default UnsupportedBrowserModal;
