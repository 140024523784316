/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./store";
import RouterClass from "./router";
import { CookiesProvider } from "react-cookie";
import ErrorBoundary from "./components/ErrorBoundary";

function launchApp(swObject) {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CookiesProvider>
            <RouterClass swObject={swObject} />
          </CookiesProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
}

launchApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
