import React from "react";
import { connect } from "react-redux";
import { ModalsContext } from "../../ModalsContext";
import { withRouter } from "react-router";
import { formatPhoneNumber } from "../../helpers";

import { setNumbersFilter } from "../../actions/numbers";

import InfoTooltip from "../elements/InfoTooltip";

import { ReactComponent as FlagUsSvg } from "../../assets/img/flags/flag_us.svg";
import { ReactComponent as UserIconSvg } from "../../assets/img/icons/user_icon.svg";

function mapStateToProps(store) {
  return {
    currentCompanyId: store.companies.currentCompany.id,
    numbersStatus: store.numbers.numbersStatus,
    numbers: store.numbers.numbers,
    numbersFilter: store.numbers.numbersFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setNumbersFilter: (companyId, numbers, all) =>
      dispatch(setNumbersFilter(companyId, numbers, all)),
  };
}

class FilterNumbersModal extends React.Component {
  static contextType = ModalsContext;

  constructor(props) {
    super(props);
    this.updateView = this.updateView.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isShown: false,
      isHide: false,
      redirect: null,
      numbersFilter: this.props.numbersFilter
        ? [].concat(this.props.numbersFilter)
        : [],
    };
  }

  componentDidMount() {
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
    }
    if (this.props.numbersFilter !== prevProps.numbersFilter) {
      this.setState({ numbersFilter: [].concat(this.props.numbersFilter) });
    }
    const currentOpen = this.state.isShown && !this.state.isHide;
    const prevOpen = prevState.isShown && !prevState.isHide;
    if (currentOpen !== prevOpen && !currentOpen) {
      this.saveFilter();
    }
    // on show
    if (
      this.state.isShown &&
      !this.state.isHide &&
      (!prevState.isShown || prevState.isHide)
    ) {
      if (this.state.numbersFilter.length === 0) {
        this.setState({ numbersFilter: [].concat(this.props.numbersFilter) });
      }
    }
  }

  updateView() {
    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  saveFilter() {
    const prevFilter = this.props.numbersFilter
      ? this.props.numbersFilter.sort().join(",")
      : null;
    const newFilter = this.state.numbersFilter
      ? this.state.numbersFilter.sort().join(",")
      : null;
    const all = this.props.numbers.length === this.state.numbersFilter.length;
    if (prevFilter !== newFilter && this.state.numbersFilter.length > 0) {
      this.props.setNumbersFilter(
        this.props.currentCompanyId,
        this.state.numbersFilter,
        all
      );
    }
  }

  toggleAll() {
    if (this.props.numbers.length === this.state.numbersFilter.length) {
      this.setState({ numbersFilter: [] });
    } else {
      let numbersFilter = [];
      for (let i = 0; i < this.props.numbers.length; i++) {
        numbersFilter.push(this.props.numbers[i].number);
      }
      this.setState({ numbersFilter: numbersFilter });
    }
  }

  toggle(number) {
    let numbersFilter = this.state.numbersFilter;
    if (numbersFilter.indexOf(number) === -1) {
      numbersFilter.push(number);
    } else {
      numbersFilter.splice(numbersFilter.indexOf(number), 1);
    }
    this.setState({ numbersFilter: numbersFilter });
  }

  render() {
    if (!this.props.numbers) {
      return null;
    }
    return (
      <div
        className={
          "modal fade number-filter-modal" +
          (this.state.isShown && !this.state.isHide ? " show" : "")
        }
        style={
          this.props.show || this.state.isShown ? { display: "block" } : {}
        }
        id="FilterNumbersModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="FilterNumbersModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-backdrop fade show"
          onClick={(e) => {
            e.preventDefault();
            this.props.history.goBack();
          }}
        />
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ zIndex: 100 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="FilterNumbersModalLabel">
                Number Filter
              </h5>
            </div>
            <div className="modal-body">
              <div
                className="custom-control custom-switch"
                onClick={this.toggleAll}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="NumberFilterModalSwitchAll"
                  checked={
                    this.state.numbersFilter &&
                    this.props.numbers &&
                    this.state.numbersFilter.length ===
                      this.props.numbers.length
                      ? true
                      : false
                  }
                  onChange={() => {}}
                />
                <label className="custom-control-label">
                  <span className="content d-flex ml-2">
                    <p className="name">All</p>
                  </span>
                </label>
              </div>
              <div id="NumberFilterModalSwitchContainer">
                {this.props.numbers &&
                  this.props.numbers.map((number) => {
                    return (
                      <div
                        key={number.number}
                        className="custom-control custom-switch"
                        onClick={() => this.toggle(number.number)}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input custom-switch-input-single"
                          id={"switchNumber" + number.number}
                          checked={
                            this.state.numbersFilter &&
                            this.state.numbersFilter.indexOf(number.number) >= 0
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />
                        <label className="custom-control-label">
                          <span className="content d-flex ml-2">
                            <div className="d-flex flex-column mr-2">
                              <img
                                src="/img/flags/flag_us.svg"
                                width="16"
                                alt=""
                              />
                              <FlagUsSvg width={16} />
                              {number.is_default && (
                                <UserIconSvg className="user-icon" width={16} />
                              )}
                            </div>
                            <div>
                              <p className="number">
                                {formatPhoneNumber(number.number)}
                                {number.src === 5 && (
                                  <span>&nbsp;(US only)</span>
                                )}
                                {number.name && (
                                  <InfoTooltip
                                    style={{ verticalAlign: "bottom" }}
                                    content={number.name}
                                  />
                                )}
                              </p>
                              <p className="name">
                                {number.is_default
                                  ? `Me, ${number.nickname}`
                                  : number.nickname}
                              </p>
                            </div>
                          </span>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={(e) => {
                  this.props.history.goBack();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterNumbersModal)
);
