const initialState = {
  numbers: null,
  numbersStatus: null,
  numbersFilter: null,
  numbersFilterValue: null,
  numbersFiltered: null,

  senderNumber: null,
  useSecondaryNumber: null,

  shortCodeUserId: null,
  shortCodeUserNumber: null,
  shortCodeSignaturePreview: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    case "NUMBERS/NUMBERS":
      return {
        ...state,
        numbers: null,
        numbersStatus: "loading",
      };
    case "NUMBERS/NUMBERS.SUCCESS":
      return {
        ...state,
        numbers: action.payload.numbers,
        numbersStatus: "success",
      };
    case "NUMBERS/NUMBERS.ERROR":
      return {
        ...state,
        numbers: [],
        numbersStatus: "error",
      };

    case "NUMBERS/FILTER":
      return {
        ...state,
        numbersFilter: action.numbers,
        numbersFilterValue: action.value,
        numbersFiltered: !action.all,
      };

    case "NUMBERS/SENDER":
      let numberData = null;
      if (state.numbers) {
        for (let i = 0; i < state.numbers.length; i++) {
          if (state.numbers[i].number === action.number) {
            numberData = state.numbers[i];
          }
        }
      }
      return {
        ...state,
        senderNumber: numberData,
      };

    case "NUMBERS/SET_USE_SECONDARY_NUMBER":
      return {
        ...state,
        useSecondaryNumber: action.useSecondaryNumber,
      };

    case "NUMBERS/SHORT_CODE_SIGNATURE":
      return {
        ...state,
        shortCodeUserId: action.userId,
        shortCodeUserNumber: action.userNumber,
        shortCodeSignaturePreview: action.signaturePreview,
      };

    default:
      return state;
  }
}
