import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TwilioConfig from "../TwilioConfig";
import { isCordovaApp, isIOs } from "../helpers";

function mapStateToProps(store) {
  return {
    ready: store.twilio.ready,
    twilioToken: store.calls.twilioToken,
    currentCompany: store.companies.currentCompany,
    loggedUser: store.users.loggedUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const TwilioInitializer = (props) => {
  const { ready, twilioToken, currentCompany, loggedUser, dispatch } = props;
  const isInitialized = TwilioConfig.isInitialized();
  const [eventAttached, setEventAttached] = useState(false);

  const clickHandler = useCallback(() => {
    if (!isInitialized || !ready) {
      const dispatchProxy = (dispatchRes) => dispatch(dispatchRes);
      TwilioConfig.init(twilioToken, currentCompany, loggedUser, dispatchProxy);
    }
    document.body.removeEventListener("click", clickHandler);
  }, [currentCompany, dispatch, isInitialized, loggedUser, ready, twilioToken]);

  useEffect(() => {
    // Cordova app has different flow for now. Will be changed in the future.
    // It's only for iOS, on Android it works well.
    if (
      !(isCordovaApp() && isIOs()) &&
      !eventAttached &&
      (!isInitialized || !ready)
    ) {
      document.body.addEventListener("click", clickHandler);
      setEventAttached(true);
    }
  }, [clickHandler, eventAttached, isInitialized, ready]);

  return null;
};

TwilioInitializer.propTypes = {
  ready: PropTypes.bool,
  twilioToken: PropTypes.string,
  currentCompany: PropTypes.objectOf(PropTypes.any),
  loggedUser: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func,
};

TwilioInitializer.defaultProps = {
  ready: false,
  twilioToken: null,
  currentCompany: null,
  loggedUser: null,
  dispatch: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(TwilioInitializer);
